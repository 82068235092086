import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";

export const BasicInfoStatus = createModel<RootModel>()({
    state: {
        token:'',
        isLoading:true,
        email:'',
        firstName:'',
        lastName:'',
        companyName:'',
        guiNumber:'',
        accountName:'',
    },
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        }
    },
    effects: (dispatch) =>({
        clearup () {
            dispatch.BasicInfoStatus.setState({data: '', type:SET_TOKEN})
            dispatch.BasicInfoStatus.setState({data: '', type:SET_LOADING})
            dispatch.BasicInfoStatus.setState({data:'',type:SET_ACCOUNT_NAME})
        }
    })
});

export const SET_TOKEN = "token";
export const SET_EMAIL = "email";
export const SET_LOADING = "isLoading"
export const SET_FIRST_NAME = "firstName"
export const SET_LAST_NAME = "lastName"
export const SET_COMPANY_NAME = "companyName"
export const SET_GUI_NUM = 'guiNumber';
export const SET_ACCOUNT_NAME = 'accountName'