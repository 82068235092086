import React, { useEffect, useReducer, useRef, useState } from "react";
import "antd/dist/antd.min.css";
import { Divider, Form, Input, Button, Cascader, Modal } from "antd";
import { WarningOutlined, LoadingOutlined } from "@ant-design/icons";
import localforage from "localforage";
import routes from "@/routers";
import * as ArContent_pb from "@/proto/ArContent_pb";
import { AuthReply } from "@/proto/Auth_pb";
import * as arContentApi from "@/helpers/arContentApi";
import { HairTemplate } from "@ar_template/component/ar_view/HairTemplate";
import { TemplateHair } from "@/proto/ArContentTemplate_pb";
import { IColor } from "@/helper/ar_lib/component/interface/IColor";
import { IHairAction } from "@/components/templateInterface";
import Loading from "./Loading";
import BaseArViewerController from "./Helpers/BaseArViewerController";
import moment from "moment";
import { resultErrorMsg } from "@/helpers/errorCodeMsg";
import { RpcError } from "grpc-web";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@/store";
import { SET_ARVIEW, SET_AR_LOADING, SET_HAIR_PICKER_COLOR, SET_HAIR_PICKER_COLOR_B, SET_HAIR_PICKER_COLOR_G, SET_HAIR_PICKER_COLOR_HEX, SET_HAIR_PICKER_COLOR_R, SET_ISLOADING,  SET_IS_SUBMIT,  SET_TOKEN } from "@/model/component/editHair";


export interface ITemplateHairSetting {
  color: IColor;
  softness: number;
  feather: number;
}

function hairSettingReducer(state: ITemplateHairSetting, action: IHairAction) {
  const { type, payload } = action;
  switch (type) {
    case "color":
      return {
        ...state,
        value: (state[type] = payload as IColor),
      };
    case "feather":
    case "softness":
      return {
        ...state,
        value: (state[type] = payload as number),
      };
    default:
      return state;
  }
}

let template: HairTemplate | undefined = undefined;
const templateHair = new TemplateHair();

export const defaultHairColor: ITemplateHairSetting = {
  color: { r: 0, g: 0, b: 0 },
  softness: 0,
  feather: 0,
};

const EditHair: React.FC = function (props) {
  const nav = useNavigate();
  const params = useParams();
  const router_path = routes.router_path;
  const contentId = params.pid;
  const [form] = Form.useForm();
  const modelViewDom = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<Dispatch> ()
  const status = useSelector((state: RootState) => state.editHairStatus);
  const {
    hairPickerColor,
    hairPickerColorB,
    hairPickerColorG,
    hairPickerColorHEX,
    hairPickerColorR,
    token,
    arLoading,
    arSwitch,
    arView,
    loadArView,
    isLoading,
    isSubmit,
    state
    
  } = status;

  useEffect(() => {
    const load = async () => {
      if (contentId === undefined) {
        return;
      }
      dispatch.editHairStatus.hairSettingReducer({ type: "color", payload: state.color as IColor });
      const auth = await localforage.getItem<AuthReply.AsObject>("admin_auth");
      if (!auth) {
        await localforage.clear();
        nav(router_path.login);
      } else {
        const expiresTime = moment(auth.expireDate);
        if (moment().isAfter(expiresTime)) {
          await localforage.clear();
          nav(router_path.login);
        } else {
          dispatch.editHairStatus.setState({data:auth.token,type: SET_TOKEN})
          const resultData = await arContentApi.getArContentApi(
            auth!.token,
            contentId!
          );
          try {
            if (resultData instanceof ArContent_pb.GetArContentReply) {
              const content = resultData.toObject().data;
              const templateData = TemplateHair.deserializeBinary(
                content!.templateSetting as Uint8Array
              ).toObject();
              dispatch.editHairStatus.setState({data: templateData.colorCode,type: SET_HAIR_PICKER_COLOR});
              dispatch({
                type: "color",
                payload: hexToRgb(templateData.colorCode) as IColor,
              });
              dispatch({
                type: "softness",
                payload: +templateData.alphaSoftness as number,
              });
              dispatch({
                type: "feather",
                payload: +templateData.alphaFeather as number,
              });
              dispatch.editHairStatus.setState({data: hexToRgb(templateData.colorCode)!.r,type:SET_HAIR_PICKER_COLOR_R})
              dispatch.editHairStatus.setState({data: hexToRgb(templateData.colorCode)!.b,type:SET_HAIR_PICKER_COLOR_B})
              dispatch.editHairStatus.setState({data: hexToRgb(templateData.colorCode)!.g,type:SET_HAIR_PICKER_COLOR_G})
            } else {
              throw new Error();
            }
          } catch (e) {
            resultErrorMsg(resultData as RpcError);
          }
        }
      }
    };
    load();
    return function cleanUp() {
      if (template) {
        template.CloseArMode();
        template = undefined;
        dispatch.editHairStatus.clearup()
      }
    };
  }, [params]);

  useEffect(() => {
    if (modelViewDom.current) {
      const dom = modelViewDom.current;
      template = new HairTemplate(
        dom,
        { width: dom.clientWidth, height: dom.clientHeight },
        state.color,
        callbackEvent
      );
    }
  }, []);

  useEffect(() => {
    template?.ChangeColor(state.color);
    dispatch.editHairStatus.setState({type:SET_HAIR_PICKER_COLOR_HEX,data:rgbToHex(state.color)});
  }, [state.color]);

  useEffect(() => {
    template?.SetSoftness(state.softness);
    template?.SetFeather(state.feather);
  }, [state.feather, state.softness]);

  function callbackEvent(arstate: string) {
    // console.log(arstate)
    if (arstate === "done") {
      dispatch.editHairStatus.setState({data:false, type: SET_AR_LOADING})
    } else {
      dispatch.editHairStatus.setState({data:false , type: SET_ARVIEW})
    }
    dispatch.editHairStatus.setState({data: false , type: SET_ISLOADING})
  }

  function onChangeValue(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.currentTarget;
    if (name === "color") {
      dispatch.editHairStatus.hairSettingReducer({ type: name, payload: hexToRgb(value) as IColor });
      dispatch.editHairStatus.setState({data:value , type: SET_HAIR_PICKER_COLOR_HEX})
      dispatch.editHairStatus.setState({data: hexToRgb(value)!.b,type: SET_HAIR_PICKER_COLOR_B})
      dispatch.editHairStatus.setState({data: hexToRgb(value)!.r,type: SET_HAIR_PICKER_COLOR_R})
      dispatch.editHairStatus.setState({data: hexToRgb(value)!.g,type: SET_HAIR_PICKER_COLOR_G})
    } else if (name === "softness" || name === "feather") {
      
      dispatch.editHairStatus.hairSettingReducer({ type: name, payload: +value as number });
    }
  }

  function hexToRgb(hex: string): IColor | null {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function rgbToHex(color: IColor) {
    return (
      "#" +
      ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b)
        .toString(16)
        .slice(1)
    );
  }

  useEffect(() => {
    const RGBcolorToHex = ConvertRGBtoHex(
      hairPickerColorR,
      hairPickerColorG,
      hairPickerColorB
    );
    if (hairPickerColorHEX != RGBcolorToHex) {
      dispatch.editHairStatus.setState({data:RGBcolorToHex,type: SET_HAIR_PICKER_COLOR_HEX})
    }
  }, [hairPickerColorR, hairPickerColorG, hairPickerColorB]);

  useEffect(() => {
    const rgbColor = hexToRgb(hairPickerColorHEX);
    if (rgbColor == null) {
      return;
    }
    if (
      hairPickerColorR != rgbColor?.r ||
      hairPickerColorG != rgbColor?.g ||
      hairPickerColorB != rgbColor?.b
    ) {
      dispatch.editHairStatus.setState({data:rgbColor!.r,type: SET_HAIR_PICKER_COLOR_R})
      dispatch.editHairStatus.setState({data:rgbColor!.b ,type: SET_HAIR_PICKER_COLOR_B})
      dispatch.editHairStatus.setState({data:rgbColor!.g,type:SET_HAIR_PICKER_COLOR_G})
    }
    dispatch.editHairStatus.setState({data: hairPickerColorHEX, type: SET_HAIR_PICKER_COLOR})
    template?.ChangeColor(rgbColor);
  }, [hairPickerColorHEX]);

  function ConvertRGBtoHex(red: number, green: number, blue: number) {
    return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
  }

  function ColorToHex(color: number) {
    const hexadecimal = color.toString(16);
    return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
  }

  function inputValue(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.value) {
      dispatch.editHairStatus.setState({data: "#ffffff" , type: SET_HAIR_PICKER_COLOR_HEX})
    } else if (event.target.value.length <= 5) {
      dispatch.editHairStatus.setState({data: padStart(event.target.value, 6) , type: SET_HAIR_PICKER_COLOR_HEX})
    }
  }

  function padStart(str: string, length: number): string {
    if (str.length >= length) {
      return str;
    }
    return padStart("0" + str, length);
  }

  function handleCancel() {
    // template!.CloseArMode();
    // setArSwitch(!arSwitch)
    Modal.confirm({
      title: <h3>Back to projects page?</h3>,
      icon: <WarningOutlined />,
      content: <h6>Changes you made may not be saved.</h6>,
      okText: "YES",
      cancelText: "Cancel",
      maskClosable: true,
      width: 500,
      async onOk() {
        nav(router_path.arProject.edit.replace(":pid", contentId!));
      },
    });
  }

  async function onFinish() {
    // template!.CloseArMode();
    // setArSwitch(!arSwitch)
    templateHair
      .setColorCode(hairPickerColorHEX)
      .setAlphaFeather(state.feather.toString())
      .setAlphaSoftness(state.softness.toString());
    const result = await arContentApi.updateArContentTemplateApi(
      token,
      contentId!,
      templateHair.serializeBinary()
    );
    try {
      if (result instanceof ArContent_pb.UpdateArContentTemplateReply) {
        dispatch.editHairStatus.setState({data: true , type: SET_IS_SUBMIT})
        const path = router_path.arProject.editViewer.replace(
          ":pid",
          contentId!
        );
        nav(path);
      } else {
        dispatch.editHairStatus.setState({data: true , type: SET_IS_SUBMIT})
        throw new Error();
      }
    } catch (e) {
      resultErrorMsg(result as RpcError);
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="edit-ar-layout">
        {/* Left side */}
        <Form form={form} onFinish={onFinish} className="edit-ar-form">
          <div className="edit-ar-left">
            {/* 選擇ABC檔案圖片 */}
            <div className="edit-layout">
              <p className="edit-title">Hair Coloring</p>
              <p className="edit-hint">
                Input your color (RGB) to change hair color
              </p>
              <div className="action-color-group">
                <span className="action-button-title">Color Palette</span>
                <div
                  className="dropdown"
                  style={{ backgroundColor: `${hairPickerColor}` }}
                >
                  <Input
                    className="dropdown-content"
                    type={"color"}
                    name={"color"}
                    onChange={onChangeValue}
                    value={hairPickerColorHEX}
                  />
                </div>
                <div className="action-RGBcolor-group">
                  <Form.Item
                    className="action-RGBcolor"
                    key="action-RGBcolor_R"
                   
                  >
                    <span className="action-color-title">R</span>
                    <Input
                      className="action-RGBcolor-input"
                      value={hairPickerColorR}
                      name="Rcolor"
                      onChange={(e) => {
                        if (!e.target.value || isNaN(Number(e.target.value))) {
                          dispatch.editHairStatus.setState({data:0 , type: SET_HAIR_PICKER_COLOR_R})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: 0,
                              g: state.color.g,
                              b: state.color.b,
                            },
                          });
                        } else if (Number(e.target.value) >= 256) {
                          dispatch.editHairStatus.setState({data: 255 , type: SET_HAIR_PICKER_COLOR_R})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: 255,
                              g: state.color.g,
                              b: state.color.b,
                            },
                          });
                        } else {
                          dispatch.editHairStatus.setState({data: Number(e.target.value) , type: SET_HAIR_PICKER_COLOR_R})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: Number(e.target.value),
                              g: state.color.g,
                              b: state.color.b,
                            },
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                  
                    key="action-RGBcolor_G"
                    className="action-RGBcolor"
                  >
                    <span className="action-color-title">G</span>
                    <Input
                      className="action-RGBcolor-input"
                      value={hairPickerColorG}
                      name="Gcolor"
                      onChange={(e) => {
                        if (!e.target.value || isNaN(Number(e.target.value))) {
                          dispatch.editHairStatus.setState({type: SET_HAIR_PICKER_COLOR_G, data: 0})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: state.color.r,
                              g: 0,
                              b: state.color.b,
                            },
                          });
                        } else if (Number(e.target.value) >= 256) {
                          dispatch.editHairStatus.setState({data:255, type: SET_HAIR_PICKER_COLOR_G})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: state.color.r,
                              g: 255,
                              b: state.color.b,
                            },
                          });
                        } else {
                          dispatch.editHairStatus.setState({data:Number(e.target.value),type:SET_HAIR_PICKER_COLOR_G});
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: state.color.r,
                              g: Number(e.target.value),
                              b: state.color.b,
                            },
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="action-RGBcolor"
                  
                    key="action-RGBcolor_B"
                  >
                    <span className="action-color-title">B</span>
                    <Input
                      className="action-RGBcolor-input"
                      value={hairPickerColorB}
                      name="Bcolort"
                      max={3}
                      onChange={(e) => {
                        // console.log(e)
                        // console.log(e.target.value)
                        // console.log(!e.target.value || isNaN(Number(e.target.value)))
                        if (!e.target.value || isNaN(Number(e.target.value))) {
                          dispatch.editHairStatus.setState({data: 0 , type: SET_HAIR_PICKER_COLOR_B})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: state.color.r,
                              g: state.color.g,
                              b: 0,
                            },
                          });
                        } else if (Number(e.target.value) >= 256) {
                          dispatch.editHairStatus.setState({data: 255 , type: SET_HAIR_PICKER_COLOR_B})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: state.color.r,
                              g: state.color.g,
                              b: 255,
                            },
                          });
                        } else {
                          dispatch.editHairStatus.setState({data: Number(e.target.value) , type: SET_HAIR_PICKER_COLOR_B})
                          dispatch.editHairStatus.hairSettingReducer({
                            type: "color",
                            payload: {
                              r: state.color.r,
                              g: state.color.g,
                              b: Number(e.target.value),
                            },
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                className="action-HEXcolor"
              
                key="input_radiusx"
              >
                <span className="action-color"> </span>
                <span className="action-color-title">#</span>
                <Input
                  className="action-HEXcolor-input"
                  value={hairPickerColorHEX.replace("#", "")}
                  maxLength={6}
                  onChange={(e) => {
                    if (!e.target.value.includes("#")) {
                      dispatch.editHairStatus.setState({data:`#${e.target.value}`,type:SET_HAIR_PICKER_COLOR_HEX})
                      if (e.target.value.length === 6) {
                        dispatch.editHairStatus.hairSettingReducer({
                          type: "color",
                          payload: {
                            r: hexToRgb(e.target.value)!.r,
                            g: hexToRgb(e.target.value)!.g,
                            b: hexToRgb(e.target.value)!.b,
                          },
                        });
                      }
                    } else {
                      dispatch.editHairStatus.setState({data:e.target.value , type: SET_HAIR_PICKER_COLOR_HEX})
                      if (e.target.value.length === 6) {
                        dispatch.editHairStatus.hairSettingReducer({
                          type: "color",
                          payload: {
                            r: hexToRgb(e.target.value)!.r,
                            g: hexToRgb(e.target.value)!.g,
                            b: hexToRgb(e.target.value)!.b,
                          },
                        });
                      }
                    }
                  }}
                  onBlur={inputValue}
                />
              </Form.Item>
            </div>

            <Divider className="divider" />

            {/* 調整ABC */}
            <div className="edit-modifyModel-layout">
              <p className="edit-title">Color Balance</p>
              <p className="edit-hint">
                Select your mode and input value to mix the hair color.
              </p>

              {/* 調整ABC position */}
              <Form.Item className="action-button-from">
                <span className="action-color-balance">Color Balance</span>
                <Cascader
                  className="action-color-value"
                  style={{ width: "200px" }}
                  disabled
                />
              </Form.Item>
              <Form.Item className="action-button-from">
                <span className="action-color-balance">Opacity</span>
                <Input
                  className="action-color-value"
                  step={1}
                  max={100}
                  min={0}
                  type={"number"}
                  name={"softness"}
                  onChange={onChangeValue}
                  value={state.softness}
                />
                %
              </Form.Item>
              <Form.Item className="action-button-from">
                <span className="action-color-balance">Feather Edges</span>
                <Input
                  className="action-color-value"
                  step={1}
                  max={100}
                  min={0}
                  type={"number"}
                  name={"feather"}
                  onChange={onChangeValue}
                  value={state.feather}
                  disabled
                />
                %
              </Form.Item>
            </div>
          </div>

          {/* Right side */}
          <div className="edit-ar-right">
            {/* preview */}
            <div className="preview-group">
              <BaseArViewerController
                template={undefined}
                hairTemplate={template}
                arSwitch={arSwitch}
                arLoading={(value:boolean)=>{dispatch.editHairStatus.setState({data: value , type: SET_AR_LOADING})}}
              />
              <div className="ar-preview">
                {arLoading ? (
                  <div className="preview-loading">
                    <LoadingOutlined
                      style={{ fontSize: "2rem", color: "white" }}
                    />
                  </div>
                ) : arView ? null : (
                  <div className="preview-loading">
                    <p style={{ fontSize: "1rem", color: "white" }}>
                      Open AR mode error
                    </p>
                  </div>
                )}
                <div className="preview" ref={modelViewDom}>
                  {/* 示範圖片 */}
                </div>
              </div>
            </div>
            {/* 微調的 icon */}
            {/* buttons */}
            <div className="button-group">
              <Button
                className="btn-cancel"
                onClick={handleCancel}
                shape="round"
                size="large"
              >
                Back
              </Button>
              <Button
                className="btn-next"
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                loading={isSubmit}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditHair;
