import { Button, Modal } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import axios from "axios";
import { ErrorCodes } from "@/proto/Common_pb";

// export enum UploadIconEnum { 
//   UPLOADFILE = 0,
//   FIRST = 1,
//   SECOND = 2,
//   THIRD = 3,
// }

type FileProps = {
  token: string;
  fileList: any;
  setImageFileId: Function;
  setImageFilePath: Function;
  showDelete: boolean;
  showDeleteColor: string | undefined;
};

const EditUploadImage: React.FC<FileProps> = function (props) {
  const fileList = props.fileList;
  const token = props.token;
  const showDelete = props.showDelete;
  const showDeleteColor = props.showDeleteColor;

  const [imagePath, setImagePath] = useState<string>();
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const uploadImage = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (fileList) {
      setImagePath(fileList);
    }
  }, [fileList]);

  function onClickUpload() {
    uploadInput.current?.click();
  }

  function handleClear(event: React.MouseEvent<HTMLButtonElement>) {
    setImagePath("");
    props.setImageFileId("");
    props.setImageFilePath("");
    uploadInput.current!.value = "";
  }

  async function onChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const fileList: FileList | null = event.target.files;
    if (fileList === null || fileList.length === 0) {
      uploadInput.current!.value = "";
      return;
    } else {
      checkSize(fileList);
    }
  }

  async function checkSize(fileList: FileList) {
    if (fileList[0].size / (1024 * 1024) > 1) {
      uploadInput.current!.value = "";
      Modal.error({
        title: <h3>Error</h3>,
        icon: <WarningOutlined />,
        content: <h6>File size must not exceed 1MB</h6>,
        okText: "OK",
        maskClosable: true,
        width: 500,
      });
      return;
    } else {
      let fileReader = new FileReader();
      // fileReader.onload = (file) => {
      //   uploadImage.current!.src = file.target!.result as string;
      // };
      fileReader.readAsDataURL(fileList[0]);
      const data = new FormData();
      data.append("file", fileList[0]);
      try {
        const result = await axios({
          url: `${process.env.REACT_APP_PUBLIC_API_IMG}/upload_file`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
          data: data,
        });
        if (result.data.Code === ErrorCodes.SUCCESS) {
          props.setImageFileId(result.data.FileName);
          props.setImageFilePath(result.data.FilePath);
          setImagePath(result.data.FilePath)
        } else {
          //todo error handle
          throw new Error();
        }
      } catch (error) {
        Modal.error({
          title: <h3>Error</h3>,
          icon: <WarningOutlined />,
          content: <h6>The file was not uploaded successfully</h6>,
          okText: "OK",
          maskClosable: true,
          width: 500,
        });
      }
    }
  }

  return (
    <>
      <div
        className="upload-image"
        style={{ display: imagePath ? "none" : "flex" }}
      >
        <div className="ant-upload-drag-icon">
          <img
            className="upload-icon"
            src="/images/menuIcon/E1_03_icon_upload.svg"
            alt="upload"
          />
        </div>
        <Button
          className="btn-upload-image ImageA"
          type="primary"
          shape="round"
          onClick={onClickUpload}
        >
          Upload Image
        </Button>
      </div>
      <input
        name="ImageA"
        type="file"
        placeholder="Please upload image"
        onChange={onChangeFile}
        accept="image/png , image/jpeg"
        ref={uploadInput}
      />
      <div
        className="uploaded-image-group"
        style={{ display: imagePath ? "" : "none" }}
      >
        <div className="uploaded-image-item">
          <img
            className="uploaded-image"
            src={imagePath ? imagePath : ""}
            alt=""
            ref={uploadImage}
          />
        </div>
        <div className="btn-delete-image-group">
          <Button
            className="btn-change-image"
            name="imgA"
            type="primary"
            shape="round"
            ghost
            size="middle"
            onClick={onClickUpload}
          >
            Change Image
          </Button>
          {showDelete ? (
            showDeleteColor ? (
              <Button className="btn-delete-image">
                <img
                  src="/images/menuIcon/E1_03_icon_delete.svg"
                  alt="delete"
                  style={{
                    cursor: "no-drop",
                    filter:
                      "invert(77%) sepia(3%) saturate(4%) hue-rotate(9deg) brightness(90%) contrast(85%)",
                  }}
                />
              </Button>
            ) : (
              <Button className="btn-delete-image" onClick={handleClear}>
                <img
                  src="/images/menuIcon/E1_03_icon_delete.svg"
                  alt="delete"
                  style={{
                    filter:
                      "invert(65%) sepia(81%) saturate(5579%) hue-rotate(190deg) brightness(100%) contrast(101%)",
                  }}
                />
              </Button>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EditUploadImage;
