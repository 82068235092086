import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import { IAction, ITransform } from "@/components/templateInterface";
import { EarringTemplatePosition } from "@/components/Helpers/SetTemplate";

interface ISideBarStatus {
  collapsed: boolean;
  accountName: string;
  abbr: string;
  select: Array<string>;
}
export const SideBarStatus = createModel<RootModel>()({
  state: {
    collapsed: true,
    abbr: "",
    accountName: "",
    select: [""],
  } as ISideBarStatus,
  
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
  },
  effects: (dispatch) => ({
    clearup() {
      dispatch.SideBarStatus.setState({ data: true, type: SET_COLLAPSED });
      dispatch.SideBarStatus.setState({ data: "", type: SET_ABBR });
      dispatch.SideBarStatus.setState({ data: "", type: SET_ACCOUNTNAME });
      dispatch.SideBarStatus.setState({ data: [""], type: SET_SELECT });
    },
  }),
});

export const SET_COLLAPSED = "collapsed";
export const SET_ABBR = "abbr";
export const SET_ACCOUNTNAME = "accountname";
export const SET_SELECT = "select";
