import {BaseArView, Landmarks} from "@ar_template/component/ar_view/BaseArView";
import * as THREE from "three";
import { IVector3 } from "@ar_template/component/interface/IVector3";
import { ISize } from '@ar_template/component/interface/ISize';
import { degrees_to_radians, dist, get_landmark_point, predict } from "@ar_template/component/helper";
import * as ort from "onnxruntime-web";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

export class LensTemplate extends BaseArView {
    private initFinish = false;
    private offsetPos: IVector3 = { x: 0, y: -38, z: -1372 };
    private offsetRotation: IVector3 = { x: 1.7, y: 0, z: 0 };
    private offsetScale: IVector3 = { x: 100, y: 100, z: 100 };
    private offsetScaleHead: IVector3 = { x: 2.64, y: 2.64, z: 2.64 };

    private templateObject = {
        right: new THREE.Object3D(),
        rightTransform: {
            position: new THREE.Vector3(),
            rotation: new THREE.Euler(),
            scale: new THREE.Vector3(),
            orgScale: new THREE.Vector3(),
            settingTransform: {
                position: { x: 0, y: 0, z: 0 },
                rotation: { x: 0, y: 0, z: 0 },
                scale: { x: 0, y: 0, z: 0 }
            }
        },
        left: new THREE.Object3D(),
        leftTransform: {
            position: new THREE.Vector3(),
            rotation: new THREE.Euler(),
            scale: new THREE.Vector3(),
            orgScale: new THREE.Vector3(),
            settingTransform: {
                position: { x: 0, y: 0, z: 0 },
                rotation: { x: 0, y: 0, z: 0 },
                scale: { x: 0, y: 0, z: 0 }
            }
        },
        radius: 0,
        rightEyeLens: new THREE.Mesh,
        leftEyeLens: new THREE.Mesh
    }

    private faceMesh: THREE.Mesh = new THREE.Mesh();

    constructor(domElement: HTMLDivElement, canvasSize: ISize, trackingCallback?: (isFind: boolean) => void) {
        super(domElement, canvasSize, "landmark", trackingCallback);
    }

    async ChangeTexture(url: string, part: "right" | "left"): Promise<boolean> {
        // console.log("change change texture", part);
        const tex = await this.imageLoader.loadAsync(url);
        tex.flipY = false;
        tex.encoding = THREE.sRGBEncoding;

        if (part === "right" || part === "left") {
            const changeObject = this.templateObject[part].children[0];
            if (changeObject instanceof THREE.Mesh) {
                changeObject.material.map = tex;
            }

            if (part === "right") {
                if(this.templateObject.rightEyeLens.material instanceof THREE.MeshStandardMaterial) {
                    this.templateObject.rightEyeLens.material.map = tex;
                }
            } else if (part === "left") {
                if(this.templateObject.leftEyeLens.material instanceof THREE.MeshStandardMaterial) {
                    this.templateObject.leftEyeLens.material.map = tex;
                }
            }

            return true;
        }

        return false;
    }

    LoadTemplate(openArMode: boolean = false,
        initTransform: {
            radius: number,
            right: { position: IVector3, rotation: IVector3, scale: IVector3 },
            left: { position: IVector3, rotation: IVector3, scale: IVector3 }
        } | undefined = undefined,
        callback: (status: string) => void = () => null): void {
        const sceneUrl = "/static/ar_template/template_lens_01_scale1.json";

        if (initTransform) {
            this.templateObject.radius = initTransform.radius;
            this.templateObject.rightTransform.settingTransform.position = initTransform.right.position;
            this.templateObject.rightTransform.settingTransform.rotation = initTransform.right.rotation;
            this.templateObject.rightTransform.settingTransform.scale = initTransform.right.scale;

            this.templateObject.leftTransform.settingTransform.position = initTransform.left.position;
            this.templateObject.leftTransform.settingTransform.rotation = initTransform.left.rotation;
            this.templateObject.leftTransform.settingTransform.scale = initTransform.left.scale;
        }

        this.LoadScene(sceneUrl, async (obj: any) => {
            const gltfLoader = new GLTFLoader();
            const lensModel = await gltfLoader.loadAsync("/static/ar_template/pupil.glb");

            this.faceMesh = await this.loadFaceMesh();
            this.scene.add(this.faceMesh);
            this.faceMesh.visible = false;

            if (lensModel.scene.children[0] instanceof THREE.Mesh) {
                this.templateObject.leftEyeLens = lensModel.scene.children[0].clone();
                this.templateObject.rightEyeLens = lensModel.scene.children[0].clone();
                this.templateObject.leftEyeLens.scale.setScalar(185);
                this.templateObject.rightEyeLens.scale.setScalar(185);
                if(this.templateObject.leftEyeLens.material instanceof THREE.MeshStandardMaterial) {
                    this.templateObject.leftEyeLens.material.transparent = true;
                }

                if(this.templateObject.rightEyeLens.material instanceof THREE.MeshStandardMaterial) {
                    this.templateObject.rightEyeLens.material.transparent = true
                }

                this.scene.add(this.templateObject.leftEyeLens);
                this.scene.add(this.templateObject.rightEyeLens);
            }

            this.modelObject = new THREE.Object3D();
            this.modelObject.add(obj);
            this.scene.add(this.modelObject);

            this.modelObject.scale.setScalar(12);
            const light = new THREE.AmbientLight(0xffffff, 0.8);
            this.scene.add(light);
            this.modelObject.rotateY(degrees_to_radians(-40));
            this.modelObject.rotateX(degrees_to_radians(4));
            this.modelObject.children[0].rotation.y = degrees_to_radians(180);
            this.modelObject.traverse(async (item) => {
                if (item instanceof THREE.Light) {
                    item.parent = this.scene;
                }

                if (item instanceof THREE.Mesh && item.userData.isOccluder) {
                    const occlusionMat = new THREE.ShaderMaterial({
                        vertexShader: THREE.ShaderLib.basic.vertexShader,
                        fragmentShader: "precision lowp float;\n void main(void){\n gl_FragColor = vec4(1.,0.,0.,1.);\n }",
                        uniforms: THREE.ShaderLib.basic.uniforms,
                        side: THREE.DoubleSide,
                        colorWrite: false
                    });
                    item.renderOrder = -1e12; // render first
                    item.material = occlusionMat;
                    this.occlusionObject = item;
                    item.visible = false;
                }

                if (item.userData.head_obj) {
                    this.headObj = item;
                }

                if (item instanceof THREE.Group) {
                    if (item.name === "left") {
                        this.templateObject.leftTransform.orgScale = item.scale.clone();
                        this.templateObject.left = item;
                        this.templateObject.leftTransform.position = item.position.clone();
                        this.templateObject.leftTransform.rotation = item.rotation.clone();
                        this.templateObject.leftTransform.scale = item.scale.clone();
                        if (initTransform) {
                            const _posL = this.templateObject.leftTransform.position.clone().add(new THREE.Vector3(initTransform.left.position.x,
                                initTransform.left.position.y, initTransform.left.position.z));
                            const _rotL = this.templateObject.leftTransform.rotation.clone();
                            const _scaleL = this.templateObject.leftTransform.scale.clone()
                                .multiply(new THREE.Vector3(initTransform.left.scale.x, initTransform.left.scale.y, initTransform.left.scale.z))
                                .multiply(new THREE.Vector3(initTransform.radius, initTransform.radius, initTransform.radius));
                            this.templateObject.left.position.set(_posL.x, _posL.y, _posL.z);
                            this.templateObject.left.rotation.set(_rotL.x + degrees_to_radians(initTransform.left.rotation.x),
                                _rotL.y + degrees_to_radians(initTransform.left.rotation.y), _rotL.z + degrees_to_radians(initTransform.left.rotation.z));
                            this.templateObject.left.scale.set(_scaleL.x, _scaleL.y, _scaleL.z)
                        }

                    } else if (item.name === "right") {
                        this.templateObject.rightTransform.orgScale = item.scale.clone();
                        this.templateObject.right = item;
                        this.templateObject.rightTransform.position = item.position.clone();
                        this.templateObject.rightTransform.rotation = item.rotation.clone();
                        this.templateObject.rightTransform.scale = item.scale.clone();
                        if (initTransform) {
                            const _posR = this.templateObject.rightTransform.position.clone().add(new THREE.Vector3(initTransform.right.position.x,
                                initTransform.right.position.y, initTransform.right.position.z));
                            const _rotR = this.templateObject.rightTransform.rotation.clone();
                            const _scaleR = this.templateObject.rightTransform.scale.clone()
                                .multiply(new THREE.Vector3(initTransform.right.scale.x, initTransform.right.scale.y, initTransform.right.scale.z))
                                .multiply(new THREE.Vector3(initTransform.radius, initTransform.radius, initTransform.radius));
                            this.templateObject.right.position.set(_posR.x, _posR.y, _posR.z);
                            this.templateObject.right.rotation.set(_rotR.x + degrees_to_radians(initTransform.right.rotation.x),
                                _rotR.y + degrees_to_radians(initTransform.right.rotation.y), _rotR.z + degrees_to_radians(initTransform.right.rotation.z));
                            this.templateObject.right.scale.set(_scaleR.x, _scaleR.y, _scaleR.z)
                        }
                    }
                }
            });

            try {
                if (openArMode) {
                    if (!await this.OpenArMode(true)) {
                        throw new Error();
                    }
                    this.initFinish = true;
                    callback("done")
                }
                else {
                    setTimeout(() => {
                        this.initFinish = true;
                        callback("done")
                    }, 500);
                }

            } catch {
                console.error("open ar mode error");
                callback("error");
            }
        })
    }

    public SetTransform(side: "right" | "left", position: IVector3, rotation: IVector3, scale: IVector3) {
        // console.log(`set transform`)
        const { radius } = this.templateObject;


        if (side === "right") {
            //右眼設定
            this.templateObject.rightTransform.settingTransform = { position, rotation, scale };
            const _posR = this.templateObject.rightTransform.position.clone().add(new THREE.Vector3(position.x, position.y, position.z));
            const _rotR = this.templateObject.rightTransform.rotation.clone();
            const _scaleR = this.templateObject.rightTransform.orgScale.clone().multiply(new THREE.Vector3(scale.x, scale.y, scale.z))
                .multiply(new THREE.Vector3(radius, radius, radius))

            this.templateObject.right.position.set(_posR.x, _posR.y, _posR.z);
            this.templateObject.right.rotation.set(_rotR.x + degrees_to_radians(rotation.x), _rotR.y + degrees_to_radians(rotation.y),
                _rotR.z + degrees_to_radians(rotation.z));
            this.templateObject.right.scale.set(_scaleR.x, _scaleR.y, _scaleR.z)
        }
        else if (side === "left") {
            //左眼設定
            this.templateObject.leftTransform.settingTransform = { position, rotation, scale };

            const _posL = this.templateObject.leftTransform.position.clone().add(new THREE.Vector3(position.x, position.y, position.z));
            const _rotL = this.templateObject.leftTransform.rotation.clone();
            const _scaleL = this.templateObject.leftTransform.orgScale.clone().multiply(new THREE.Vector3(scale.x, scale.y, scale.z)).multiply(new THREE.Vector3(radius, radius, radius));

            this.templateObject.left.position.set(_posL.x, _posL.y, _posL.z);
            this.templateObject.left.rotation.set(_rotL.x + degrees_to_radians(rotation.x), _rotL.y + degrees_to_radians(rotation.y),
                _rotL.z + degrees_to_radians(rotation.z));
            this.templateObject.left.scale.set(_scaleL.x, _scaleL.y, _scaleL.z);
        }
    }

    public SetRadius(radius: number) {
        this.templateObject.radius = radius;
        const rightTransform = this.templateObject.rightTransform.settingTransform;
        const _scaleR = this.templateObject.rightTransform.scale.clone().multiply(new THREE.Vector3(rightTransform.scale.x, rightTransform.scale.y, rightTransform.scale.z))
            .multiply(new THREE.Vector3(radius, radius, radius));
        const leftTransform = this.templateObject.leftTransform.settingTransform;
        const _scaleL = this.templateObject.leftTransform.scale.clone().multiply(new THREE.Vector3(leftTransform.scale.x, leftTransform.scale.y, leftTransform.scale.z))
            .multiply(new THREE.Vector3(radius, radius, radius));

        this.templateObject.right.scale.set(_scaleR.x, _scaleR.y, _scaleR.z);
        this.templateObject.left.scale.set(_scaleL.x, _scaleL.y, _scaleL.z);
    }

    public override async OpenArMode(viewer: boolean = false): Promise<undefined | boolean> {
        this.templateObject.left.visible = false;
        this.templateObject.right.visible = false;
        this.templateObject.rightEyeLens.visible = true;
        this.templateObject.leftEyeLens.visible = true;
        this.faceMesh.visible = true;
        return super.OpenArMode(viewer);
    }

    public override CloseArMode() {
        super.CloseArMode();
        const { leftTransform, rightTransform, radius } = this.templateObject;
        const _scaleL = this.templateObject.leftTransform.orgScale.clone().multiply(new THREE.Vector3(leftTransform.settingTransform.scale.x,
          leftTransform.settingTransform.scale.y, leftTransform.settingTransform.scale.z))
          .multiply(new THREE.Vector3(radius, radius, radius));
        const _scaleR = this.templateObject.rightTransform.orgScale.clone().multiply(new THREE.Vector3(rightTransform.settingTransform.scale.x,
          rightTransform.settingTransform.scale.y, rightTransform.settingTransform.scale.z))
          .multiply(new THREE.Vector3(radius, radius, radius));

        this.templateObject.left.visible = true;
        this.templateObject.right.visible = true;
        this.templateObject.rightEyeLens.visible = false;
        this.templateObject.leftEyeLens.visible = false;
        this.faceMesh.visible = false;
    }

    protected override async GetFrameFromVideo() {
        await super.GetFrameFromVideo();
        try {
            const imageData = this.ctx!.getImageData(0, 0, 192, 192);
            const colorData = new Array<number>();
            for (let i = 0; i < imageData.data.length; i += 4) {
                colorData.push((imageData.data[i] - 127.5) / 127.5);
                colorData.push((imageData.data[i + 1] - 127.5) / 127.5);
                colorData.push((imageData.data[i + 2] - 127.5) / 127.5);
            }
            const result = await predict(new ort.Tensor("float32", colorData, [1, 192, 192, 3]));
            if (result) {
                const facecorner: ort.Tensor = (result as ort.InferenceSession.OnnxValueMapType)["facecorner"];
                const landmarks: ort.Tensor = (result as ort.InferenceSession.OnnxValueMapType)["landmarks"];
                const landmark: ort.Tensor = (result as ort.InferenceSession.OnnxValueMapType)["facemesh"];
                const pose: ort.Tensor = (result as ort.InferenceSession.OnnxValueMapType)["pose"];

                const nosePoint = get_landmark_point(landmark, Landmarks.LANDMARK_INDEX_Nose);
                const rightEyeOutter = get_landmark_point(landmark, Landmarks.LANDMARK_INDEX_RightEyeOutter);
                const leftEyeOutter = get_landmark_point(landmark, Landmarks.LANDMARK_INDEX_LeftEyeOutter);
                const rE = new THREE.Vector3(rightEyeOutter.x, rightEyeOutter.y, rightEyeOutter.z);
                const lE = new THREE.Vector3(leftEyeOutter.x, leftEyeOutter.y, leftEyeOutter.z);
                const dis = lE.distanceTo(rE);

                let cacheLandmark = [];
                for (let i = 0; i < landmark.data.length; i++) {
                    const point = get_landmark_point(landmark, i);
                    // const x = ((point.x / 192) - 0.5) * 500;
                    let x = ((point.x / 192) - 0.5) * 500;
                    let y = (0.5 - (point.y / 192)) * 500;
                    let z = point.z;
                    cacheLandmark.push(x, y, -z);
                }
                this.faceMesh.geometry.setAttribute('position', new THREE.Float32BufferAttribute(cacheLandmark, 3));
                (this.faceMesh.geometry.attributes['position'] as THREE.BufferAttribute).needsUpdate = true
                let lenScale = dis * 3.3;
                this.templateObject.leftEyeLens.scale.setScalar(lenScale)
                this.templateObject.leftEyeLens.scale.multiply(
                  new THREE.Vector3(this.templateObject.leftTransform.settingTransform.scale.x, this.templateObject.leftTransform.settingTransform.scale.y, this.templateObject.leftTransform.settingTransform.scale.z)
                ).multiply(new THREE.Vector3(this.templateObject.radius, this.templateObject.radius, this.templateObject.radius));
                this.templateObject.rightEyeLens.scale.setScalar(lenScale)
                this.templateObject.rightEyeLens.scale.multiply(
                  new THREE.Vector3(this.templateObject.rightTransform.settingTransform.scale.x, this.templateObject.rightTransform.settingTransform.scale.y, this.templateObject.rightTransform.settingTransform.scale.z)
                ).multiply(new THREE.Vector3(this.templateObject.radius, this.templateObject.radius, this.templateObject.radius));

                if(this.trackingCallback) {
                    this.trackingCallback((facecorner.data[4] as number) >= 0.65)
                }

                if( (facecorner.data[4] as number) >= 0.65) {
                    this.templateObject.leftEyeLens.visible = true;
                    this.templateObject.rightEyeLens.visible = true;
                }
                else {
                    this.templateObject.leftEyeLens.visible = false;
                    this.templateObject.rightEyeLens.visible = false;
                }

                //104, 105
                const rightEye = get_landmark_point(landmarks, 105);
                const leftEye = get_landmark_point(landmarks, 104);
                this.SetLeftEye(leftEye, pose);
                this.SetRightEye(rightEye, pose);
                if(this.occlusionObject) {
                    this.occlusionObject.visible = false;
                }
            }

        } catch {
            console.log("error show")
        }
    }

    SetLeftEye(point: IVector3, pose: ort.Tensor) {
        let x = (point.x / 192) - 0.5;
        x *= 500;
        let y = (0.5 - (point.y / 192));
        y *= 500;
        const yaw = pose.data[0];
        const pitch = pose.data[1];
        const roll = pose.data[2];
        x -= this.templateObject.leftTransform.settingTransform.position.x*10;
        y += this.templateObject.leftTransform.settingTransform.position.y*10;
        this.templateObject.leftEyeLens.position.set(x, y, -10);
        this.templateObject.leftEyeLens.rotation.set(degrees_to_radians(-(pitch as number)+90), degrees_to_radians(yaw as number), degrees_to_radians(roll as number))
    }

    SetRightEye(point: IVector3, pose: ort.Tensor) {
        let x = (point.x / 192) - 0.5;
        x *= 500;
        let y = (0.5 - (point.y / 192));
        y *= 500;

        const yaw = pose.data[0];
        const pitch = pose.data[1];
        const roll = pose.data[2];
        x -= this.templateObject.rightTransform.settingTransform.position.x*10;
        y += this.templateObject.rightTransform.settingTransform.position.y*10;
        this.templateObject.rightEyeLens.position.set(x, y, -10);
        this.templateObject.rightEyeLens.rotation.set(degrees_to_radians(-(pitch as number)+90), degrees_to_radians(yaw as number), degrees_to_radians(roll as number))

    }
}