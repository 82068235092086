import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import routes from "@/routers";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";
import { StatisticType } from "@/proto/Statistic_pb";

interface IBackgroundDataStatus {
    token: string,
       isloading:boolean,
       userId:string,
       dropList:Array<[]>,
       searchData: [],
       projectId:string,
       datetimeStart:string,
       datetimeEnd:string,
       statisticType:StatisticType,
       sumDatalist:[],
       typeName:'Opens',
       total:number
}


export const BackgroundDataStatus = createModel<RootModel>()({
    state: {
       token: "",
       isloading:true,
       userId:'',
       dropList:[],
       searchData: [],
       projectId:'',
       datetimeStart:'',
       datetimeEnd:'',
       statisticType:StatisticType.VIEWER_OPEN,
       sumDatalist:[],
       typeName:'Opens',
       total:0
    },
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        }
    },
    effects: (dispatch) =>({
        clearup () {
            dispatch.BackgroundDataStatus.setState({data:'', type:SET_TOKEN})
            dispatch.BackgroundDataStatus.setState({data:true,type:SET_ISLOADING})
            dispatch.BackgroundDataStatus.setState({data:'',type:SET_USER_ID})
            dispatch.BackgroundDataStatus.setState({data:'',type:SET_PROJECT_ID})
            dispatch.BackgroundDataStatus.setState({data:0,type:SET_TOTAL})
        }
     
    })
});

export const SET_TOKEN = "token";
export const SET_ISLOADING = "isloading";
export const SET_USER_ID = "userId";
export const SET_DROPLIST = 'dropList';
export const SET_SEARCH_DATA = 'searchData';
export const SET_PROJECT_ID = 'projectId';
export const SET_DATETIME_START = 'datetimeStart';
export const SET_DATETIME_END = 'datetimeEnd';
export const SET_STATSTICT_TYPE = 'statisticType';
export const SET_SUM_DATA_LIST = 'sumDatalist';
export const SET_TYPE_NAME = 'typeName';
export const SET_TOTAL = 'total';