import { createBrowserRouter } from "react-router-dom";
import LazyLoadRoutes from "@/components/LazyLoadRoutes";

const router_path = {
  index: `/`,
  login: `/login`,
  signUp: `/sign-up`,
  sendEmail: `/send-email`,
  resendForgotEmail: `/resend-forgot-email`,
  arProject: {
    index: `/ar-project`,
    create: `/ar-project/create`,
    edit: `/ar-project/:pid`,
    editContent: `/ar-project/:pid/edit-content`,
    editViewer: `/ar-project/:pid/edit-viewer`,
  },
  arViewer: {
    index: `/ar/:pid`,
  },
  account: {
    index: `/account`,
    basicInfo: `/account/basic-info`,
    // authority: `/account/authority`,
    // traffic: `/account/traffic`,
  },
  payment: {
    // billingRecords: `/payment/billing-records`,
    // method: `/payment/method`,
    // purchasePlan: `/payment/purchase-plan`
  },
  confirmRegister: `/confirm-register/:pid`,
  helpCenter: `/help-center`,
  backgroundData: `/background-data`,
};
const routers = createBrowserRouter([
  {
    path: router_path.login,
    element: <LazyLoadRoutes componentName="Login" />,
  },
  {
    path: router_path.index,
    element: <LazyLoadRoutes componentName="Index" />
  },
  {
    path: router_path.helpCenter,
    element: <LazyLoadRoutes componentName="HelpCenter" />
  },
  {
    path: router_path.resendForgotEmail,
    element: <LazyLoadRoutes componentName="resendForgotEmail" />
  },
  {
    path: router_path.sendEmail,
    element: <LazyLoadRoutes componentName="SendEmail" />
  },
  {
    path: router_path.confirmRegister,
    element: <LazyLoadRoutes componentName="confirm-register/[pid]/index" />
  },
  {
    path: router_path.signUp,
    element: <LazyLoadRoutes componentName="SignUp" />
  },
  {
    path: router_path.arViewer.index,
    element: <LazyLoadRoutes componentName="arViewer/Index" />
  },

  {
    path: router_path.index,
    element: <LazyLoadRoutes componentName="Dashboard" />,
    children: [
      {
        path: router_path.backgroundData,
        element: <LazyLoadRoutes componentName="background-data" />,
      },
      {
        path: router_path.arProject.index,
        children: [
          {
            path: router_path.arProject.index,
            element: <LazyLoadRoutes componentName="arProject/Index" />,
          },
          {
            path: router_path.arProject.create,
            element: <LazyLoadRoutes componentName="arProject/Create" />,
          },
          {
            path: router_path.arProject.edit,

            children: [
              {
                path: router_path.arProject.edit,
                element: <LazyLoadRoutes componentName="arProject/[pid]/index" />,
              },
              {
                path: router_path.arProject.editContent,
                element: <LazyLoadRoutes componentName="arProject/[pid]/edit-content" />,
              },
              {
                path: router_path.arProject.editViewer,
                element: <LazyLoadRoutes componentName="arProject/[pid]/edit-viewer" />,
              },
            ],
          },
        ],
      },
      {
        path: router_path.account.index,
        children: [
          {
            path: router_path.account.basicInfo,
            element: <LazyLoadRoutes componentName="account/basic-info" />,
          },
        ],
      },
      // {
      //   path: router_path.arProject.create,
      //   element: LazyLoadRoutes("arProject/Create")
      // },
    ],
  },
]);

const unprotect_router = [
  router_path.login,
  router_path.signUp,
  router_path.sendEmail,
  router_path.resendForgotEmail,
  router_path.confirmRegister,
  router_path.helpCenter,
  router_path.arViewer.index,
];
export default {
  unprotect_router,
  router_path,
  routers,
};
