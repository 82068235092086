// source: ArContent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var Common_pb = require('./Common_pb.js');
goog.object.extend(proto, Common_pb);
goog.exportSymbol('proto.ArContent.ArContentCategoryEnum', null, global);
goog.exportSymbol('proto.ArContent.ArContentCollet', null, global);
goog.exportSymbol('proto.ArContent.ArContentImageType', null, global);
goog.exportSymbol('proto.ArContent.ArContentInfo', null, global);
goog.exportSymbol('proto.ArContent.ArContentTemplateEnum', null, global);
goog.exportSymbol('proto.ArContent.ArContentTypeEnum', null, global);
goog.exportSymbol('proto.ArContent.ArViewerSetting', null, global);
goog.exportSymbol('proto.ArContent.CreateArContentReply', null, global);
goog.exportSymbol('proto.ArContent.CreateArContentRequest', null, global);
goog.exportSymbol('proto.ArContent.DeleteArContentRequest', null, global);
goog.exportSymbol('proto.ArContent.DuplicateArContentReply', null, global);
goog.exportSymbol('proto.ArContent.DuplicateArContentRequest', null, global);
goog.exportSymbol('proto.ArContent.GetArContentListReply', null, global);
goog.exportSymbol('proto.ArContent.GetArContentListRequest', null, global);
goog.exportSymbol('proto.ArContent.GetArContentReply', null, global);
goog.exportSymbol('proto.ArContent.GetArContentRequest', null, global);
goog.exportSymbol('proto.ArContent.GetArLinkReply', null, global);
goog.exportSymbol('proto.ArContent.GetArLinkRequest', null, global);
goog.exportSymbol('proto.ArContent.GetCategoryListReply', null, global);
goog.exportSymbol('proto.ArContent.GetCategoryListRequest', null, global);
goog.exportSymbol('proto.ArContent.GetTemplateListReply', null, global);
goog.exportSymbol('proto.ArContent.GetTemplateListRequest', null, global);
goog.exportSymbol('proto.ArContent.GetTypeListReply', null, global);
goog.exportSymbol('proto.ArContent.GetTypeListRequest', null, global);
goog.exportSymbol('proto.ArContent.GetViewerDataReply', null, global);
goog.exportSymbol('proto.ArContent.GetViewerDataRequest', null, global);
goog.exportSymbol('proto.ArContent.LinkButton', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentIsOnRequest', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentReply', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentRequest', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentTemplateReply', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentTemplateRequest', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentThreeDModelRequest', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentViewerReplay', null, global);
goog.exportSymbol('proto.ArContent.UpdateArContentViewerRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetArContentListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetArContentListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetArContentListRequest.displayName = 'proto.ArContent.GetArContentListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetArContentListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArContent.GetArContentListReply.repeatedFields_, null);
};
goog.inherits(proto.ArContent.GetArContentListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetArContentListReply.displayName = 'proto.ArContent.GetArContentListReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetCategoryListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetCategoryListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetCategoryListRequest.displayName = 'proto.ArContent.GetCategoryListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetCategoryListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArContent.GetCategoryListReply.repeatedFields_, null);
};
goog.inherits(proto.ArContent.GetCategoryListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetCategoryListReply.displayName = 'proto.ArContent.GetCategoryListReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetTypeListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetTypeListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetTypeListRequest.displayName = 'proto.ArContent.GetTypeListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetTypeListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArContent.GetTypeListReply.repeatedFields_, null);
};
goog.inherits(proto.ArContent.GetTypeListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetTypeListReply.displayName = 'proto.ArContent.GetTypeListReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetTemplateListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetTemplateListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetTemplateListRequest.displayName = 'proto.ArContent.GetTemplateListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetTemplateListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArContent.GetTemplateListReply.repeatedFields_, null);
};
goog.inherits(proto.ArContent.GetTemplateListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetTemplateListReply.displayName = 'proto.ArContent.GetTemplateListReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.CreateArContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.CreateArContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.CreateArContentRequest.displayName = 'proto.ArContent.CreateArContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.CreateArContentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.CreateArContentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.CreateArContentReply.displayName = 'proto.ArContent.CreateArContentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentThreeDModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentThreeDModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentThreeDModelRequest.displayName = 'proto.ArContent.UpdateArContentThreeDModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentTemplateRequest.displayName = 'proto.ArContent.UpdateArContentTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentTemplateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentTemplateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentTemplateReply.displayName = 'proto.ArContent.UpdateArContentTemplateReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentViewerRequest.displayName = 'proto.ArContent.UpdateArContentViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentViewerReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentViewerReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentViewerReplay.displayName = 'proto.ArContent.UpdateArContentViewerReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetArContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetArContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetArContentRequest.displayName = 'proto.ArContent.GetArContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetArContentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetArContentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetArContentReply.displayName = 'proto.ArContent.GetArContentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentRequest.displayName = 'proto.ArContent.UpdateArContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentReply.displayName = 'proto.ArContent.UpdateArContentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.UpdateArContentIsOnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.UpdateArContentIsOnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.UpdateArContentIsOnRequest.displayName = 'proto.ArContent.UpdateArContentIsOnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.DeleteArContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.DeleteArContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.DeleteArContentRequest.displayName = 'proto.ArContent.DeleteArContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.DuplicateArContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.DuplicateArContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.DuplicateArContentRequest.displayName = 'proto.ArContent.DuplicateArContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.DuplicateArContentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.DuplicateArContentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.DuplicateArContentReply.displayName = 'proto.ArContent.DuplicateArContentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.ArContentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.ArContentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.ArContentInfo.displayName = 'proto.ArContent.ArContentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.ArViewerSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.ArViewerSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.ArViewerSetting.displayName = 'proto.ArContent.ArViewerSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.LinkButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.LinkButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.LinkButton.displayName = 'proto.ArContent.LinkButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetArLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetArLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetArLinkRequest.displayName = 'proto.ArContent.GetArLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetArLinkReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetArLinkReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetArLinkReply.displayName = 'proto.ArContent.GetArLinkReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetViewerDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetViewerDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetViewerDataRequest.displayName = 'proto.ArContent.GetViewerDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContent.GetViewerDataReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContent.GetViewerDataReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContent.GetViewerDataReply.displayName = 'proto.ArContent.GetViewerDataReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetArContentListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetArContentListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetArContentListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageinfo: (f = msg.getPageinfo()) && Common_pb.PageInfoRequest.toObject(includeInstance, f),
    keyword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetArContentListRequest}
 */
proto.ArContent.GetArContentListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetArContentListRequest;
  return proto.ArContent.GetArContentListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetArContentListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetArContentListRequest}
 */
proto.ArContent.GetArContentListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Common_pb.PageInfoRequest;
      reader.readMessage(value,Common_pb.PageInfoRequest.deserializeBinaryFromReader);
      msg.setPageinfo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetArContentListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetArContentListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetArContentListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Common_pb.PageInfoRequest.serializeBinaryToWriter
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Common.PageInfoRequest pageInfo = 1;
 * @return {?proto.Common.PageInfoRequest}
 */
proto.ArContent.GetArContentListRequest.prototype.getPageinfo = function() {
  return /** @type{?proto.Common.PageInfoRequest} */ (
    jspb.Message.getWrapperField(this, Common_pb.PageInfoRequest, 1));
};


/**
 * @param {?proto.Common.PageInfoRequest|undefined} value
 * @return {!proto.ArContent.GetArContentListRequest} returns this
*/
proto.ArContent.GetArContentListRequest.prototype.setPageinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.GetArContentListRequest} returns this
 */
proto.ArContent.GetArContentListRequest.prototype.clearPageinfo = function() {
  return this.setPageinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.GetArContentListRequest.prototype.hasPageinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string keyword = 2;
 * @return {string}
 */
proto.ArContent.GetArContentListRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.GetArContentListRequest} returns this
 */
proto.ArContent.GetArContentListRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArContent.GetArContentListReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetArContentListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetArContentListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetArContentListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageinfo: (f = msg.getPageinfo()) && Common_pb.PageInfoReply.toObject(includeInstance, f),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.ArContent.ArContentInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetArContentListReply}
 */
proto.ArContent.GetArContentListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetArContentListReply;
  return proto.ArContent.GetArContentListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetArContentListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetArContentListReply}
 */
proto.ArContent.GetArContentListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Common_pb.PageInfoReply;
      reader.readMessage(value,Common_pb.PageInfoReply.deserializeBinaryFromReader);
      msg.setPageinfo(value);
      break;
    case 2:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetArContentListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetArContentListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetArContentListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Common_pb.PageInfoReply.serializeBinaryToWriter
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional Common.PageInfoReply pageInfo = 1;
 * @return {?proto.Common.PageInfoReply}
 */
proto.ArContent.GetArContentListReply.prototype.getPageinfo = function() {
  return /** @type{?proto.Common.PageInfoReply} */ (
    jspb.Message.getWrapperField(this, Common_pb.PageInfoReply, 1));
};


/**
 * @param {?proto.Common.PageInfoReply|undefined} value
 * @return {!proto.ArContent.GetArContentListReply} returns this
*/
proto.ArContent.GetArContentListReply.prototype.setPageinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.GetArContentListReply} returns this
 */
proto.ArContent.GetArContentListReply.prototype.clearPageinfo = function() {
  return this.setPageinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.GetArContentListReply.prototype.hasPageinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ArContentInfo data = 2;
 * @return {!Array<!proto.ArContent.ArContentInfo>}
 */
proto.ArContent.GetArContentListReply.prototype.getDataList = function() {
  return /** @type{!Array<!proto.ArContent.ArContentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ArContent.ArContentInfo, 2));
};


/**
 * @param {!Array<!proto.ArContent.ArContentInfo>} value
 * @return {!proto.ArContent.GetArContentListReply} returns this
*/
proto.ArContent.GetArContentListReply.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ArContent.ArContentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ArContent.ArContentInfo}
 */
proto.ArContent.GetArContentListReply.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ArContent.ArContentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArContent.GetArContentListReply} returns this
 */
proto.ArContent.GetArContentListReply.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetCategoryListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetCategoryListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetCategoryListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetCategoryListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetCategoryListRequest}
 */
proto.ArContent.GetCategoryListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetCategoryListRequest;
  return proto.ArContent.GetCategoryListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetCategoryListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetCategoryListRequest}
 */
proto.ArContent.GetCategoryListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetCategoryListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetCategoryListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetCategoryListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetCategoryListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArContent.GetCategoryListReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetCategoryListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetCategoryListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetCategoryListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetCategoryListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryEnumList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetCategoryListReply}
 */
proto.ArContent.GetCategoryListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetCategoryListReply;
  return proto.ArContent.GetCategoryListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetCategoryListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetCategoryListReply}
 */
proto.ArContent.GetCategoryListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.ArContent.ArContentCategoryEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryEnum(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetCategoryListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetCategoryListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetCategoryListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetCategoryListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryEnumList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated ArContentCategoryEnum category_enum = 1;
 * @return {!Array<!proto.ArContent.ArContentCategoryEnum>}
 */
proto.ArContent.GetCategoryListReply.prototype.getCategoryEnumList = function() {
  return /** @type {!Array<!proto.ArContent.ArContentCategoryEnum>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.ArContent.ArContentCategoryEnum>} value
 * @return {!proto.ArContent.GetCategoryListReply} returns this
 */
proto.ArContent.GetCategoryListReply.prototype.setCategoryEnumList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.ArContent.ArContentCategoryEnum} value
 * @param {number=} opt_index
 * @return {!proto.ArContent.GetCategoryListReply} returns this
 */
proto.ArContent.GetCategoryListReply.prototype.addCategoryEnum = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArContent.GetCategoryListReply} returns this
 */
proto.ArContent.GetCategoryListReply.prototype.clearCategoryEnumList = function() {
  return this.setCategoryEnumList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetTypeListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetTypeListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetTypeListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTypeListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryEnum: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetTypeListRequest}
 */
proto.ArContent.GetTypeListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetTypeListRequest;
  return proto.ArContent.GetTypeListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetTypeListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetTypeListRequest}
 */
proto.ArContent.GetTypeListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ArContent.ArContentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryEnum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetTypeListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetTypeListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetTypeListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTypeListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ArContentCategoryEnum category_enum = 1;
 * @return {!proto.ArContent.ArContentCategoryEnum}
 */
proto.ArContent.GetTypeListRequest.prototype.getCategoryEnum = function() {
  return /** @type {!proto.ArContent.ArContentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ArContent.ArContentCategoryEnum} value
 * @return {!proto.ArContent.GetTypeListRequest} returns this
 */
proto.ArContent.GetTypeListRequest.prototype.setCategoryEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArContent.GetTypeListReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetTypeListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetTypeListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetTypeListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTypeListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeEnumList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetTypeListReply}
 */
proto.ArContent.GetTypeListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetTypeListReply;
  return proto.ArContent.GetTypeListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetTypeListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetTypeListReply}
 */
proto.ArContent.GetTypeListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.ArContent.ArContentTypeEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypeEnum(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetTypeListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetTypeListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetTypeListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTypeListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeEnumList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated ArContentTypeEnum type_enum = 1;
 * @return {!Array<!proto.ArContent.ArContentTypeEnum>}
 */
proto.ArContent.GetTypeListReply.prototype.getTypeEnumList = function() {
  return /** @type {!Array<!proto.ArContent.ArContentTypeEnum>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.ArContent.ArContentTypeEnum>} value
 * @return {!proto.ArContent.GetTypeListReply} returns this
 */
proto.ArContent.GetTypeListReply.prototype.setTypeEnumList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.ArContent.ArContentTypeEnum} value
 * @param {number=} opt_index
 * @return {!proto.ArContent.GetTypeListReply} returns this
 */
proto.ArContent.GetTypeListReply.prototype.addTypeEnum = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArContent.GetTypeListReply} returns this
 */
proto.ArContent.GetTypeListReply.prototype.clearTypeEnumList = function() {
  return this.setTypeEnumList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetTemplateListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetTemplateListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetTemplateListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTemplateListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryEnum: jspb.Message.getFieldWithDefault(msg, 1, 0),
    typeEnum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetTemplateListRequest}
 */
proto.ArContent.GetTemplateListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetTemplateListRequest;
  return proto.ArContent.GetTemplateListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetTemplateListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetTemplateListRequest}
 */
proto.ArContent.GetTemplateListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ArContent.ArContentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryEnum(value);
      break;
    case 2:
      var value = /** @type {!proto.ArContent.ArContentTypeEnum} */ (reader.readEnum());
      msg.setTypeEnum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetTemplateListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetTemplateListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetTemplateListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTemplateListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTypeEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ArContentCategoryEnum category_enum = 1;
 * @return {!proto.ArContent.ArContentCategoryEnum}
 */
proto.ArContent.GetTemplateListRequest.prototype.getCategoryEnum = function() {
  return /** @type {!proto.ArContent.ArContentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ArContent.ArContentCategoryEnum} value
 * @return {!proto.ArContent.GetTemplateListRequest} returns this
 */
proto.ArContent.GetTemplateListRequest.prototype.setCategoryEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ArContentTypeEnum type_enum = 2;
 * @return {!proto.ArContent.ArContentTypeEnum}
 */
proto.ArContent.GetTemplateListRequest.prototype.getTypeEnum = function() {
  return /** @type {!proto.ArContent.ArContentTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ArContent.ArContentTypeEnum} value
 * @return {!proto.ArContent.GetTemplateListRequest} returns this
 */
proto.ArContent.GetTemplateListRequest.prototype.setTypeEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArContent.GetTemplateListReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetTemplateListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetTemplateListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetTemplateListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTemplateListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateEnumList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetTemplateListReply}
 */
proto.ArContent.GetTemplateListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetTemplateListReply;
  return proto.ArContent.GetTemplateListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetTemplateListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetTemplateListReply}
 */
proto.ArContent.GetTemplateListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.ArContent.ArContentTemplateEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTemplateEnum(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetTemplateListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetTemplateListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetTemplateListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetTemplateListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateEnumList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated ArContentTemplateEnum template_enum = 1;
 * @return {!Array<!proto.ArContent.ArContentTemplateEnum>}
 */
proto.ArContent.GetTemplateListReply.prototype.getTemplateEnumList = function() {
  return /** @type {!Array<!proto.ArContent.ArContentTemplateEnum>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.ArContent.ArContentTemplateEnum>} value
 * @return {!proto.ArContent.GetTemplateListReply} returns this
 */
proto.ArContent.GetTemplateListReply.prototype.setTemplateEnumList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.ArContent.ArContentTemplateEnum} value
 * @param {number=} opt_index
 * @return {!proto.ArContent.GetTemplateListReply} returns this
 */
proto.ArContent.GetTemplateListReply.prototype.addTemplateEnum = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArContent.GetTemplateListReply} returns this
 */
proto.ArContent.GetTemplateListReply.prototype.clearTemplateEnumList = function() {
  return this.setTemplateEnumList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.CreateArContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.CreateArContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.CreateArContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.CreateArContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uploadThumbnailName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryEnum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    typeEnum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    templateEnum: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.CreateArContentRequest}
 */
proto.ArContent.CreateArContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.CreateArContentRequest;
  return proto.ArContent.CreateArContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.CreateArContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.CreateArContentRequest}
 */
proto.ArContent.CreateArContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadThumbnailName(value);
      break;
    case 4:
      var value = /** @type {!proto.ArContent.ArContentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryEnum(value);
      break;
    case 5:
      var value = /** @type {!proto.ArContent.ArContentTypeEnum} */ (reader.readEnum());
      msg.setTypeEnum(value);
      break;
    case 6:
      var value = /** @type {!proto.ArContent.ArContentTemplateEnum} */ (reader.readEnum());
      msg.setTemplateEnum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.CreateArContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.CreateArContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.CreateArContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.CreateArContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUploadThumbnailName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTypeEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTemplateEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ArContent.CreateArContentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.CreateArContentRequest} returns this
 */
proto.ArContent.CreateArContentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tag = 2;
 * @return {string}
 */
proto.ArContent.CreateArContentRequest.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.CreateArContentRequest} returns this
 */
proto.ArContent.CreateArContentRequest.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string upload_thumbnail_name = 3;
 * @return {string}
 */
proto.ArContent.CreateArContentRequest.prototype.getUploadThumbnailName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.CreateArContentRequest} returns this
 */
proto.ArContent.CreateArContentRequest.prototype.setUploadThumbnailName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ArContentCategoryEnum category_enum = 4;
 * @return {!proto.ArContent.ArContentCategoryEnum}
 */
proto.ArContent.CreateArContentRequest.prototype.getCategoryEnum = function() {
  return /** @type {!proto.ArContent.ArContentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ArContent.ArContentCategoryEnum} value
 * @return {!proto.ArContent.CreateArContentRequest} returns this
 */
proto.ArContent.CreateArContentRequest.prototype.setCategoryEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ArContentTypeEnum type_enum = 5;
 * @return {!proto.ArContent.ArContentTypeEnum}
 */
proto.ArContent.CreateArContentRequest.prototype.getTypeEnum = function() {
  return /** @type {!proto.ArContent.ArContentTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ArContent.ArContentTypeEnum} value
 * @return {!proto.ArContent.CreateArContentRequest} returns this
 */
proto.ArContent.CreateArContentRequest.prototype.setTypeEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ArContentTemplateEnum template_enum = 6;
 * @return {!proto.ArContent.ArContentTemplateEnum}
 */
proto.ArContent.CreateArContentRequest.prototype.getTemplateEnum = function() {
  return /** @type {!proto.ArContent.ArContentTemplateEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.ArContent.ArContentTemplateEnum} value
 * @return {!proto.ArContent.CreateArContentRequest} returns this
 */
proto.ArContent.CreateArContentRequest.prototype.setTemplateEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.CreateArContentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.CreateArContentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.CreateArContentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.CreateArContentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.CreateArContentReply}
 */
proto.ArContent.CreateArContentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.CreateArContentReply;
  return proto.ArContent.CreateArContentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.CreateArContentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.CreateArContentReply}
 */
proto.ArContent.CreateArContentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.CreateArContentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.CreateArContentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.CreateArContentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.CreateArContentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.CreateArContentReply.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.CreateArContentReply} returns this
*/
proto.ArContent.CreateArContentReply.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.CreateArContentReply} returns this
 */
proto.ArContent.CreateArContentReply.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.CreateArContentReply.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentThreeDModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentThreeDModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentThreeDModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentThreeDModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uploadedThreeDModelFilename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentThreeDModelRequest}
 */
proto.ArContent.UpdateArContentThreeDModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentThreeDModelRequest;
  return proto.ArContent.UpdateArContentThreeDModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentThreeDModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentThreeDModelRequest}
 */
proto.ArContent.UpdateArContentThreeDModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadedThreeDModelFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentThreeDModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentThreeDModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentThreeDModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentThreeDModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploadedThreeDModelFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.UpdateArContentThreeDModelRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentThreeDModelRequest} returns this
 */
proto.ArContent.UpdateArContentThreeDModelRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uploaded_three_d_model_filename = 2;
 * @return {string}
 */
proto.ArContent.UpdateArContentThreeDModelRequest.prototype.getUploadedThreeDModelFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentThreeDModelRequest} returns this
 */
proto.ArContent.UpdateArContentThreeDModelRequest.prototype.setUploadedThreeDModelFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateData: msg.getTemplateData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentTemplateRequest}
 */
proto.ArContent.UpdateArContentTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentTemplateRequest;
  return proto.ArContent.UpdateArContentTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentTemplateRequest}
 */
proto.ArContent.UpdateArContentTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTemplateData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentTemplateRequest} returns this
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes template_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.getTemplateData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes template_data = 2;
 * This is a type-conversion wrapper around `getTemplateData()`
 * @return {string}
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.getTemplateData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTemplateData()));
};


/**
 * optional bytes template_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTemplateData()`
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.getTemplateData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTemplateData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.ArContent.UpdateArContentTemplateRequest} returns this
 */
proto.ArContent.UpdateArContentTemplateRequest.prototype.setTemplateData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentTemplateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentTemplateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentTemplateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentTemplateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentTemplateReply}
 */
proto.ArContent.UpdateArContentTemplateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentTemplateReply;
  return proto.ArContent.UpdateArContentTemplateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentTemplateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentTemplateReply}
 */
proto.ArContent.UpdateArContentTemplateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentTemplateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentTemplateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentTemplateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentTemplateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.UpdateArContentTemplateReply.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.UpdateArContentTemplateReply} returns this
*/
proto.ArContent.UpdateArContentTemplateReply.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.UpdateArContentTemplateReply} returns this
 */
proto.ArContent.UpdateArContentTemplateReply.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.UpdateArContentTemplateReply.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    viewerSetting: (f = msg.getViewerSetting()) && proto.ArContent.ArViewerSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentViewerRequest}
 */
proto.ArContent.UpdateArContentViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentViewerRequest;
  return proto.ArContent.UpdateArContentViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentViewerRequest}
 */
proto.ArContent.UpdateArContentViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    case 2:
      var value = new proto.ArContent.ArViewerSetting;
      reader.readMessage(value,proto.ArContent.ArViewerSetting.deserializeBinaryFromReader);
      msg.setViewerSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getViewerSetting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ArContent.ArViewerSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentViewerRequest} returns this
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ArViewerSetting viewer_setting = 2;
 * @return {?proto.ArContent.ArViewerSetting}
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.getViewerSetting = function() {
  return /** @type{?proto.ArContent.ArViewerSetting} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArViewerSetting, 2));
};


/**
 * @param {?proto.ArContent.ArViewerSetting|undefined} value
 * @return {!proto.ArContent.UpdateArContentViewerRequest} returns this
*/
proto.ArContent.UpdateArContentViewerRequest.prototype.setViewerSetting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.UpdateArContentViewerRequest} returns this
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.clearViewerSetting = function() {
  return this.setViewerSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.UpdateArContentViewerRequest.prototype.hasViewerSetting = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentViewerReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentViewerReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentViewerReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentViewerReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentViewerReplay}
 */
proto.ArContent.UpdateArContentViewerReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentViewerReplay;
  return proto.ArContent.UpdateArContentViewerReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentViewerReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentViewerReplay}
 */
proto.ArContent.UpdateArContentViewerReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentViewerReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentViewerReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentViewerReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentViewerReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.UpdateArContentViewerReplay.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.UpdateArContentViewerReplay} returns this
*/
proto.ArContent.UpdateArContentViewerReplay.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.UpdateArContentViewerReplay} returns this
 */
proto.ArContent.UpdateArContentViewerReplay.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.UpdateArContentViewerReplay.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetArContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetArContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetArContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetArContentRequest}
 */
proto.ArContent.GetArContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetArContentRequest;
  return proto.ArContent.GetArContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetArContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetArContentRequest}
 */
proto.ArContent.GetArContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetArContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetArContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetArContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.GetArContentRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.GetArContentRequest} returns this
 */
proto.ArContent.GetArContentRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetArContentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetArContentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetArContentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetArContentReply}
 */
proto.ArContent.GetArContentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetArContentReply;
  return proto.ArContent.GetArContentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetArContentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetArContentReply}
 */
proto.ArContent.GetArContentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetArContentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetArContentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetArContentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArContentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.GetArContentReply.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.GetArContentReply} returns this
*/
proto.ArContent.GetArContentReply.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.GetArContentReply} returns this
 */
proto.ArContent.GetArContentReply.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.GetArContentReply.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uploadThumbnailName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentRequest}
 */
proto.ArContent.UpdateArContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentRequest;
  return proto.ArContent.UpdateArContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentRequest}
 */
proto.ArContent.UpdateArContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadThumbnailName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.UpdateArContentRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentRequest} returns this
 */
proto.ArContent.UpdateArContentRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ArContent.UpdateArContentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentRequest} returns this
 */
proto.ArContent.UpdateArContentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tag = 3;
 * @return {string}
 */
proto.ArContent.UpdateArContentRequest.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentRequest} returns this
 */
proto.ArContent.UpdateArContentRequest.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string upload_thumbnail_name = 4;
 * @return {string}
 */
proto.ArContent.UpdateArContentRequest.prototype.getUploadThumbnailName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentRequest} returns this
 */
proto.ArContent.UpdateArContentRequest.prototype.setUploadThumbnailName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ArContent.UpdateArContentRequest} returns this
 */
proto.ArContent.UpdateArContentRequest.prototype.clearUploadThumbnailName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.UpdateArContentRequest.prototype.hasUploadThumbnailName = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentReply}
 */
proto.ArContent.UpdateArContentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentReply;
  return proto.ArContent.UpdateArContentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentReply}
 */
proto.ArContent.UpdateArContentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.UpdateArContentReply.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.UpdateArContentReply} returns this
*/
proto.ArContent.UpdateArContentReply.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.UpdateArContentReply} returns this
 */
proto.ArContent.UpdateArContentReply.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.UpdateArContentReply.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.UpdateArContentIsOnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.UpdateArContentIsOnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentIsOnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isOn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.UpdateArContentIsOnRequest}
 */
proto.ArContent.UpdateArContentIsOnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.UpdateArContentIsOnRequest;
  return proto.ArContent.UpdateArContentIsOnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.UpdateArContentIsOnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.UpdateArContentIsOnRequest}
 */
proto.ArContent.UpdateArContentIsOnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.UpdateArContentIsOnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.UpdateArContentIsOnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.UpdateArContentIsOnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.UpdateArContentIsOnRequest} returns this
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_on = 2;
 * @return {boolean}
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.getIsOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ArContent.UpdateArContentIsOnRequest} returns this
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.setIsOn = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ArContent.UpdateArContentIsOnRequest} returns this
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.clearIsOn = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.UpdateArContentIsOnRequest.prototype.hasIsOn = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.DeleteArContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.DeleteArContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.DeleteArContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.DeleteArContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.DeleteArContentRequest}
 */
proto.ArContent.DeleteArContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.DeleteArContentRequest;
  return proto.ArContent.DeleteArContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.DeleteArContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.DeleteArContentRequest}
 */
proto.ArContent.DeleteArContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.DeleteArContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.DeleteArContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.DeleteArContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.DeleteArContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.DeleteArContentRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.DeleteArContentRequest} returns this
 */
proto.ArContent.DeleteArContentRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.DuplicateArContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.DuplicateArContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.DuplicateArContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.DuplicateArContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.DuplicateArContentRequest}
 */
proto.ArContent.DuplicateArContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.DuplicateArContentRequest;
  return proto.ArContent.DuplicateArContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.DuplicateArContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.DuplicateArContentRequest}
 */
proto.ArContent.DuplicateArContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.DuplicateArContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.DuplicateArContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.DuplicateArContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.DuplicateArContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.DuplicateArContentRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.DuplicateArContentRequest} returns this
 */
proto.ArContent.DuplicateArContentRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.DuplicateArContentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.DuplicateArContentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.DuplicateArContentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.DuplicateArContentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.DuplicateArContentReply}
 */
proto.ArContent.DuplicateArContentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.DuplicateArContentReply;
  return proto.ArContent.DuplicateArContentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.DuplicateArContentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.DuplicateArContentReply}
 */
proto.ArContent.DuplicateArContentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.DuplicateArContentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.DuplicateArContentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.DuplicateArContentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.DuplicateArContentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.DuplicateArContentReply.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.DuplicateArContentReply} returns this
*/
proto.ArContent.DuplicateArContentReply.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.DuplicateArContentReply} returns this
 */
proto.ArContent.DuplicateArContentReply.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.DuplicateArContentReply.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.ArContentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.ArContentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.ArContentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.ArContentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isOn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    categoryEnum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    typeEnum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    templateEnum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    templateSetting: msg.getTemplateSetting_asB64(),
    viewerSetting: (f = msg.getViewerSetting()) && proto.ArContent.ArViewerSetting.toObject(includeInstance, f),
    thumbnailPath: jspb.Message.getFieldWithDefault(msg, 10, ""),
    thumbnailName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createTime: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updateTime: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.ArContentInfo}
 */
proto.ArContent.ArContentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.ArContentInfo;
  return proto.ArContent.ArContentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.ArContentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.ArContentInfo}
 */
proto.ArContent.ArContentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOn(value);
      break;
    case 5:
      var value = /** @type {!proto.ArContent.ArContentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryEnum(value);
      break;
    case 6:
      var value = /** @type {!proto.ArContent.ArContentTypeEnum} */ (reader.readEnum());
      msg.setTypeEnum(value);
      break;
    case 7:
      var value = /** @type {!proto.ArContent.ArContentTemplateEnum} */ (reader.readEnum());
      msg.setTemplateEnum(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTemplateSetting(value);
      break;
    case 9:
      var value = new proto.ArContent.ArViewerSetting;
      reader.readMessage(value,proto.ArContent.ArViewerSetting.deserializeBinaryFromReader);
      msg.setViewerSetting(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailPath(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateTime(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.ArContentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.ArContentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.ArContentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.ArContentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCategoryEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTypeEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTemplateEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getTemplateSetting_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getViewerSetting();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.ArContent.ArViewerSetting.serializeBinaryToWriter
    );
  }
  f = message.getThumbnailPath();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getThumbnailName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreateTime();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdateTime();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tag = 3;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_on = 4;
 * @return {boolean}
 */
proto.ArContent.ArContentInfo.prototype.getIsOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setIsOn = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.clearIsOn = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.ArContentInfo.prototype.hasIsOn = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ArContentCategoryEnum category_enum = 5;
 * @return {!proto.ArContent.ArContentCategoryEnum}
 */
proto.ArContent.ArContentInfo.prototype.getCategoryEnum = function() {
  return /** @type {!proto.ArContent.ArContentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ArContent.ArContentCategoryEnum} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setCategoryEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional ArContentTypeEnum type_enum = 6;
 * @return {!proto.ArContent.ArContentTypeEnum}
 */
proto.ArContent.ArContentInfo.prototype.getTypeEnum = function() {
  return /** @type {!proto.ArContent.ArContentTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.ArContent.ArContentTypeEnum} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setTypeEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ArContentTemplateEnum template_enum = 7;
 * @return {!proto.ArContent.ArContentTemplateEnum}
 */
proto.ArContent.ArContentInfo.prototype.getTemplateEnum = function() {
  return /** @type {!proto.ArContent.ArContentTemplateEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.ArContent.ArContentTemplateEnum} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setTemplateEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bytes template_setting = 8;
 * @return {!(string|Uint8Array)}
 */
proto.ArContent.ArContentInfo.prototype.getTemplateSetting = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes template_setting = 8;
 * This is a type-conversion wrapper around `getTemplateSetting()`
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getTemplateSetting_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTemplateSetting()));
};


/**
 * optional bytes template_setting = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTemplateSetting()`
 * @return {!Uint8Array}
 */
proto.ArContent.ArContentInfo.prototype.getTemplateSetting_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTemplateSetting()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setTemplateSetting = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional ArViewerSetting viewer_setting = 9;
 * @return {?proto.ArContent.ArViewerSetting}
 */
proto.ArContent.ArContentInfo.prototype.getViewerSetting = function() {
  return /** @type{?proto.ArContent.ArViewerSetting} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArViewerSetting, 9));
};


/**
 * @param {?proto.ArContent.ArViewerSetting|undefined} value
 * @return {!proto.ArContent.ArContentInfo} returns this
*/
proto.ArContent.ArContentInfo.prototype.setViewerSetting = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.clearViewerSetting = function() {
  return this.setViewerSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.ArContentInfo.prototype.hasViewerSetting = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string thumbnail_path = 10;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getThumbnailPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setThumbnailPath = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string thumbnail_name = 11;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getThumbnailName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setThumbnailName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string create_time = 12;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getCreateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string update_time = 13;
 * @return {string}
 */
proto.ArContent.ArContentInfo.prototype.getUpdateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArContentInfo} returns this
 */
proto.ArContent.ArContentInfo.prototype.setUpdateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.ArViewerSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.ArViewerSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.ArViewerSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.ArViewerSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    logoImagePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    logoImageName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    borderColor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cameraButtonColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    allowCapture: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    uploadLogoImageName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    viewerRightButton: (f = msg.getViewerRightButton()) && proto.ArContent.LinkButton.toObject(includeInstance, f),
    viewerLeftButton: (f = msg.getViewerLeftButton()) && proto.ArContent.LinkButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.ArViewerSetting}
 */
proto.ArContent.ArViewerSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.ArViewerSetting;
  return proto.ArContent.ArViewerSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.ArViewerSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.ArViewerSetting}
 */
proto.ArContent.ArViewerSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoImagePath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoImageName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorderColor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraButtonColor(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCapture(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadLogoImageName(value);
      break;
    case 8:
      var value = new proto.ArContent.LinkButton;
      reader.readMessage(value,proto.ArContent.LinkButton.deserializeBinaryFromReader);
      msg.setViewerRightButton(value);
      break;
    case 9:
      var value = new proto.ArContent.LinkButton;
      reader.readMessage(value,proto.ArContent.LinkButton.deserializeBinaryFromReader);
      msg.setViewerLeftButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.ArViewerSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.ArViewerSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.ArViewerSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.ArViewerSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogoImagePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogoImageName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBorderColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCameraButtonColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getUploadLogoImageName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getViewerRightButton();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.ArContent.LinkButton.serializeBinaryToWriter
    );
  }
  f = message.getViewerLeftButton();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.ArContent.LinkButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional string logo_image_path = 1;
 * @return {string}
 */
proto.ArContent.ArViewerSetting.prototype.getLogoImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setLogoImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string logo_image_name = 2;
 * @return {string}
 */
proto.ArContent.ArViewerSetting.prototype.getLogoImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setLogoImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.ArContent.ArViewerSetting.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string border_color = 4;
 * @return {string}
 */
proto.ArContent.ArViewerSetting.prototype.getBorderColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setBorderColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string camera_button_color = 5;
 * @return {string}
 */
proto.ArContent.ArViewerSetting.prototype.getCameraButtonColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setCameraButtonColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool allow_capture = 6;
 * @return {boolean}
 */
proto.ArContent.ArViewerSetting.prototype.getAllowCapture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setAllowCapture = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.clearAllowCapture = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.ArViewerSetting.prototype.hasAllowCapture = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string upload_logo_image_name = 7;
 * @return {string}
 */
proto.ArContent.ArViewerSetting.prototype.getUploadLogoImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.setUploadLogoImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional LinkButton viewer_right_button = 8;
 * @return {?proto.ArContent.LinkButton}
 */
proto.ArContent.ArViewerSetting.prototype.getViewerRightButton = function() {
  return /** @type{?proto.ArContent.LinkButton} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.LinkButton, 8));
};


/**
 * @param {?proto.ArContent.LinkButton|undefined} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
*/
proto.ArContent.ArViewerSetting.prototype.setViewerRightButton = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.clearViewerRightButton = function() {
  return this.setViewerRightButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.ArViewerSetting.prototype.hasViewerRightButton = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional LinkButton viewer_left_button = 9;
 * @return {?proto.ArContent.LinkButton}
 */
proto.ArContent.ArViewerSetting.prototype.getViewerLeftButton = function() {
  return /** @type{?proto.ArContent.LinkButton} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.LinkButton, 9));
};


/**
 * @param {?proto.ArContent.LinkButton|undefined} value
 * @return {!proto.ArContent.ArViewerSetting} returns this
*/
proto.ArContent.ArViewerSetting.prototype.setViewerLeftButton = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.ArViewerSetting} returns this
 */
proto.ArContent.ArViewerSetting.prototype.clearViewerLeftButton = function() {
  return this.setViewerLeftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.ArViewerSetting.prototype.hasViewerLeftButton = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.LinkButton.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.LinkButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.LinkButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.LinkButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    btnText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    btnColor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    textColor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.LinkButton}
 */
proto.ArContent.LinkButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.LinkButton;
  return proto.ArContent.LinkButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.LinkButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.LinkButton}
 */
proto.ArContent.LinkButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBtnText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBtnColor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextColor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.LinkButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.LinkButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.LinkButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.LinkButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBtnText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBtnColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTextColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool enable = 1;
 * @return {boolean}
 */
proto.ArContent.LinkButton.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ArContent.LinkButton} returns this
 */
proto.ArContent.LinkButton.prototype.setEnable = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ArContent.LinkButton} returns this
 */
proto.ArContent.LinkButton.prototype.clearEnable = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.LinkButton.prototype.hasEnable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string btn_text = 2;
 * @return {string}
 */
proto.ArContent.LinkButton.prototype.getBtnText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.LinkButton} returns this
 */
proto.ArContent.LinkButton.prototype.setBtnText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string btn_color = 3;
 * @return {string}
 */
proto.ArContent.LinkButton.prototype.getBtnColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.LinkButton} returns this
 */
proto.ArContent.LinkButton.prototype.setBtnColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text_color = 4;
 * @return {string}
 */
proto.ArContent.LinkButton.prototype.getTextColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.LinkButton} returns this
 */
proto.ArContent.LinkButton.prototype.setTextColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.ArContent.LinkButton.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.LinkButton} returns this
 */
proto.ArContent.LinkButton.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetArLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetArLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetArLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetArLinkRequest}
 */
proto.ArContent.GetArLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetArLinkRequest;
  return proto.ArContent.GetArLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetArLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetArLinkRequest}
 */
proto.ArContent.GetArLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetArLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetArLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetArLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ar_content_id = 1;
 * @return {string}
 */
proto.ArContent.GetArLinkRequest.prototype.getArContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.GetArLinkRequest} returns this
 */
proto.ArContent.GetArLinkRequest.prototype.setArContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetArLinkReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetArLinkReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetArLinkReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArLinkReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentViewerPath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetArLinkReply}
 */
proto.ArContent.GetArLinkReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetArLinkReply;
  return proto.ArContent.GetArLinkReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetArLinkReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetArLinkReply}
 */
proto.ArContent.GetArLinkReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentViewerPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetArLinkReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetArLinkReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetArLinkReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetArLinkReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentViewerPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ar_content_viewer_path = 1;
 * @return {string}
 */
proto.ArContent.GetArLinkReply.prototype.getArContentViewerPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.GetArLinkReply} returns this
 */
proto.ArContent.GetArLinkReply.prototype.setArContentViewerPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetViewerDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetViewerDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetViewerDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetViewerDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    arContentViewerUrlId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetViewerDataRequest}
 */
proto.ArContent.GetViewerDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetViewerDataRequest;
  return proto.ArContent.GetViewerDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetViewerDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetViewerDataRequest}
 */
proto.ArContent.GetViewerDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArContentViewerUrlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetViewerDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetViewerDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetViewerDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetViewerDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArContentViewerUrlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ar_content_viewer_url_id = 1;
 * @return {string}
 */
proto.ArContent.GetViewerDataRequest.prototype.getArContentViewerUrlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.GetViewerDataRequest} returns this
 */
proto.ArContent.GetViewerDataRequest.prototype.setArContentViewerUrlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContent.GetViewerDataReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContent.GetViewerDataReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContent.GetViewerDataReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetViewerDataReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.ArContent.ArContentInfo.toObject(includeInstance, f),
    adminId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContent.GetViewerDataReply}
 */
proto.ArContent.GetViewerDataReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContent.GetViewerDataReply;
  return proto.ArContent.GetViewerDataReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContent.GetViewerDataReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContent.GetViewerDataReply}
 */
proto.ArContent.GetViewerDataReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContent.ArContentInfo;
      reader.readMessage(value,proto.ArContent.ArContentInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContent.GetViewerDataReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContent.GetViewerDataReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContent.GetViewerDataReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContent.GetViewerDataReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContent.ArContentInfo.serializeBinaryToWriter
    );
  }
  f = message.getAdminId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ArContentInfo data = 1;
 * @return {?proto.ArContent.ArContentInfo}
 */
proto.ArContent.GetViewerDataReply.prototype.getData = function() {
  return /** @type{?proto.ArContent.ArContentInfo} */ (
    jspb.Message.getWrapperField(this, proto.ArContent.ArContentInfo, 1));
};


/**
 * @param {?proto.ArContent.ArContentInfo|undefined} value
 * @return {!proto.ArContent.GetViewerDataReply} returns this
*/
proto.ArContent.GetViewerDataReply.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContent.GetViewerDataReply} returns this
 */
proto.ArContent.GetViewerDataReply.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContent.GetViewerDataReply.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string admin_id = 2;
 * @return {string}
 */
proto.ArContent.GetViewerDataReply.prototype.getAdminId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContent.GetViewerDataReply} returns this
 */
proto.ArContent.GetViewerDataReply.prototype.setAdminId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.ArContent.ArContentCollet = {
  CATEGORY: 0,
  TYPE: 1,
  TEMPLATE: 2
};

/**
 * @enum {number}
 */
proto.ArContent.ArContentCategoryEnum = {
  CATRGORY_ALL: 0,
  CATRGORY_HEAD: 1,
  CATRGORY_FACE: 2
};

/**
 * @enum {number}
 */
proto.ArContent.ArContentTypeEnum = {
  TYPE_ALL: 0,
  TYPE_EAR: 1,
  TYPE_HAIR: 2,
  TYPE_GLASSES: 3,
  TYPE_LENS: 4
};

/**
 * @enum {number}
 */
proto.ArContent.ArContentTemplateEnum = {
  TEMPLATE_ALL: 0,
  TEMPLATE_EARRING: 1,
  TEMPLATE_GLASSES: 2,
  TEMPLATE_CONTECT_LENSES: 3,
  TEMPLATE_HAIR: 4
};

/**
 * @enum {number}
 */
proto.ArContent.ArContentImageType = {
  TEMP: 0,
  THUMBNAIL: 1,
  TEMPLATE_IMAGE: 2,
  VIEWER_IMAGE: 3,
  STATIC_IMAGE: 4
};

goog.object.extend(exports, proto.ArContent);
