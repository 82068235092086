// source: Program.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var Common_pb = require('./Common_pb.js');
goog.object.extend(proto, Common_pb);
var ArContent_pb = require('./ArContent_pb.js');
goog.object.extend(proto, ArContent_pb);
goog.exportSymbol('proto.Program.CreateProgramReply', null, global);
goog.exportSymbol('proto.Program.CreateProgramRequest', null, global);
goog.exportSymbol('proto.Program.GetFreeProgramReply', null, global);
goog.exportSymbol('proto.Program.GetFreeProgramRequest', null, global);
goog.exportSymbol('proto.Program.GetProgramListReply', null, global);
goog.exportSymbol('proto.Program.GetProgramListRequest', null, global);
goog.exportSymbol('proto.Program.ProgramModel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.CreateProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Program.CreateProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.CreateProgramRequest.displayName = 'proto.Program.CreateProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.CreateProgramReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Program.CreateProgramReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.CreateProgramReply.displayName = 'proto.Program.CreateProgramReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.GetProgramListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Program.GetProgramListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.GetProgramListRequest.displayName = 'proto.Program.GetProgramListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.GetProgramListReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Program.GetProgramListReply.repeatedFields_, null);
};
goog.inherits(proto.Program.GetProgramListReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.GetProgramListReply.displayName = 'proto.Program.GetProgramListReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.GetFreeProgramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Program.GetFreeProgramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.GetFreeProgramRequest.displayName = 'proto.Program.GetFreeProgramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.GetFreeProgramReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Program.GetFreeProgramReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.GetFreeProgramReply.displayName = 'proto.Program.GetFreeProgramReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Program.ProgramModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Program.ProgramModel.repeatedFields_, null);
};
goog.inherits(proto.Program.ProgramModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Program.ProgramModel.displayName = 'proto.Program.ProgramModel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.CreateProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.CreateProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.CreateProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.CreateProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: (f = msg.getModel()) && proto.Program.ProgramModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.CreateProgramRequest}
 */
proto.Program.CreateProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.CreateProgramRequest;
  return proto.Program.CreateProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.CreateProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.CreateProgramRequest}
 */
proto.Program.CreateProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Program.ProgramModel;
      reader.readMessage(value,proto.Program.ProgramModel.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.CreateProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.CreateProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.CreateProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.CreateProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Program.ProgramModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProgramModel model = 1;
 * @return {?proto.Program.ProgramModel}
 */
proto.Program.CreateProgramRequest.prototype.getModel = function() {
  return /** @type{?proto.Program.ProgramModel} */ (
    jspb.Message.getWrapperField(this, proto.Program.ProgramModel, 1));
};


/**
 * @param {?proto.Program.ProgramModel|undefined} value
 * @return {!proto.Program.CreateProgramRequest} returns this
*/
proto.Program.CreateProgramRequest.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Program.CreateProgramRequest} returns this
 */
proto.Program.CreateProgramRequest.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Program.CreateProgramRequest.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.CreateProgramReply.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.CreateProgramReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.CreateProgramReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.CreateProgramReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.CreateProgramReply}
 */
proto.Program.CreateProgramReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.CreateProgramReply;
  return proto.Program.CreateProgramReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.CreateProgramReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.CreateProgramReply}
 */
proto.Program.CreateProgramReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.CreateProgramReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.CreateProgramReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.CreateProgramReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.CreateProgramReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.Program.CreateProgramReply.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Program.CreateProgramReply} returns this
 */
proto.Program.CreateProgramReply.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.GetProgramListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.GetProgramListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.GetProgramListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetProgramListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.GetProgramListRequest}
 */
proto.Program.GetProgramListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.GetProgramListRequest;
  return proto.Program.GetProgramListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.GetProgramListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.GetProgramListRequest}
 */
proto.Program.GetProgramListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.GetProgramListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.GetProgramListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.GetProgramListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetProgramListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Program.GetProgramListReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.GetProgramListReply.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.GetProgramListReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.GetProgramListReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetProgramListReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.Program.ProgramModel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.GetProgramListReply}
 */
proto.Program.GetProgramListReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.GetProgramListReply;
  return proto.Program.GetProgramListReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.GetProgramListReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.GetProgramListReply}
 */
proto.Program.GetProgramListReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Program.ProgramModel;
      reader.readMessage(value,proto.Program.ProgramModel.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.GetProgramListReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.GetProgramListReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.GetProgramListReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetProgramListReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Program.ProgramModel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProgramModel models = 1;
 * @return {!Array<!proto.Program.ProgramModel>}
 */
proto.Program.GetProgramListReply.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.Program.ProgramModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Program.ProgramModel, 1));
};


/**
 * @param {!Array<!proto.Program.ProgramModel>} value
 * @return {!proto.Program.GetProgramListReply} returns this
*/
proto.Program.GetProgramListReply.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Program.ProgramModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel}
 */
proto.Program.GetProgramListReply.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Program.ProgramModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.GetProgramListReply} returns this
 */
proto.Program.GetProgramListReply.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.GetFreeProgramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.GetFreeProgramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.GetFreeProgramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetFreeProgramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.GetFreeProgramRequest}
 */
proto.Program.GetFreeProgramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.GetFreeProgramRequest;
  return proto.Program.GetFreeProgramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.GetFreeProgramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.GetFreeProgramRequest}
 */
proto.Program.GetFreeProgramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.GetFreeProgramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.GetFreeProgramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.GetFreeProgramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetFreeProgramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.GetFreeProgramReply.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.GetFreeProgramReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.GetFreeProgramReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetFreeProgramReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.GetFreeProgramReply}
 */
proto.Program.GetFreeProgramReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.GetFreeProgramReply;
  return proto.Program.GetFreeProgramReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.GetFreeProgramReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.GetFreeProgramReply}
 */
proto.Program.GetFreeProgramReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.GetFreeProgramReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.GetFreeProgramReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.GetFreeProgramReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.GetFreeProgramReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.Program.GetFreeProgramReply.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Program.GetFreeProgramReply} returns this
 */
proto.Program.GetFreeProgramReply.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Program.ProgramModel.repeatedFields_ = [5,6,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Program.ProgramModel.prototype.toObject = function(opt_includeInstance) {
  return proto.Program.ProgramModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Program.ProgramModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.ProgramModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seats: jspb.Message.getFieldWithDefault(msg, 4, 0),
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    typesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    templatesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    effectToolsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    arInteractModulesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    arEditWindowModulesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Program.ProgramModel}
 */
proto.Program.ProgramModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Program.ProgramModel;
  return proto.Program.ProgramModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Program.ProgramModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Program.ProgramModel}
 */
proto.Program.ProgramModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.Common.ProgramState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.ArContent.ArContentCategoryEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategories(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<!proto.ArContent.ArContentTypeEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<!proto.ArContent.ArContentTemplateEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTemplates(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<!proto.Common.EffectTool>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEffectTools(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<!proto.Common.ArInteractModule>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addArInteractModules(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<!proto.Common.ArEditWindowModule>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addArEditWindowModules(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Program.ProgramModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Program.ProgramModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Program.ProgramModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Program.ProgramModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getEffectToolsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getArInteractModulesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
  f = message.getArEditWindowModulesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.Program.ProgramModel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Program.ProgramModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Common.ProgramState state = 3;
 * @return {!proto.Common.ProgramState}
 */
proto.Program.ProgramModel.prototype.getState = function() {
  return /** @type {!proto.Common.ProgramState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Common.ProgramState} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 seats = 4;
 * @return {number}
 */
proto.Program.ProgramModel.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ArContent.ArContentCategoryEnum categories = 5;
 * @return {!Array<!proto.ArContent.ArContentCategoryEnum>}
 */
proto.Program.ProgramModel.prototype.getCategoriesList = function() {
  return /** @type {!Array<!proto.ArContent.ArContentCategoryEnum>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.ArContent.ArContentCategoryEnum>} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.ArContent.ArContentCategoryEnum} value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated ArContent.ArContentTypeEnum types = 6;
 * @return {!Array<!proto.ArContent.ArContentTypeEnum>}
 */
proto.Program.ProgramModel.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.ArContent.ArContentTypeEnum>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.ArContent.ArContentTypeEnum>} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.ArContent.ArContentTypeEnum} value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * repeated ArContent.ArContentTemplateEnum templates = 7;
 * @return {!Array<!proto.ArContent.ArContentTemplateEnum>}
 */
proto.Program.ProgramModel.prototype.getTemplatesList = function() {
  return /** @type {!Array<!proto.ArContent.ArContentTemplateEnum>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.ArContent.ArContentTemplateEnum>} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setTemplatesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.ArContent.ArContentTemplateEnum} value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.addTemplates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};


/**
 * repeated Common.EffectTool effect_tools = 8;
 * @return {!Array<!proto.Common.EffectTool>}
 */
proto.Program.ProgramModel.prototype.getEffectToolsList = function() {
  return /** @type {!Array<!proto.Common.EffectTool>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.Common.EffectTool>} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setEffectToolsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.Common.EffectTool} value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.addEffectTools = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.clearEffectToolsList = function() {
  return this.setEffectToolsList([]);
};


/**
 * repeated Common.ArInteractModule ar_interact_modules = 9;
 * @return {!Array<!proto.Common.ArInteractModule>}
 */
proto.Program.ProgramModel.prototype.getArInteractModulesList = function() {
  return /** @type {!Array<!proto.Common.ArInteractModule>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.Common.ArInteractModule>} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setArInteractModulesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.Common.ArInteractModule} value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.addArInteractModules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.clearArInteractModulesList = function() {
  return this.setArInteractModulesList([]);
};


/**
 * repeated Common.ArEditWindowModule ar_edit_window_modules = 10;
 * @return {!Array<!proto.Common.ArEditWindowModule>}
 */
proto.Program.ProgramModel.prototype.getArEditWindowModulesList = function() {
  return /** @type {!Array<!proto.Common.ArEditWindowModule>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.Common.ArEditWindowModule>} value
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.setArEditWindowModulesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.Common.ArEditWindowModule} value
 * @param {number=} opt_index
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.addArEditWindowModules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Program.ProgramModel} returns this
 */
proto.Program.ProgramModel.prototype.clearArEditWindowModulesList = function() {
  return this.setArEditWindowModulesList([]);
};


goog.object.extend(exports, proto.Program);
