import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { setup_camera, degrees_to_radians, init_onnx } from "../helper";
import { ISize } from "../interface/ISize";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { customBilateralFilterShader } from "@ar_template/component/helper/customBilateralFilterShader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { BloomPass } from "three/examples/jsm/postprocessing/BloomPass";
import { customBilateralFilterYoungerShader } from "@ar_template/component/helper/customBilateralFilterYoungerShader";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader";
import waterMark from "/images/MetARLogoTranslation.png";
import { bool } from "@techstark/opencv-js";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {faceMeshIndex} from "@ar_template/component/helper/faceMeshIndex";

export const Landmarks = {
  LANDMARK_INDEX_Nose: 527,
  LANDMARK_INDEX_RightEyeOutter: 989,
  LANDMARK_INDEX_LeftEyeOutter: 455,
  LANDMARK_INDEX_EyebrowCenter: 319,
  LANDMARK_INDEX_EyeCenter: 392,
  LANDMARK_INDEX_EarRightOutterBottom: 587,
  LANDMARK_INDEX_EarRightInnerBottom: 586
};

export abstract class BaseArView {
  protected canvasDomElement: HTMLDivElement;
  private videoCanvas: HTMLCanvasElement | null = null;
  protected ctx: CanvasRenderingContext2D | null = null;
  protected videoDomElement: HTMLVideoElement | null = null;

  protected scene: THREE.Scene;
  protected scene2: THREE.Scene;
  protected camera: THREE.OrthographicCamera;
  protected camera2: THREE.OrthographicCamera;
  protected controller: OrbitControls;
  public renderer: THREE.WebGLRenderer;
  public watermarkRenderer: THREE.WebGLRenderer;
  protected clock: THREE.Clock;

  protected imageLoader: THREE.TextureLoader;
  protected frameId: number | undefined;
  protected modelObject: THREE.Object3D | undefined;
  protected occlusionObject: THREE.Object3D | undefined;
  protected headObj: THREE.Object3D | undefined;
  // james update start here
  // protected geometry :  THREE.PlaneGeometry | undefined;
  // protected material : THREE.MeshBasicMaterial | undefined;
  // protected plane : THREE.Mesh | undefined;

  protected isArMode: boolean = false;
  private predictType: "landmark" | "seg";

  private composer: EffectComposer | undefined;
  private renderPass: RenderPass | undefined;
  private customBilateralFilterShader: ShaderPass | undefined;
  private isViewer: boolean = false;

  protected trackingCallback?: (isFind: boolean) => void;

  renderWaterMark: () => void;

  protected constructor(
    domElement: HTMLDivElement,
    canvasSize: ISize,
    predictType: "landmark" | "seg" = "landmark",
    trackingCallback?: (isFind: boolean) => void
  ) {
    this.canvasDomElement = domElement;

    //#region init threejs
    this.scene = new THREE.Scene();
    this.scene2 = new THREE.Scene();
    // this.camera = new THREE.PerspectiveCamera(45, (canvasSize.width/canvasSize.height), 0.1, 3000);
    this.camera = new THREE.OrthographicCamera(
      500 / -2,
      500 / 2,
      500 / 2,
      500 / -2,
      0.1,
      20000
    );

    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this.watermarkRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(500, 500);
    this.renderer.domElement.style.width = "100%";
    this.renderer.domElement.style.height = "100%";
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setClearColor(0xeeeeee, 0.0); // 設定背景色為黑色，透明度為 0
    this.renderer.setClearAlpha(0.0);
    // this.watermarkRenderer.setPixelRatio(window.devicePixelRatio);
    // this.watermarkRenderer.setSize(500, 500);
    // this.watermarkRenderer.domElement.style.width = "100%";
    // this.watermarkRenderer.domElement.style.height = "100%";
    // this.watermarkRenderer.outputEncoding = THREE.sRGBEncoding;
    // this.watermarkRenderer.setClearColor(0xeeeeee, 0.0); // 設定背景色為黑色，透明度為 0
    // this.watermarkRenderer.setClearAlpha(0.0);
    this.clock = new THREE.Clock();

    this.canvasDomElement.appendChild(this.renderer.domElement);
    this.camera.position.set(0, 0, -1000);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));

    this.controller = new OrbitControls(this.camera, this.canvasDomElement);
    this.controller.saveState();

    this.imageLoader = new THREE.TextureLoader();
    this.imageLoader.setCrossOrigin("anonymous");
    this.predictType = predictType;
    //#endregion
    this.camera2 = new THREE.OrthographicCamera(
      500 / -2,
      500 / 2,
      500 / 2,
      500 / -2,
      0.1,
      20000
    );
    this.camera2.position.set(0, 0, -1000);
    this.camera2.lookAt(new THREE.Vector3(0, 0, 0));

    var parameters = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      stencilBuffer: false,
    };
    var multiRenderTarget = new THREE.WebGLMultipleRenderTargets(500, 500, 2);

    var renderTarget = new THREE.WebGLRenderTarget(500, 500, parameters);
   
    // 美肌
    this.composer = new EffectComposer(this.renderer, renderTarget);

    this.renderPass = new RenderPass(this.scene, this.camera);

    this.customBilateralFilterShader = new ShaderPass(
      customBilateralFilterYoungerShader
    );
    this.customBilateralFilterShader.uniforms.bsigma.value = 0.05;
    this.customBilateralFilterShader.uniforms.smoothArea.value = 0;
    const copyShader = new ShaderPass(CopyShader);
    copyShader.renderToScreen = true;
    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.customBilateralFilterShader);
    this.composer.addPass(copyShader);

    //新增便是回傳
    this.trackingCallback = trackingCallback

    // 新增logo  Start//
    this.renderWaterMark = () => {
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("/images/MetARLogoTransparent.png");
      const geometry = new THREE.PlaneGeometry(100, 100);
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        opacity: 1,
        side: THREE.DoubleSide,
      });
      const plane = new THREE.Mesh(geometry, material);
      // console.log( canvasSize.width > window.innerWidth,((window.innerWidth/4) + 25), ((canvasSize.width/4) + 25));
      plane.position.set(
        canvasSize.width > window.innerWidth
          ? window.innerWidth / 8 + 40
          : canvasSize.width / 8 + 40,
        -canvasSize.height / 2 + 65,
        0
      );
      // plane.position.set(0, 0, 0);
      plane.rotation.setFromVector3(
        new THREE.Vector3(0, degrees_to_radians(180), 0)
      );
      plane.scale.set(1, 0.3, 1);
      // this.scene2.add(plane);
    };

    // End //
    this.Start = this.Start.bind(this);
    this.RenderScene = this.RenderScene.bind(this);
    this.Animate = this.Animate.bind(this);
    this.OpenArMode = this.OpenArMode.bind(this);
    this.GetFrameFromVideo = this.GetFrameFromVideo.bind(this);

    this.Start();
  }

  //#region abstract function
  public abstract LoadTemplate(): void;
  //#endregion

  //#region Scene control
  protected LoadScene(sceneUrl: string, callback: (obj: any) => void) {
    this.DownloadScene(sceneUrl, (result) => {
      if (!result) {
        callback(undefined);
      }
      const loader = new THREE.ObjectLoader();
      loader.parse<THREE.Scene>(result.scene, async (obj: any) => {
        if (obj.environment !== null) {
          this.scene.environment = obj.environment;
        }

        callback(obj);
      });
    });
  }

  private DownloadScene(url: string, callback: (obj: any | boolean) => void) {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        callback(data);
      })
      .catch((err) => {
        callback(false);
      });
  }

  protected Start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.Animate);
    }

  }

  protected Animate() {
    this.RenderScene();
    const mixerUpdateDelta = this.clock.getDelta();
    this.frameId = requestAnimationFrame(this.Animate);
  }

  protected RenderScene() {
    if(this.scene && this.camera && this.renderer) {
      
      this.renderer.setClearColor(0x000000, 0);
      this.renderer.autoClear = false;
      if(this.composer) {
          this.composer.render();
      }
      this.renderer.clearColor();
      this.renderer.clearDepth();
      this.camera.layers.set(2);
      this.renderer.render(this.scene, this.camera);
      if(this.composer) {
          //this.composer.render();
      }
      if(!this.isViewer){
        // this.renderer.clearColor();
        // this.renderer.clearDepth();
      }
      
      this.camera.layers.set(0);
      this.renderer.render(this.scene, this.camera);
  }
    // if (this.scene && this.camera && this.renderer ) {
    //   this.renderer.setClearColor(0x000000, 0);
    //   // this.watermarkRenderer.setClearColor(0x000000,0)
    //   this.renderer.autoClear = false;
    //   // this.watermarkRenderer.autoClear = false
    //   this.renderWaterMark();
    //   if (this.composer) {
    //     //this.composer.render();
    //   }
    //   this.renderer.clearColor();
    //   this.renderer.clearDepth();
    //   this.camera.layers.set(2);
    //   this.renderer.setRenderTarget(null);

    //   this.renderer.render(this.scene, this.camera);
    //   if (this.composer) {
    //     this.composer.render();
    //   }
    //   this.renderer.clearColor();
    //   this.renderer.clearDepth();
    //   this.camera.layers.set(0);
    //   this.renderer.setRenderTarget(null);
    //   this.renderer.autoClear = true;
  
    //   // this.renderer.render(this.scene, this.camera);
    // }
  }
  //#endregion

  //#region controller setting
  public SetEnablePan(isEnable: boolean) {
    this.controller.enablePan = isEnable;
  }

  public SetEnableRotate(isEnable: boolean) {
    this.controller.enableRotate = isEnable;
  }

  public SetEnableZoom(isEnable: boolean) {
    this.controller.enableZoom = isEnable;
  }

  public ResetPosition() {
    this.controller.reset();
  }
  //#endregion

  //#region AR controller
  public async OpenArMode(viewer: boolean = false) {
    if (this.isArMode) {
      return;
    }
    this.isViewer = viewer;
    this.videoDomElement = document.createElement("video");
    this.videoDomElement.autoplay = true;
    this.videoDomElement.playsInline = true;
    this.videoDomElement.muted = true;
    this.videoDomElement.style.width = "100%";
    this.videoDomElement.style.height = "100%";
    this.videoDomElement.style.position = "absolute";
    this.videoDomElement.style.top = "0";
    this.videoDomElement.style.transform = "rotateY(180deg)";
    this.videoDomElement.width = 512;
    this.videoDomElement.height = 512;
    if (!viewer) {
      this.canvasDomElement.style.position = "relative";
    }
    this.canvasDomElement.insertBefore(
      this.videoDomElement,
      this.renderer.domElement
    );
    this.renderer.domElement.style.position = "relative";

    this.videoCanvas = document.createElement("canvas");
    this.videoCanvas.width = 192;
    this.videoCanvas.height = 192;
    this.videoCanvas.style.width = "192";
    this.videoCanvas.style.height = "192";
    this.videoCanvas.style.position = "absolute";
    this.videoCanvas.style.transform = "scale(0.0001)";
    this.canvasDomElement.appendChild(this.videoCanvas);
    this.ctx = this.videoCanvas.getContext("2d");

    try {
      await setup_camera({ width: 500, height: 500 }, this.videoDomElement);
      if (viewer) {
        const videoTex = new THREE.VideoTexture(this.videoDomElement);
        videoTex.encoding = THREE.LinearEncoding;
        videoTex.minFilter = THREE.LinearFilter;
        const mesh = new THREE.Mesh(
          new THREE.PlaneGeometry(500, 500),
          new THREE.MeshBasicMaterial({
            color: 0xffffff,
            map: videoTex,
            side: THREE.DoubleSide,
            blending: THREE.AdditiveBlending,
          })
        );

        mesh.position.set(0, 0, 10000);
        mesh.scale.set(1, 1, 1);
        mesh.layers.set(2);
        mesh.layers.enable(2);
        this.scene.add(mesh);
        this.videoDomElement.style.transform = "scale(0.0001) rotateY(180deg)";
      }

      this.isArMode = true;
      this.controller.reset();
      await init_onnx(this.predictType);
      this.headObj!.visible = false;
      if (this.occlusionObject) {
        this.occlusionObject.visible = true;
      }
      this.GetFrameFromVideo();
      return true;
    } catch (e) {
      console.log("error open camera");
      return false;
    }
  }

  public CloseArMode() {
    if (!this.isArMode) {
      return;
    }

    const stream = this.videoDomElement!.srcObject!;
    if ("getTracks" in stream) {
      const tracks = stream.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });

      this.videoDomElement!.srcObject = null;
    }

    this.videoDomElement?.remove();
    this.videoCanvas?.remove();
    this.ctx = null;
    this.headObj!.visible = true;
    this.modelObject!.visible = true;
    this.modelObject?.position.set(0, 0, 0);
    this.controller.reset();
    if (this.occlusionObject) {
      this.occlusionObject.visible = false;
    }
    // this.modelObject!.scale.set(1, 1, 1);
    this.modelObject?.rotation.set(0, 0, 0);
    this.modelObject!.scale.setScalar(12);
    this.modelObject!.rotateY(degrees_to_radians(-40));
    this.modelObject!.rotateX(degrees_to_radians(4));
    this.modelObject!.rotateZ(0);
    this.isArMode = false;
  }

  protected async GetFrameFromVideo() {
    if (!this.ctx) {
      return;
    }

    this.ctx?.clearRect(0, 0, 192, 192);
    this.ctx?.save();
    this.ctx?.drawImage(this.videoDomElement!, 0, 0, 192, 192);
    this.ctx?.restore();

    if (this.isArMode) {
      requestAnimationFrame(this.GetFrameFromVideo);
    }
  }
  //#endregion

  //#region take photo
  public takePhoto(): string {
    this.RenderScene();
    return this.renderer.domElement.toDataURL();
  }
  //#endRegion

  //#region face mesh
  protected async loadObjFile(url: string) {
    let text = await this.loadFileAsText(url);
    let lines = (text as string).split('\n');
    let rtn: any[] = [];
    const KEY_V: string = "v  ";
    for (let idx: number = 0; idx < lines.length; idx++) {
      const str = lines[idx];
      if (str.indexOf(KEY_V) === 0) {
        const values = str.substring(KEY_V.length).split(' ');
        let x = values[0];
        let y = values[1];
        let z = values[2];
        rtn.push(x, y, z);
      }
    }
    return new Promise((resolve, reject) => {
      resolve(rtn);
    });
  }

  private loadFileAsText(url: string) {
    return new Promise<any>((resolve: any, reject: any) => {
      let loader = new THREE.FileLoader();
      loader.load(url, (text) => {
        resolve(text)
      })
    })
  }
  protected async loadFaceMesh() {
    const objLoader = new OBJLoader();
    const faceMeshObj = await objLoader.loadAsync("/static/ar_template/face.obj");
    const faceMesh = (faceMeshObj.children[0] as THREE.Mesh).clone();
    const rtn = await this.loadObjFile('/static/ar_template/face_v.obj');
    faceMesh.geometry.setIndex(faceMeshIndex);
    faceMesh.geometry.setAttribute("position", new THREE.Float32BufferAttribute(rtn as Array<number>, 3));
    faceMesh.geometry.computeVertexNormals();

    if (faceMesh.material instanceof Array<THREE.MeshPhongMaterial>) {
      const occ = new THREE.ShaderMaterial({
        vertexShader: THREE.ShaderLib.basic.vertexShader,
        fragmentShader: "precision lowp float;\n void main(void){\n gl_FragColor = vec4(1.,0.,0.,1.);\n }",
        uniforms: THREE.ShaderLib.basic.uniforms,
        side: THREE.DoubleSide,
        colorWrite: false
      })
      faceMesh.material = faceMesh.material.map(item => {
        if (item.name.indexOf("Eye") >= 0) {
          item.transparent = true;
          item.opacity = 0;
        } else if (item.name.indexOf("Mouth") >= 0) {
          item.transparent = true;
          item.opacity = 0;
        } else {
          item = occ;
          item.side = THREE.BackSide;
        }
        return item;
      })
    }

    return faceMesh;
  }
  //#endRegion
}
