import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import { IAction, ITransform } from "@/components/templateInterface";
import { EarringTemplatePosition, GlassesTemplatePosition } from "@/components/Helpers/SetTemplate";
import { SET_ARVIEW } from "./editLens";

interface ITemplateGlassesTransform {
    all: ITransform;
    front: ITransform;
    right: ITransform;
    left: ITransform;
  }
const defaultTransform: ITemplateGlassesTransform = {
    all: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    front: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    right: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    left: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
  };
interface IEditGlassesStatus {
  state: ITemplateGlassesTransform,
  token:string,
  isLoading:boolean,
  isSubmit: boolean,
  loadArView:boolean,
  imageAFileId:string,
  imageBFileId:string,
  imageCFileId:string,
  imageAFilePath:string,
  imageBFilePath:string,
  imageCFilePath:string,
  imageAUploadId:string,
  imageBUploadId:string,
  imageCUploadId:string,
  componentSize:GlassesTemplatePosition.ALL | GlassesTemplatePosition.LEFT | GlassesTemplatePosition.RIGHT | GlassesTemplatePosition.FRONT,
  contentInfo:undefined | ArContent_pb.ArContentInfo.AsObject,
  arSwitch:boolean,
  arLoading:boolean,
  arView:boolean,
}
export const EditGlassesStatus = createModel<RootModel>()({
    state: {
        state: defaultTransform,
        token:'',
        isLoading:true,
        isSubmit: false,
        loadArView:false,
        imageAFileId:'',
        imageBFileId:'',
        imageCFileId:'',
        imageAFilePath:'',
        imageBFilePath:'',
        imageCFilePath:'',
        imageAUploadId:'',
        imageBUploadId:'',
        imageCUploadId:'',
        componentSize:GlassesTemplatePosition.ALL,
        contentInfo:undefined,
        arSwitch:false,
        arLoading:true,
        arView:true,


    } as IEditGlassesStatus,
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        },
        transformReducer(state,action: IAction){
          const {type,payload} = action;
          switch (type) {
              case GlassesTemplatePosition.ALL:
              case GlassesTemplatePosition.LEFT:
              case GlassesTemplatePosition.RIGHT:
              case GlassesTemplatePosition.FRONT:
                  return {
                      ...state,
                      state:{
                          ...state.state,
                          [type]:payload
                      }
                     }
                  break
              default:
                return state;
          }
      },
   
    },
    effects: (dispatch) =>({
      clearUp () {
        dispatch.EditGlassesStatus.setState({data: true, type: SET_IS_LOADING});
        dispatch.EditGlassesStatus.setState({data: false, type: SET_IS_SUBMIT})
        dispatch.EditGlassesStatus.setState({data: true, type: SET_AR_LOADING});
        dispatch.EditGlassesStatus.setState({data: defaultTransform, type: SET_STATE});
        dispatch.EditGlassesStatus.setState({data:true, type: SET_ARVIEW})
        dispatch.EditGlassesStatus.setState({data:false, type: SET_AR_SWITCH})
        dispatch.EditGlassesStatus.setState({data:false, type: SET_LOAD_AR_VIEW})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_A_FILEPATH})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_B_FILEPATH})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_C_FILEPATH})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_A_FILE_ID})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_B_FILE_ID})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_C_FILE_ID})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_A_UPLOAD_ID})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_B_UPLOAD_ID})
        dispatch.EditGlassesStatus.setState({data:GlassesTemplatePosition.ALL, type:SET_COMPONENT_SIZE})
        dispatch.EditGlassesStatus.setState({data:'', type: SET_IMAGE_C_UPLOAD_ID})
      }
    })
});

export const SET_LEFT_STATE = "leftState";
export const SET_STATE = 'state'
export const SET_RIGHT_STATE = "rightState";
export const SET_TOKEN = 'token';
export const SET_IS_LOADING = 'isLoading'
export const SET_IS_SUBMIT = 'isSubmit'
export const SET_LOAD_AR_VIEW = 'loadArView';
export const SET_IMAGE_A_FILEPATH = 'imageAFilePath';
export const SET_IMAGE_B_FILEPATH = 'imageBFilePath';
export const SET_IMAGE_C_FILEPATH = 'imageCFilePath';
export const SET_IMAGE_A_FILE_ID = 'imageAFileId';
export const SET_IMAGE_B_FILE_ID = 'imageBFileId';
export const SET_IMAGE_C_FILE_ID = 'imageCFileId';
export const SET_IMAGE_A_UPLOAD_ID = 'imageAUploadId' ;
export const SET_IMAGE_B_UPLOAD_ID = 'imageBUploadId' ;
export const SET_IMAGE_C_UPLOAD_ID = 'imageCUploadId'
export const SET_COMPONENT_SIZE = 'componentSize';
export const SET_DIRECTION = 'direction';
export const SET_CONTENT_INFO = 'contentInfo';
export const SET_AR_SWITCH = 'arSwitch';
export const SET_AR_VIEW = 'arView';
export const SET_AR_LOADING = 'arLoading';


