import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";

//state用於管理所有狀態
//reducer處理怎麼改變那些狀態,可以寫各式各樣的api
//Effect 有點像useEffect,可以批輛處理多個reducers methods

const initalState =  {
    loginAccount: '',
    loginPassword: '',
    resetPop:false,
    isVerified:false,
    isRecaptchaRendered:false
}

export const LoginStatus = createModel<RootModel>()({
    state: initalState,
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        },
        setIsVerified(state, payload: boolean){
            return { ...state, isVerified: payload};
        },
        reset () {
            return initalState
        }
    },
    effects: (dispatch) =>({
        clearup(){
            dispatch.LoginStatus.reset()
        }
    })
});


//這邊的用法是用於怎麼去export 這些名字,因為可能會有很多命名是重複的,為了方便紀錄和使用
//但假如你的編輯器有支援引入,其實就不一定要用
export const SET_ACCOUNT = "loginAccount";
export const SET_PASSWORD = "loginPassword";
export const SET_RESETPOP = "resetPop";
export const SET_VERIFIED = "isVerified";
export const SET_RECAPTCHARENDERED = "isRecaptchaRendered";