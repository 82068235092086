import * as ArContent_pb from '@/proto/ArContent_pb';
import { ArContentServicesClient } from '@/proto/WebServicesServiceClientPb';
import { RpcError } from 'grpc-web';
import * as Common_pb from '@/proto/Common_pb';

const arContentClient = new ArContentServicesClient(`${process.env.REACT_APP_PUBLIC_API_HOST}`);

export async function getArContentListApi(token:string , pageinfo:Common_pb.PageInfoRequest | undefined,keyword:string):Promise<ArContent_pb.GetArContentListReply | RpcError>{
    const req = new ArContent_pb.GetArContentListRequest()
    if(pageinfo===undefined){
      pageinfo = new Common_pb.PageInfoRequest()
    }
    req.setPageinfo(pageinfo).setKeyword(keyword);
    try {
      return await arContentClient.getArContentList(req,{"authorization":token})
    }
    catch (err) {
      return err as RpcError;
    }
} 

export async function getCategoryList(token:string){
  const req = new ArContent_pb.GetCategoryListRequest()
  try {
    return await arContentClient.getCategoryList(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}

export async function getTypeListApi(token:string ,category:ArContent_pb.ArContentCategoryEnum):Promise<ArContent_pb.GetTypeListReply | RpcError>{
  const req = new ArContent_pb.GetTypeListRequest()
  req.setCategoryEnum(category);
  try {
    return await arContentClient.getTypeList(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
} 

export async function getTemplateListApi(token:string ,category:ArContent_pb.ArContentCategoryEnum , type:ArContent_pb.ArContentTypeEnum):Promise<ArContent_pb.GetTemplateListReply | RpcError>{
  const req = new ArContent_pb.GetTemplateListRequest()
  req.setCategoryEnum(category).setTypeEnum(type);
  try {
    return await arContentClient.getTemplateList(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
} 

export async function createArContentApi(token:string ,name: string , tag:string , uploadThumbnailName:string , categoryEnum:ArContent_pb.ArContentCategoryEnum,typeEnum: ArContent_pb.ArContentTypeEnum,templateEnum: ArContent_pb.ArContentTemplateEnum,):Promise<ArContent_pb.CreateArContentReply | RpcError>{
  const req = new ArContent_pb.CreateArContentRequest()
  req.setName(name).setTag(tag).setUploadThumbnailName(uploadThumbnailName).setCategoryEnum(categoryEnum).setTypeEnum(typeEnum).setTemplateEnum(templateEnum);
  try {
    return await arContentClient.createArContent(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}


export async function updateArContentTemplateApi(token:string ,arContentId: string , templateData:Uint8Array | string):Promise<ArContent_pb.UpdateArContentTemplateReply | RpcError>{
  const req = new ArContent_pb.UpdateArContentTemplateRequest()
  req.setArContentId(arContentId).setTemplateData(templateData);
  try {
    return await arContentClient.updateArContentTemplate(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}

export async function updateArContentViewerApi(token:string ,arContentId: string , templateData:ArContent_pb.ArViewerSetting | undefined):Promise<ArContent_pb.UpdateArContentViewerReplay | RpcError>{
  const req = new ArContent_pb.UpdateArContentViewerRequest()
  req.setArContentId(arContentId).setViewerSetting(templateData);
  try {
    return await arContentClient.updateArContentViewer(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}


export async function getArContentApi(token:string ,arContentId: string):Promise<ArContent_pb.GetArContentReply | RpcError>{
  const req = new ArContent_pb.GetArContentRequest()
  req.setArContentId(arContentId);
  try {
    return await arContentClient.getArContent(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}


export async function updateArContentApi(token:string ,arContentId: string,name: string,tag: string,uploadThumbnailName: string,):Promise<ArContent_pb.UpdateArContentReply | RpcError>{
  const req = new ArContent_pb.UpdateArContentRequest()
  req.setArContentId(arContentId).setName(name).setTag(tag).setUploadThumbnailName(uploadThumbnailName);
  try {
    return await arContentClient.updateArContent(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}


export async function updateArContentIsOnApi(token:string ,arContentId: string,isOn: boolean){
  const req = new ArContent_pb.UpdateArContentIsOnRequest()
  req.setArContentId(arContentId).setIsOn(isOn);
  try {
    return await arContentClient.updateArContentIsOn(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}


export async function deleteArContentApi(token:string ,arContentId: string):Promise<Common_pb.CommonReply |  RpcError>{
  const req = new ArContent_pb.DeleteArContentRequest()
  req.setArContentId(arContentId);
  try {
    return await arContentClient.deleteArContent(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}

export async function duplicateArContentApi(token:string ,arContentId: string):Promise<ArContent_pb.DuplicateArContentReply | RpcError>{
  const req = new ArContent_pb.DuplicateArContentRequest()
  req.setArContentId(arContentId);
  try {
    return await arContentClient.duplicateArContent(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}

export async function getArLinkApi(token:string ,arContentId: string):Promise<ArContent_pb.GetArLinkReply | RpcError>{
  const req = new ArContent_pb.GetArLinkRequest()
  req.setArContentId(arContentId);
  try {
    return await arContentClient.getArLink(req,{"authorization":token})
  }
  catch (err) {
    return err as RpcError;
  }
}


// export async function getviewerDataApi(token:string ,arContentviewerUrlId: string):Promise<ArContent_pb.GetviewerDataReply | RpcError>{
//   const req = new ArContent_pb.GetviewerDataRequest()
//   req.setArContentviewerUrlId(arContentviewerUrlId);
//   try {
//     return await arContentClient.getviewerData(req,{"authorization":token})
//   }
//   catch (err) {
//     return err as RpcError;
//   }
// }

export async function getViewerDataApi(arContentViewerUrlId: string):Promise<ArContent_pb.GetViewerDataReply | RpcError>{
  const req = new ArContent_pb.GetViewerDataRequest()
  req.setArContentViewerUrlId(arContentViewerUrlId);
  try {
    return await arContentClient.getViewerData(req,null)
  }
  catch (err) {
    return err as RpcError;
  }
}

