import { IColor, ISize } from "@ar_template/component/interface";
import { init_onnx, predict, setup_camera } from "@ar_template/component/helper";
import * as ort from "onnxruntime-web";

export class HairTemplate {
    public canvasDomElement: HTMLDivElement;
    public readonly rendererDom: HTMLCanvasElement;
    public readonly hairRendererDom: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;
    private hairCtx: CanvasRenderingContext2D;
    private headImage: HTMLImageElement | undefined;
    private hairImage: HTMLImageElement | undefined;
    private colorSet: IColor;
    private softness: number = 0;
    private feather: number = 0;
    private readonly callbackEvent: (state: string) => void;
    private isArMode: boolean = false;

    private videoCanvas: HTMLCanvasElement | null = null;
    protected videoCtx: CanvasRenderingContext2D | null = null;
    protected videoDomElement: HTMLVideoElement | null = null;
    public arCanvas: HTMLCanvasElement | null = null;
    private arCtx: CanvasRenderingContext2D | null = null;

    public constructor(domElement: HTMLDivElement, canvasSize: ISize, defaultColor?: IColor, callback: (state: string) => void = () => null) {
        this.canvasDomElement = domElement;
        this.canvasDomElement.style.position = "relative";

        this.hairRendererDom = document.createElement("canvas");
        this.hairRendererDom.style.width = canvasSize.width.toString();
        this.hairRendererDom.style.height = canvasSize.height.toString();
        this.hairRendererDom.width = canvasSize.width;
        this.hairRendererDom.height = canvasSize.height;

        this.rendererDom = document.createElement("canvas");
        this.rendererDom.style.width = "100%";
        this.rendererDom.style.height = "100%";
        this.rendererDom.style.position = "absolute";
        this.rendererDom.width = canvasSize.width;
        this.rendererDom.height = canvasSize.height;

        this.ctx = this.rendererDom.getContext("2d")!;
        this.hairCtx = this.hairRendererDom.getContext("2d")!;
        this.canvasDomElement.appendChild(this.rendererDom);

        this.colorSet = {r: 0, g: 0, b: 0};
        if (defaultColor) {
            this.colorSet = defaultColor;
        }
        this.callbackEvent = callback;

        this.DrawHeadImage();

        this.GetFrameFromVideo = this.GetFrameFromVideo.bind(this);
    }

    private DrawHeadImage() {

        if (this.headImage) {
            this.ctx.clearRect(0, 0, this.rendererDom.width, this.rendererDom.height);
            this.ctx.drawImage(this.headImage!, 0, 0, this.rendererDom.width, this.rendererDom.height);
            this.CrateHairImage()
        } else {
            this.headImage = new Image();
            this.headImage.onload = () => {
                this.ctx.drawImage(this.headImage!, 0, 0, this.rendererDom.width, this.rendererDom.height);
                // console.log('draw head image')
                this.CrateHairImage()
            }
            this.headImage.src = "/static/ar_template/template_hair_00.png";
        }
    }

    private CrateHairImage() {
        if (this.hairImage) {
            this.hairCtx.clearRect(0, 0, this.rendererDom.width, this.rendererDom.height);
            this.hairCtx.drawImage(this.hairImage!, 0, 0, this.rendererDom!.width, this.rendererDom!.height);
            let imgData = this.hairCtx.getImageData(0, 0, this.rendererDom!.width, this.rendererDom!.height);
            for (let i = 0; i < imgData.data.length; i += 4) {
                imgData.data[i] *= this.colorSet.r <= 0 ? 0 : this.colorSet.r / 255;
                imgData.data[i + 1] *= this.colorSet.g <= 0 ? 0 : this.colorSet.g / 255;
                imgData.data[i + 2] *= this.colorSet.b <= 0 ? 0 : this.colorSet.b / 255;
            }
            this.hairCtx.putImageData(imgData, 0, 0);
            this.ctx.drawImage(this.hairCtx.canvas, 0, 0);
            this.callbackEvent("done");
        } else {
            this.hairImage = new Image();
            this.hairImage.onload = () => {
                this.hairCtx.drawImage(this.hairImage!, 0, 0, this.rendererDom!.width, this.rendererDom!.height);
                let imgData = this.hairCtx.getImageData(0, 0, this.rendererDom!.width, this.rendererDom!.height);
                for (let i = 0; i < imgData.data.length; i += 4) {
                    imgData.data[i] *= this.colorSet.r <= 0 ? 0 : this.colorSet.r / 255;
                    imgData.data[i + 1] *= this.colorSet.g <= 0 ? 0 : this.colorSet.g / 255;
                    imgData.data[i + 2] *= this.colorSet.b <= 0 ? 0 : this.colorSet.b / 255;
                }
                this.hairCtx.putImageData(imgData, 0, 0);
                this.ctx.drawImage(this.hairCtx.canvas, 0, 0);
                this.callbackEvent("done");
            }
            this.hairImage.src = "/static/ar_template/template_hair_01.png";
        }
    }

    public ChangeColor(color: IColor) {
        this.colorSet = color;
        this.DrawHeadImage();
    }

    public SetSoftness(softness: number) {
        this.softness = softness;
    }

    public SetFeather(feather: number) {
        this.feather = feather
    }

    //#region AR function
    public async OpenArMode(viewer: boolean = false) {
        if (this.isArMode) {
            return;
        }
        this.rendererDom.style.display = "none";

        this.videoDomElement = document.createElement("video");
        this.videoDomElement.autoplay = true;
        this.videoDomElement.playsInline = true;
        this.videoDomElement.muted = true;
        this.videoDomElement.style.width = "100%";
        this.videoDomElement.style.height = "100%";
        this.videoDomElement.style.position = "absolute";
        this.videoDomElement.style.top = "0";
        this.videoDomElement.style.transform = "rotateY(180deg)"
        this.videoDomElement.style.transform = "scale(0.0001)";
        this.videoDomElement.width = 512;
        this.videoDomElement.height = 512;
        if (!viewer) {
            this.canvasDomElement.style.position = "relative";
        }

        this.canvasDomElement.insertBefore(this.videoDomElement, this.rendererDom);

        this.videoCanvas = document.createElement("canvas");
        this.videoCanvas.width = 192;
        this.videoCanvas.height = 192;
        this.videoCanvas.style.width = "192";
        this.videoCanvas.style.height = "192";
        this.videoCanvas.style.position = "absolute";
        this.videoCanvas.style.transform = "scale(0.0001)";
        this.canvasDomElement.appendChild(this.videoCanvas);
        this.videoCtx = this.videoCanvas.getContext("2d");

        this.arCanvas = document.createElement("canvas");
        this.arCanvas.id = "ar_canvas";
        this.arCanvas.style.width = "100%";
        this.arCanvas.style.height = "100%";
        this.arCanvas.style.position = "absolute";
        this.arCanvas.style.top = "0";
        // this.arCanvas.style.transform = "scaleX(-1)"
        this.arCanvas.style.transform =  "rotateY(180deg)"
        this.arCanvas.width = 192;
        this.arCanvas.height = 192;
        this.canvasDomElement.appendChild(this.arCanvas);
        this.arCtx = this.arCanvas.getContext("2d");

        try {
            await setup_camera({width: 512, height: 512}, this.videoDomElement);
            this.isArMode = true;
            await init_onnx("seg");
            requestAnimationFrame(this.GetFrameFromVideo);
            return true;
        } catch (e) {
            // console.log("error open camera");
            return false;
        }
    }

    public CloseArMode() {
        if (!this.isArMode) {
            return;
        }
        this.rendererDom.style.display = "";
        const stream = this.videoDomElement!.srcObject!;
        if ("getTracks" in stream) {
            const tracks = stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });

            this.videoDomElement!.srcObject = null;
        }

        this.videoDomElement?.remove();
        this.videoCanvas?.remove();
        this.arCanvas?.remove();
        this.videoCtx = null;
        this.arCtx = null;
        this.isArMode = false;
    }

    private async GetFrameFromVideo() {
        if (!this.videoCtx) {
            return;
        }

        try {
            this.videoCtx?.clearRect(0, 0, 192, 192);
            this.videoCtx?.save();
            this.videoCtx?.drawImage(this.videoDomElement!, 0, 0, 192, 192);
            this.videoCtx?.restore();

            this.arCtx?.clearRect(0, 0, this.arCanvas!.width, this.arCanvas!.height);
            this.arCtx?.save();
            this.arCtx?.drawImage(this.videoCanvas!, 0, 0);
            this.arCtx?.restore();

            if (this.isArMode) {
                const imageData = this.videoCtx?.getImageData(0, 0, 192, 192);
                this.arCtx?.putImageData(imageData, 0, 0);
                const colorData = new Array<number>();
                for (let i = 0; i < imageData.data.length; i += 4) {
                    colorData.push((imageData.data[i] - 127.5) / 127.5);
                    colorData.push((imageData.data[i + 1] - 127.5) / 127.5);
                    colorData.push((imageData.data[i + 2] - 127.5) / 127.5);
                }
                const result = await predict(new ort.Tensor("float32", colorData, [1, 192, 192, 3]));
                if (result) {
                    const masks: ort.Tensor = (result as ort.InferenceSession.OnnxValueMapType)["masks"];
                    const alpha: ort.Tensor = (result as ort.InferenceSession.OnnxValueMapType)["alpha"];
                    const {r, g, b} = this.colorSet;
                    let setImageData = this.arCtx!.getImageData(0,0, 192, 192);
                    let count = 0;
                    for (let i = 0; i < setImageData.data.length; i += 4) {
                        if(masks.data[count] >= 0.65){
                            setImageData.data[i] = r
                            setImageData.data[i + 1] = g
                            setImageData.data[i + 2] = b
                            setImageData.data[i + 3] = ((alpha.data[count] as number) * 255) *0.5

                            setImageData.data[i + 3] = 255
                        }
                        count++;
                    }
                    this.arCtx?.putImageData(setImageData, 0, 0);
                }


                requestAnimationFrame(this.GetFrameFromVideo);
            }
        } catch {

        }
    }

    //#endregion

    public takePhoto(): string {
        // const context = this.arCanvas!.getContext("2d");
        // context!.save();
        // context!.scale(-1, 1); // 將畫布左右翻轉
        // context!.drawImage(this.arCanvas!, 0, 0);
        // context!.restore();
        this.arCanvas!.style.transform = "scaleX(-1)";

        return this.arCanvas!.toDataURL("image/png");
    }
}