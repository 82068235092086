import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";


interface IProjectIndexModel {
  contentInfo: ArContent_pb.ArContentInfo.AsObject | undefined;
  isloading: boolean;
  isSubmit: boolean;
  token: string;
  categoryId: ArContent_pb.ArContentCategoryEnum | undefined;
  typeId: ArContent_pb.ArContentTypeEnum | undefined;
  templateId: ArContent_pb.ArContentTemplateEnum | undefined;
  imageFileId: string;
  imagePath: string;
  tags: string[];
  tagTipShow : boolean
}

export const ProjectIndexStatus = createModel<RootModel>()({
  state: {
    contentInfo: undefined,
    isloading: true,
    isSubmit: false,
    token: "",
    categoryId: undefined,
    typeId: undefined,
    templateId: undefined,
    imageFileId: "",
    imagePath: "",
    tags: [],
    tagTipShow : false
  } as IProjectIndexModel,
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
  },
  effects: (dispatch) => ({
    
    async load(callback:(value:string)=>void) {
     
    },
    clearup() {
      dispatch.ProjectIndexStatus.setState({data:true , type: SET_ISLOADING})
      dispatch.ProjectIndexStatus.setState({data:false , type: SET_IS_SUBMIT})
      dispatch.ProjectIndexStatus.setState({data:false, type:SET_TAG_TIP_SHOW})
    }
  }),
});

export const SET_TAG_TIP_SHOW = 'tagTipShow'
export const SET_ISLOADING = "isloading";
export const SET_IS_SUBMIT = "isSubmit";
export const SET_TOKEN = "token";
export const SET_CATEGORY_ID = "categoryId";
export const SET_TYPE_ID = "typeId";
export const SET_TEMPLATE_ID = "templateId";
export const SET_IMAGE_FILE_ID = "imageFileId";
export const SET_TAGS = "tags";
export const SET_IMAGE_PATH = "imagePath";
export const SET_CONTENT_INFO = 'contentInfo';
