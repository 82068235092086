import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";

export const ConfirmRegisterStatus = createModel<RootModel>()({
    state: {
        emailValidated:false,
    },
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        }
    },
    effects: (dispatch) =>({
        clearup(){
            dispatch.ConfirmRegisterStatus.setState({data:false,type:SET_EMAIL_VALIDATED})
            
        }
    })
});

export const SET_EMAIL_VALIDATED = "emailValidated";