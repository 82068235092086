// source: ArContentTemplate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var Common_pb = require('./Common_pb.js');
goog.object.extend(proto, Common_pb);
goog.exportSymbol('proto.ArContentTemplate.EarringObjectLocationEnum', null, global);
goog.exportSymbol('proto.ArContentTemplate.EarringSetting', null, global);
goog.exportSymbol('proto.ArContentTemplate.EarringSettingSide', null, global);
goog.exportSymbol('proto.ArContentTemplate.GlassesObjectLocationEnum', null, global);
goog.exportSymbol('proto.ArContentTemplate.GlassesSetting', null, global);
goog.exportSymbol('proto.ArContentTemplate.ObjectSetting', null, global);
goog.exportSymbol('proto.ArContentTemplate.TemplateContactLenses', null, global);
goog.exportSymbol('proto.ArContentTemplate.TemplateEarring', null, global);
goog.exportSymbol('proto.ArContentTemplate.TemplateGlasses', null, global);
goog.exportSymbol('proto.ArContentTemplate.TemplateHair', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.ObjectSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContentTemplate.ObjectSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.ObjectSetting.displayName = 'proto.ArContentTemplate.ObjectSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.TemplateEarring = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContentTemplate.TemplateEarring, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.TemplateEarring.displayName = 'proto.ArContentTemplate.TemplateEarring';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.EarringSettingSide = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArContentTemplate.EarringSettingSide.repeatedFields_, null);
};
goog.inherits(proto.ArContentTemplate.EarringSettingSide, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.EarringSettingSide.displayName = 'proto.ArContentTemplate.EarringSettingSide';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.EarringSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContentTemplate.EarringSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.EarringSetting.displayName = 'proto.ArContentTemplate.EarringSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.TemplateGlasses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArContentTemplate.TemplateGlasses.repeatedFields_, null);
};
goog.inherits(proto.ArContentTemplate.TemplateGlasses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.TemplateGlasses.displayName = 'proto.ArContentTemplate.TemplateGlasses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.GlassesSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContentTemplate.GlassesSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.GlassesSetting.displayName = 'proto.ArContentTemplate.GlassesSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.TemplateContactLenses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContentTemplate.TemplateContactLenses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.TemplateContactLenses.displayName = 'proto.ArContentTemplate.TemplateContactLenses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArContentTemplate.TemplateHair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArContentTemplate.TemplateHair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArContentTemplate.TemplateHair.displayName = 'proto.ArContentTemplate.TemplateHair';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.ObjectSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.ObjectSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.ObjectSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.ObjectSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    position: (f = msg.getPosition()) && Common_pb.Vector.toObject(includeInstance, f),
    rotation: (f = msg.getRotation()) && Common_pb.Vector.toObject(includeInstance, f),
    scale: (f = msg.getScale()) && Common_pb.Vector.toObject(includeInstance, f),
    imageUploadName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.ObjectSetting}
 */
proto.ArContentTemplate.ObjectSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.ObjectSetting;
  return proto.ArContentTemplate.ObjectSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.ObjectSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.ObjectSetting}
 */
proto.ArContentTemplate.ObjectSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImagePath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageName(value);
      break;
    case 3:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setRotation(value);
      break;
    case 5:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setScale(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUploadName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.ObjectSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.ObjectSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.ObjectSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.ObjectSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getRotation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getScale();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getImageUploadName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string image_path = 1;
 * @return {string}
 */
proto.ArContentTemplate.ObjectSetting.prototype.getImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
 */
proto.ArContentTemplate.ObjectSetting.prototype.setImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_name = 2;
 * @return {string}
 */
proto.ArContentTemplate.ObjectSetting.prototype.getImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
 */
proto.ArContentTemplate.ObjectSetting.prototype.setImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Common.Vector position = 3;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.ObjectSetting.prototype.getPosition = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 3));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
*/
proto.ArContentTemplate.ObjectSetting.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
 */
proto.ArContentTemplate.ObjectSetting.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.ObjectSetting.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Common.Vector rotation = 4;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.ObjectSetting.prototype.getRotation = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 4));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
*/
proto.ArContentTemplate.ObjectSetting.prototype.setRotation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
 */
proto.ArContentTemplate.ObjectSetting.prototype.clearRotation = function() {
  return this.setRotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.ObjectSetting.prototype.hasRotation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Common.Vector scale = 5;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.ObjectSetting.prototype.getScale = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 5));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
*/
proto.ArContentTemplate.ObjectSetting.prototype.setScale = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
 */
proto.ArContentTemplate.ObjectSetting.prototype.clearScale = function() {
  return this.setScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.ObjectSetting.prototype.hasScale = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string image_upload_name = 6;
 * @return {string}
 */
proto.ArContentTemplate.ObjectSetting.prototype.getImageUploadName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.ObjectSetting} returns this
 */
proto.ArContentTemplate.ObjectSetting.prototype.setImageUploadName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.TemplateEarring.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.TemplateEarring.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.TemplateEarring} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateEarring.toObject = function(includeInstance, msg) {
  var f, obj = {
    left: (f = msg.getLeft()) && proto.ArContentTemplate.EarringSettingSide.toObject(includeInstance, f),
    right: (f = msg.getRight()) && proto.ArContentTemplate.EarringSettingSide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.TemplateEarring}
 */
proto.ArContentTemplate.TemplateEarring.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.TemplateEarring;
  return proto.ArContentTemplate.TemplateEarring.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.TemplateEarring} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.TemplateEarring}
 */
proto.ArContentTemplate.TemplateEarring.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContentTemplate.EarringSettingSide;
      reader.readMessage(value,proto.ArContentTemplate.EarringSettingSide.deserializeBinaryFromReader);
      msg.setLeft(value);
      break;
    case 2:
      var value = new proto.ArContentTemplate.EarringSettingSide;
      reader.readMessage(value,proto.ArContentTemplate.EarringSettingSide.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.TemplateEarring.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.TemplateEarring.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.TemplateEarring} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateEarring.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeft();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContentTemplate.EarringSettingSide.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ArContentTemplate.EarringSettingSide.serializeBinaryToWriter
    );
  }
};


/**
 * optional EarringSettingSide left = 1;
 * @return {?proto.ArContentTemplate.EarringSettingSide}
 */
proto.ArContentTemplate.TemplateEarring.prototype.getLeft = function() {
  return /** @type{?proto.ArContentTemplate.EarringSettingSide} */ (
    jspb.Message.getWrapperField(this, proto.ArContentTemplate.EarringSettingSide, 1));
};


/**
 * @param {?proto.ArContentTemplate.EarringSettingSide|undefined} value
 * @return {!proto.ArContentTemplate.TemplateEarring} returns this
*/
proto.ArContentTemplate.TemplateEarring.prototype.setLeft = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateEarring} returns this
 */
proto.ArContentTemplate.TemplateEarring.prototype.clearLeft = function() {
  return this.setLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateEarring.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EarringSettingSide right = 2;
 * @return {?proto.ArContentTemplate.EarringSettingSide}
 */
proto.ArContentTemplate.TemplateEarring.prototype.getRight = function() {
  return /** @type{?proto.ArContentTemplate.EarringSettingSide} */ (
    jspb.Message.getWrapperField(this, proto.ArContentTemplate.EarringSettingSide, 2));
};


/**
 * @param {?proto.ArContentTemplate.EarringSettingSide|undefined} value
 * @return {!proto.ArContentTemplate.TemplateEarring} returns this
*/
proto.ArContentTemplate.TemplateEarring.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateEarring} returns this
 */
proto.ArContentTemplate.TemplateEarring.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateEarring.prototype.hasRight = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArContentTemplate.EarringSettingSide.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.EarringSettingSide.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.EarringSettingSide} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.EarringSettingSide.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
    proto.ArContentTemplate.EarringSetting.toObject, includeInstance),
    overAllPosition: (f = msg.getOverAllPosition()) && Common_pb.Vector.toObject(includeInstance, f),
    overAllRotation: (f = msg.getOverAllRotation()) && Common_pb.Vector.toObject(includeInstance, f),
    overAllScale: (f = msg.getOverAllScale()) && Common_pb.Vector.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.EarringSettingSide}
 */
proto.ArContentTemplate.EarringSettingSide.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.EarringSettingSide;
  return proto.ArContentTemplate.EarringSettingSide.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.EarringSettingSide} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.EarringSettingSide}
 */
proto.ArContentTemplate.EarringSettingSide.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContentTemplate.EarringSetting;
      reader.readMessage(value,proto.ArContentTemplate.EarringSetting.deserializeBinaryFromReader);
      msg.addSettings(value);
      break;
    case 2:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setOverAllPosition(value);
      break;
    case 3:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setOverAllRotation(value);
      break;
    case 4:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setOverAllScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.EarringSettingSide.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.EarringSettingSide} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.EarringSettingSide.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ArContentTemplate.EarringSetting.serializeBinaryToWriter
    );
  }
  f = message.getOverAllPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getOverAllRotation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getOverAllScale();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EarringSetting settings = 1;
 * @return {!Array<!proto.ArContentTemplate.EarringSetting>}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.getSettingsList = function() {
  return /** @type{!Array<!proto.ArContentTemplate.EarringSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ArContentTemplate.EarringSetting, 1));
};


/**
 * @param {!Array<!proto.ArContentTemplate.EarringSetting>} value
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
*/
proto.ArContentTemplate.EarringSettingSide.prototype.setSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ArContentTemplate.EarringSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ArContentTemplate.EarringSetting}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.addSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ArContentTemplate.EarringSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
 */
proto.ArContentTemplate.EarringSettingSide.prototype.clearSettingsList = function() {
  return this.setSettingsList([]);
};


/**
 * optional Common.Vector over_all_position = 2;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.getOverAllPosition = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 2));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
*/
proto.ArContentTemplate.EarringSettingSide.prototype.setOverAllPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
 */
proto.ArContentTemplate.EarringSettingSide.prototype.clearOverAllPosition = function() {
  return this.setOverAllPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.hasOverAllPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Common.Vector over_all_rotation = 3;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.getOverAllRotation = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 3));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
*/
proto.ArContentTemplate.EarringSettingSide.prototype.setOverAllRotation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
 */
proto.ArContentTemplate.EarringSettingSide.prototype.clearOverAllRotation = function() {
  return this.setOverAllRotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.hasOverAllRotation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Common.Vector over_all_scale = 4;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.getOverAllScale = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 4));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
*/
proto.ArContentTemplate.EarringSettingSide.prototype.setOverAllScale = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.EarringSettingSide} returns this
 */
proto.ArContentTemplate.EarringSettingSide.prototype.clearOverAllScale = function() {
  return this.setOverAllScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.EarringSettingSide.prototype.hasOverAllScale = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.EarringSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.EarringSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.EarringSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.EarringSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && proto.ArContentTemplate.ObjectSetting.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.EarringSetting}
 */
proto.ArContentTemplate.EarringSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.EarringSetting;
  return proto.ArContentTemplate.EarringSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.EarringSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.EarringSetting}
 */
proto.ArContentTemplate.EarringSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContentTemplate.ObjectSetting;
      reader.readMessage(value,proto.ArContentTemplate.ObjectSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    case 2:
      var value = /** @type {!proto.ArContentTemplate.EarringObjectLocationEnum} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.EarringSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.EarringSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.EarringSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.EarringSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContentTemplate.ObjectSetting.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ObjectSetting setting = 1;
 * @return {?proto.ArContentTemplate.ObjectSetting}
 */
proto.ArContentTemplate.EarringSetting.prototype.getSetting = function() {
  return /** @type{?proto.ArContentTemplate.ObjectSetting} */ (
    jspb.Message.getWrapperField(this, proto.ArContentTemplate.ObjectSetting, 1));
};


/**
 * @param {?proto.ArContentTemplate.ObjectSetting|undefined} value
 * @return {!proto.ArContentTemplate.EarringSetting} returns this
*/
proto.ArContentTemplate.EarringSetting.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.EarringSetting} returns this
 */
proto.ArContentTemplate.EarringSetting.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.EarringSetting.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EarringObjectLocationEnum location = 2;
 * @return {!proto.ArContentTemplate.EarringObjectLocationEnum}
 */
proto.ArContentTemplate.EarringSetting.prototype.getLocation = function() {
  return /** @type {!proto.ArContentTemplate.EarringObjectLocationEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ArContentTemplate.EarringObjectLocationEnum} value
 * @return {!proto.ArContentTemplate.EarringSetting} returns this
 */
proto.ArContentTemplate.EarringSetting.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArContentTemplate.TemplateGlasses.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.TemplateGlasses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.TemplateGlasses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateGlasses.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
    proto.ArContentTemplate.GlassesSetting.toObject, includeInstance),
    overAllPosition: (f = msg.getOverAllPosition()) && Common_pb.Vector.toObject(includeInstance, f),
    overAllRotation: (f = msg.getOverAllRotation()) && Common_pb.Vector.toObject(includeInstance, f),
    overAllScale: (f = msg.getOverAllScale()) && Common_pb.Vector.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.TemplateGlasses}
 */
proto.ArContentTemplate.TemplateGlasses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.TemplateGlasses;
  return proto.ArContentTemplate.TemplateGlasses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.TemplateGlasses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.TemplateGlasses}
 */
proto.ArContentTemplate.TemplateGlasses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContentTemplate.GlassesSetting;
      reader.readMessage(value,proto.ArContentTemplate.GlassesSetting.deserializeBinaryFromReader);
      msg.addSettings(value);
      break;
    case 2:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setOverAllPosition(value);
      break;
    case 3:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setOverAllRotation(value);
      break;
    case 4:
      var value = new Common_pb.Vector;
      reader.readMessage(value,Common_pb.Vector.deserializeBinaryFromReader);
      msg.setOverAllScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.TemplateGlasses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.TemplateGlasses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateGlasses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ArContentTemplate.GlassesSetting.serializeBinaryToWriter
    );
  }
  f = message.getOverAllPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getOverAllRotation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getOverAllScale();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.Vector.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GlassesSetting settings = 1;
 * @return {!Array<!proto.ArContentTemplate.GlassesSetting>}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.getSettingsList = function() {
  return /** @type{!Array<!proto.ArContentTemplate.GlassesSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ArContentTemplate.GlassesSetting, 1));
};


/**
 * @param {!Array<!proto.ArContentTemplate.GlassesSetting>} value
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
*/
proto.ArContentTemplate.TemplateGlasses.prototype.setSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ArContentTemplate.GlassesSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ArContentTemplate.GlassesSetting}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.addSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ArContentTemplate.GlassesSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
 */
proto.ArContentTemplate.TemplateGlasses.prototype.clearSettingsList = function() {
  return this.setSettingsList([]);
};


/**
 * optional Common.Vector over_all_position = 2;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.getOverAllPosition = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 2));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
*/
proto.ArContentTemplate.TemplateGlasses.prototype.setOverAllPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
 */
proto.ArContentTemplate.TemplateGlasses.prototype.clearOverAllPosition = function() {
  return this.setOverAllPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.hasOverAllPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Common.Vector over_all_rotation = 3;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.getOverAllRotation = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 3));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
*/
proto.ArContentTemplate.TemplateGlasses.prototype.setOverAllRotation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
 */
proto.ArContentTemplate.TemplateGlasses.prototype.clearOverAllRotation = function() {
  return this.setOverAllRotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.hasOverAllRotation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Common.Vector over_all_scale = 4;
 * @return {?proto.Common.Vector}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.getOverAllScale = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, Common_pb.Vector, 4));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
*/
proto.ArContentTemplate.TemplateGlasses.prototype.setOverAllScale = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateGlasses} returns this
 */
proto.ArContentTemplate.TemplateGlasses.prototype.clearOverAllScale = function() {
  return this.setOverAllScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateGlasses.prototype.hasOverAllScale = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.GlassesSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.GlassesSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.GlassesSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.GlassesSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && proto.ArContentTemplate.ObjectSetting.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.GlassesSetting}
 */
proto.ArContentTemplate.GlassesSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.GlassesSetting;
  return proto.ArContentTemplate.GlassesSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.GlassesSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.GlassesSetting}
 */
proto.ArContentTemplate.GlassesSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArContentTemplate.ObjectSetting;
      reader.readMessage(value,proto.ArContentTemplate.ObjectSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    case 2:
      var value = /** @type {!proto.ArContentTemplate.GlassesObjectLocationEnum} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.GlassesSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.GlassesSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.GlassesSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.GlassesSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArContentTemplate.ObjectSetting.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ObjectSetting setting = 1;
 * @return {?proto.ArContentTemplate.ObjectSetting}
 */
proto.ArContentTemplate.GlassesSetting.prototype.getSetting = function() {
  return /** @type{?proto.ArContentTemplate.ObjectSetting} */ (
    jspb.Message.getWrapperField(this, proto.ArContentTemplate.ObjectSetting, 1));
};


/**
 * @param {?proto.ArContentTemplate.ObjectSetting|undefined} value
 * @return {!proto.ArContentTemplate.GlassesSetting} returns this
*/
proto.ArContentTemplate.GlassesSetting.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.GlassesSetting} returns this
 */
proto.ArContentTemplate.GlassesSetting.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.GlassesSetting.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GlassesObjectLocationEnum location = 2;
 * @return {!proto.ArContentTemplate.GlassesObjectLocationEnum}
 */
proto.ArContentTemplate.GlassesSetting.prototype.getLocation = function() {
  return /** @type {!proto.ArContentTemplate.GlassesObjectLocationEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ArContentTemplate.GlassesObjectLocationEnum} value
 * @return {!proto.ArContentTemplate.GlassesSetting} returns this
 */
proto.ArContentTemplate.GlassesSetting.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.TemplateContactLenses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.TemplateContactLenses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateContactLenses.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    colorCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    left: (f = msg.getLeft()) && Common_pb.Transform.toObject(includeInstance, f),
    right: (f = msg.getRight()) && Common_pb.Transform.toObject(includeInstance, f),
    radius: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imageUploadName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.TemplateContactLenses}
 */
proto.ArContentTemplate.TemplateContactLenses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.TemplateContactLenses;
  return proto.ArContentTemplate.TemplateContactLenses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.TemplateContactLenses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.TemplateContactLenses}
 */
proto.ArContentTemplate.TemplateContactLenses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImagePath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorCode(value);
      break;
    case 4:
      var value = new Common_pb.Transform;
      reader.readMessage(value,Common_pb.Transform.deserializeBinaryFromReader);
      msg.setLeft(value);
      break;
    case 5:
      var value = new Common_pb.Transform;
      reader.readMessage(value,Common_pb.Transform.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRadius(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUploadName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.TemplateContactLenses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.TemplateContactLenses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateContactLenses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLeft();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.Transform.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Common_pb.Transform.serializeBinaryToWriter
    );
  }
  f = message.getRadius();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string image_path = 1;
 * @return {string}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.setImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_name = 2;
 * @return {string}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.setImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color_code = 3;
 * @return {string}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getColorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.setColorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Common.Transform left = 4;
 * @return {?proto.Common.Transform}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getLeft = function() {
  return /** @type{?proto.Common.Transform} */ (
    jspb.Message.getWrapperField(this, Common_pb.Transform, 4));
};


/**
 * @param {?proto.Common.Transform|undefined} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
*/
proto.ArContentTemplate.TemplateContactLenses.prototype.setLeft = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.clearLeft = function() {
  return this.setLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Common.Transform right = 5;
 * @return {?proto.Common.Transform}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getRight = function() {
  return /** @type{?proto.Common.Transform} */ (
    jspb.Message.getWrapperField(this, Common_pb.Transform, 5));
};


/**
 * @param {?proto.Common.Transform|undefined} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
*/
proto.ArContentTemplate.TemplateContactLenses.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.hasRight = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string radius = 6;
 * @return {string}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getRadius = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.setRadius = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string image_upload_name = 7;
 * @return {string}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.getImageUploadName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.setImageUploadName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ArContentTemplate.TemplateContactLenses} returns this
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.clearImageUploadName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArContentTemplate.TemplateContactLenses.prototype.hasImageUploadName = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArContentTemplate.TemplateHair.prototype.toObject = function(opt_includeInstance) {
  return proto.ArContentTemplate.TemplateHair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArContentTemplate.TemplateHair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateHair.toObject = function(includeInstance, msg) {
  var f, obj = {
    colorCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alphaSoftness: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alphaFeather: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArContentTemplate.TemplateHair}
 */
proto.ArContentTemplate.TemplateHair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArContentTemplate.TemplateHair;
  return proto.ArContentTemplate.TemplateHair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArContentTemplate.TemplateHair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArContentTemplate.TemplateHair}
 */
proto.ArContentTemplate.TemplateHair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlphaSoftness(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlphaFeather(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArContentTemplate.TemplateHair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArContentTemplate.TemplateHair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArContentTemplate.TemplateHair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArContentTemplate.TemplateHair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColorCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlphaSoftness();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlphaFeather();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string color_code = 1;
 * @return {string}
 */
proto.ArContentTemplate.TemplateHair.prototype.getColorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateHair} returns this
 */
proto.ArContentTemplate.TemplateHair.prototype.setColorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alpha_softness = 2;
 * @return {string}
 */
proto.ArContentTemplate.TemplateHair.prototype.getAlphaSoftness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateHair} returns this
 */
proto.ArContentTemplate.TemplateHair.prototype.setAlphaSoftness = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alpha_feather = 3;
 * @return {string}
 */
proto.ArContentTemplate.TemplateHair.prototype.getAlphaFeather = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ArContentTemplate.TemplateHair} returns this
 */
proto.ArContentTemplate.TemplateHair.prototype.setAlphaFeather = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.ArContentTemplate.EarringObjectLocationEnum = {
  EARRING_NONE: 0,
  EARRING_FIRST: 1,
  EARRING_SECOND: 2,
  EARRING_THIRD: 3
};

/**
 * @enum {number}
 */
proto.ArContentTemplate.GlassesObjectLocationEnum = {
  GLASSES_NONE: 0,
  GLASSES_RIGHT: 1,
  GLASSES_FRONT: 2,
  GLASSES_LEFT: 3
};

goog.object.extend(exports, proto.ArContentTemplate);
