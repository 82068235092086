import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import { ArContentTemplateEnum } from "@/proto/ArContent_pb";
import { LensTemplatePosition } from "@/components/Helpers/SetTemplate";
import { ILensAction, ITransform } from "@/components/templateInterface";
import { defaultTransform, ITemplateLensTransform } from "@/components/EditLens";
export interface IEditLenseStatus {
        isSubmit:boolean,
        loadArView:boolean,
        token:string,
        isLoading :boolean,
        imageId:string,
        imageUploadId:string,
        imagePath:string,
        arSwitch:boolean,
        colorCode:string,
        arLoading:boolean,
        arView:boolean,
        componentSize:LensTemplatePosition.LEFT | LensTemplatePosition.RIGHT,
        contentInfo: ArContent_pb.ArContentInfo.AsObject | undefined,
        state:ITemplateLensTransform
}

export const editLenseStatus = createModel<RootModel>()({
    state: {
        isSubmit:false,
        loadArView:false,
        token:'',
        isLoading :true,
        imageId:'',
        imageUploadId:'',
        colorCode:'#FFFFFF',
        imagePath:'',
        arSwitch:false,
        arLoading:true,
        arView:true,
        componentSize:LensTemplatePosition.LEFT,
        contentInfo:undefined,
        state:defaultTransform
    } as IEditLenseStatus,
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            // console.log(payload)
            return { ...state, [type]: data};
        },
        transformReducer(state, action: ILensAction) {
            const { type, payload } = action;
            switch (type) {
              case LensTemplatePosition.LEFT:
              case LensTemplatePosition.RIGHT:
              case LensTemplatePosition.RADIUS:
                return {
                  ...state,
                  state:{
                    ...state.state,
                    [type]:payload
                  }
                 
                };
              default:
                return state;
            }
          }
    },
    effects: (dispatch) =>({
        clearup(){
            dispatch.editLenseStatus.setState({data:'', type:SET_TOKEN})
            dispatch.editLenseStatus.setState({data:true,type:SET_ISLOADING})
            dispatch.editLenseStatus.setState({data:true,type:SET_AR_LOADING})
            dispatch.editLenseStatus.setState({data:false,type:SET_LOAD_ARVIEW})
            dispatch.editLenseStatus.setState({data:false,type:SET_IS_Submit})
            dispatch.editLenseStatus.setState({data:LensTemplatePosition.LEFT,type:SET_COMPONENT_SIZE})
        }
    })
});

export const SET_TOKEN = "token";
export const SET_IS_Submit = "isSubmit";
export const SET_CONTENTINFO = "contentInfo";
export const SET_ARVIEW = 'arView';
export const SET_COLOR_CODE = "colorCode";
export const SET_AR_LOADING = "arLoading";
export const SET_AR_SWITCH = "arSwitch";
export const SET_IMAGE_PATH = "imagePath";
export const SET_IMAGE_ID = "imageId";
export const SET_IMAGE_UPLOAD_ID = "imageUploadId";
export const SET_LOAD_ARVIEW = "loadArView"
export const SET_ISLOADING = "isLoading"
export const SET_COMPONENT_SIZE = "componentSize"
export const SET_STATE = "state"