/**
 * @fileoverview gRPC-Web generated client stub for WebServices
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as AccountSettings_pb from './AccountSettings_pb';
import * as ArContent_pb from './ArContent_pb';
import * as Auth_pb from './Auth_pb';
import * as Common_pb from './Common_pb';
import * as Echo_pb from './Echo_pb';
import * as Permission_pb from './Permission_pb';
import * as Program_pb from './Program_pb';
import * as Statistic_pb from './Statistic_pb';
import * as User_pb from './User_pb';


export class EchoServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorEcho = new grpcWeb.MethodDescriptor(
    '/WebServices.EchoServices/Echo',
    grpcWeb.MethodType.UNARY,
    Echo_pb.EchoRequest,
    Echo_pb.EchoReply,
    (request: Echo_pb.EchoRequest) => {
      return request.serializeBinary();
    },
    Echo_pb.EchoReply.deserializeBinary
  );

  echo(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null): Promise<Echo_pb.EchoReply>;

  echo(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Echo_pb.EchoReply) => void): grpcWeb.ClientReadableStream<Echo_pb.EchoReply>;

  echo(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Echo_pb.EchoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.EchoServices/Echo',
        request,
        metadata || {},
        this.methodDescriptorEcho,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.EchoServices/Echo',
    request,
    metadata || {},
    this.methodDescriptorEcho);
  }

  methodDescriptorEchoToken = new grpcWeb.MethodDescriptor(
    '/WebServices.EchoServices/EchoToken',
    grpcWeb.MethodType.UNARY,
    Echo_pb.EchoRequest,
    Echo_pb.EchoReply,
    (request: Echo_pb.EchoRequest) => {
      return request.serializeBinary();
    },
    Echo_pb.EchoReply.deserializeBinary
  );

  echoToken(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null): Promise<Echo_pb.EchoReply>;

  echoToken(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Echo_pb.EchoReply) => void): grpcWeb.ClientReadableStream<Echo_pb.EchoReply>;

  echoToken(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Echo_pb.EchoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.EchoServices/EchoToken',
        request,
        metadata || {},
        this.methodDescriptorEchoToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.EchoServices/EchoToken',
    request,
    metadata || {},
    this.methodDescriptorEchoToken);
  }

  methodDescriptorEchoPermission = new grpcWeb.MethodDescriptor(
    '/WebServices.EchoServices/EchoPermission',
    grpcWeb.MethodType.UNARY,
    Echo_pb.EchoRequest,
    Echo_pb.EchoReply,
    (request: Echo_pb.EchoRequest) => {
      return request.serializeBinary();
    },
    Echo_pb.EchoReply.deserializeBinary
  );

  echoPermission(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null): Promise<Echo_pb.EchoReply>;

  echoPermission(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Echo_pb.EchoReply) => void): grpcWeb.ClientReadableStream<Echo_pb.EchoReply>;

  echoPermission(
    request: Echo_pb.EchoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Echo_pb.EchoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.EchoServices/EchoPermission',
        request,
        metadata || {},
        this.methodDescriptorEchoPermission,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.EchoServices/EchoPermission',
    request,
    metadata || {},
    this.methodDescriptorEchoPermission);
  }

}

export class UserServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddUserChild = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/AddUserChild',
    grpcWeb.MethodType.UNARY,
    User_pb.AddUserChildRequest,
    Common_pb.CommonReply,
    (request: User_pb.AddUserChildRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  addUserChild(
    request: User_pb.AddUserChildRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  addUserChild(
    request: User_pb.AddUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  addUserChild(
    request: User_pb.AddUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/AddUserChild',
        request,
        metadata || {},
        this.methodDescriptorAddUserChild,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/AddUserChild',
    request,
    metadata || {},
    this.methodDescriptorAddUserChild);
  }

  methodDescriptorUpdateUserChild = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/UpdateUserChild',
    grpcWeb.MethodType.UNARY,
    User_pb.UpdateUserChildRequest,
    Common_pb.CommonReply,
    (request: User_pb.UpdateUserChildRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  updateUserChild(
    request: User_pb.UpdateUserChildRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  updateUserChild(
    request: User_pb.UpdateUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  updateUserChild(
    request: User_pb.UpdateUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/UpdateUserChild',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserChild,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/UpdateUserChild',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserChild);
  }

  methodDescriptorGetUserChildList = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/GetUserChildList',
    grpcWeb.MethodType.UNARY,
    User_pb.GetUserChildListRequest,
    User_pb.GetUserChildListReply,
    (request: User_pb.GetUserChildListRequest) => {
      return request.serializeBinary();
    },
    User_pb.GetUserChildListReply.deserializeBinary
  );

  getUserChildList(
    request: User_pb.GetUserChildListRequest,
    metadata: grpcWeb.Metadata | null): Promise<User_pb.GetUserChildListReply>;

  getUserChildList(
    request: User_pb.GetUserChildListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: User_pb.GetUserChildListReply) => void): grpcWeb.ClientReadableStream<User_pb.GetUserChildListReply>;

  getUserChildList(
    request: User_pb.GetUserChildListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: User_pb.GetUserChildListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/GetUserChildList',
        request,
        metadata || {},
        this.methodDescriptorGetUserChildList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/GetUserChildList',
    request,
    metadata || {},
    this.methodDescriptorGetUserChildList);
  }

  methodDescriptorChangePassword = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/ChangePassword',
    grpcWeb.MethodType.UNARY,
    User_pb.ChangePasswordRequest,
    Common_pb.CommonReply,
    (request: User_pb.ChangePasswordRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  changePassword(
    request: User_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  changePassword(
    request: User_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  changePassword(
    request: User_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/ChangePassword',
        request,
        metadata || {},
        this.methodDescriptorChangePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/ChangePassword',
    request,
    metadata || {},
    this.methodDescriptorChangePassword);
  }

  methodDescriptorForgotPassword = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/ForgotPassword',
    grpcWeb.MethodType.UNARY,
    User_pb.ForgotPasswordRequest,
    Common_pb.CommonReply,
    (request: User_pb.ForgotPasswordRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  forgotPassword(
    request: User_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  forgotPassword(
    request: User_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  forgotPassword(
    request: User_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/ForgotPassword',
        request,
        metadata || {},
        this.methodDescriptorForgotPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/ForgotPassword',
    request,
    metadata || {},
    this.methodDescriptorForgotPassword);
  }

  methodDescriptorDeleteUserChild = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/DeleteUserChild',
    grpcWeb.MethodType.UNARY,
    User_pb.DeleteUserChildRequest,
    Common_pb.CommonReply,
    (request: User_pb.DeleteUserChildRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  deleteUserChild(
    request: User_pb.DeleteUserChildRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  deleteUserChild(
    request: User_pb.DeleteUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  deleteUserChild(
    request: User_pb.DeleteUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/DeleteUserChild',
        request,
        metadata || {},
        this.methodDescriptorDeleteUserChild,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/DeleteUserChild',
    request,
    metadata || {},
    this.methodDescriptorDeleteUserChild);
  }

  methodDescriptorUpdateUserProgram = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/UpdateUserProgram',
    grpcWeb.MethodType.UNARY,
    User_pb.UpdateUserProgramRequest,
    Common_pb.CommonReply,
    (request: User_pb.UpdateUserProgramRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  updateUserProgram(
    request: User_pb.UpdateUserProgramRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  updateUserProgram(
    request: User_pb.UpdateUserProgramRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  updateUserProgram(
    request: User_pb.UpdateUserProgramRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/UpdateUserProgram',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserProgram,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/UpdateUserProgram',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserProgram);
  }

  methodDescriptorGetConfirmUserChildInfo = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/GetConfirmUserChildInfo',
    grpcWeb.MethodType.UNARY,
    User_pb.GetConfirmUserChildInfoRequest,
    User_pb.GetConfirmUserChildInfoReply,
    (request: User_pb.GetConfirmUserChildInfoRequest) => {
      return request.serializeBinary();
    },
    User_pb.GetConfirmUserChildInfoReply.deserializeBinary
  );

  getConfirmUserChildInfo(
    request: User_pb.GetConfirmUserChildInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<User_pb.GetConfirmUserChildInfoReply>;

  getConfirmUserChildInfo(
    request: User_pb.GetConfirmUserChildInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: User_pb.GetConfirmUserChildInfoReply) => void): grpcWeb.ClientReadableStream<User_pb.GetConfirmUserChildInfoReply>;

  getConfirmUserChildInfo(
    request: User_pb.GetConfirmUserChildInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: User_pb.GetConfirmUserChildInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/GetConfirmUserChildInfo',
        request,
        metadata || {},
        this.methodDescriptorGetConfirmUserChildInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/GetConfirmUserChildInfo',
    request,
    metadata || {},
    this.methodDescriptorGetConfirmUserChildInfo);
  }

  methodDescriptorConfirmUserChild = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/ConfirmUserChild',
    grpcWeb.MethodType.UNARY,
    User_pb.ConfirmUserChildRequest,
    Common_pb.CommonReply,
    (request: User_pb.ConfirmUserChildRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  confirmUserChild(
    request: User_pb.ConfirmUserChildRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  confirmUserChild(
    request: User_pb.ConfirmUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  confirmUserChild(
    request: User_pb.ConfirmUserChildRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/ConfirmUserChild',
        request,
        metadata || {},
        this.methodDescriptorConfirmUserChild,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/ConfirmUserChild',
    request,
    metadata || {},
    this.methodDescriptorConfirmUserChild);
  }

  methodDescriptorResendChildInvite = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/ResendChildInvite',
    grpcWeb.MethodType.UNARY,
    User_pb.ResendChildInviteRequest,
    User_pb.ResendChildInviteReply,
    (request: User_pb.ResendChildInviteRequest) => {
      return request.serializeBinary();
    },
    User_pb.ResendChildInviteReply.deserializeBinary
  );

  resendChildInvite(
    request: User_pb.ResendChildInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<User_pb.ResendChildInviteReply>;

  resendChildInvite(
    request: User_pb.ResendChildInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: User_pb.ResendChildInviteReply) => void): grpcWeb.ClientReadableStream<User_pb.ResendChildInviteReply>;

  resendChildInvite(
    request: User_pb.ResendChildInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: User_pb.ResendChildInviteReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/ResendChildInvite',
        request,
        metadata || {},
        this.methodDescriptorResendChildInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/ResendChildInvite',
    request,
    metadata || {},
    this.methodDescriptorResendChildInvite);
  }

  methodDescriptorCancelChildInvite = new grpcWeb.MethodDescriptor(
    '/WebServices.UserServices/CancelChildInvite',
    grpcWeb.MethodType.UNARY,
    User_pb.CancelChildInviteRequest,
    Common_pb.CommonReply,
    (request: User_pb.CancelChildInviteRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  cancelChildInvite(
    request: User_pb.CancelChildInviteRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  cancelChildInvite(
    request: User_pb.CancelChildInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  cancelChildInvite(
    request: User_pb.CancelChildInviteRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.UserServices/CancelChildInvite',
        request,
        metadata || {},
        this.methodDescriptorCancelChildInvite,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.UserServices/CancelChildInvite',
    request,
    metadata || {},
    this.methodDescriptorCancelChildInvite);
  }

}

export class ProgramServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateProgram = new grpcWeb.MethodDescriptor(
    '/WebServices.ProgramServices/CreateProgram',
    grpcWeb.MethodType.UNARY,
    Program_pb.CreateProgramRequest,
    Program_pb.CreateProgramReply,
    (request: Program_pb.CreateProgramRequest) => {
      return request.serializeBinary();
    },
    Program_pb.CreateProgramReply.deserializeBinary
  );

  createProgram(
    request: Program_pb.CreateProgramRequest,
    metadata: grpcWeb.Metadata | null): Promise<Program_pb.CreateProgramReply>;

  createProgram(
    request: Program_pb.CreateProgramRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Program_pb.CreateProgramReply) => void): grpcWeb.ClientReadableStream<Program_pb.CreateProgramReply>;

  createProgram(
    request: Program_pb.CreateProgramRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Program_pb.CreateProgramReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ProgramServices/CreateProgram',
        request,
        metadata || {},
        this.methodDescriptorCreateProgram,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ProgramServices/CreateProgram',
    request,
    metadata || {},
    this.methodDescriptorCreateProgram);
  }

  methodDescriptorGetProgramList = new grpcWeb.MethodDescriptor(
    '/WebServices.ProgramServices/GetProgramList',
    grpcWeb.MethodType.UNARY,
    Program_pb.GetProgramListRequest,
    Program_pb.GetProgramListReply,
    (request: Program_pb.GetProgramListRequest) => {
      return request.serializeBinary();
    },
    Program_pb.GetProgramListReply.deserializeBinary
  );

  getProgramList(
    request: Program_pb.GetProgramListRequest,
    metadata: grpcWeb.Metadata | null): Promise<Program_pb.GetProgramListReply>;

  getProgramList(
    request: Program_pb.GetProgramListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Program_pb.GetProgramListReply) => void): grpcWeb.ClientReadableStream<Program_pb.GetProgramListReply>;

  getProgramList(
    request: Program_pb.GetProgramListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Program_pb.GetProgramListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ProgramServices/GetProgramList',
        request,
        metadata || {},
        this.methodDescriptorGetProgramList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ProgramServices/GetProgramList',
    request,
    metadata || {},
    this.methodDescriptorGetProgramList);
  }

  methodDescriptorGetFreeProgram = new grpcWeb.MethodDescriptor(
    '/WebServices.ProgramServices/GetFreeProgram',
    grpcWeb.MethodType.UNARY,
    Program_pb.GetFreeProgramRequest,
    Program_pb.GetFreeProgramReply,
    (request: Program_pb.GetFreeProgramRequest) => {
      return request.serializeBinary();
    },
    Program_pb.GetFreeProgramReply.deserializeBinary
  );

  getFreeProgram(
    request: Program_pb.GetFreeProgramRequest,
    metadata: grpcWeb.Metadata | null): Promise<Program_pb.GetFreeProgramReply>;

  getFreeProgram(
    request: Program_pb.GetFreeProgramRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Program_pb.GetFreeProgramReply) => void): grpcWeb.ClientReadableStream<Program_pb.GetFreeProgramReply>;

  getFreeProgram(
    request: Program_pb.GetFreeProgramRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Program_pb.GetFreeProgramReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ProgramServices/GetFreeProgram',
        request,
        metadata || {},
        this.methodDescriptorGetFreeProgram,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ProgramServices/GetFreeProgram',
    request,
    metadata || {},
    this.methodDescriptorGetFreeProgram);
  }

}

export class AuthServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorLogin = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/Login',
    grpcWeb.MethodType.UNARY,
    Auth_pb.LoginRequest,
    Auth_pb.AuthReply,
    (request: Auth_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    Auth_pb.AuthReply.deserializeBinary
  );

  login(
    request: Auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<Auth_pb.AuthReply>;

  login(
    request: Auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Auth_pb.AuthReply) => void): grpcWeb.ClientReadableStream<Auth_pb.AuthReply>;

  login(
    request: Auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Auth_pb.AuthReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/Login',
        request,
        metadata || {},
        this.methodDescriptorLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/Login',
    request,
    metadata || {},
    this.methodDescriptorLogin);
  }

  methodDescriptorLogout = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/Logout',
    grpcWeb.MethodType.UNARY,
    Auth_pb.LogoutRequest,
    Common_pb.CommonReply,
    (request: Auth_pb.LogoutRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  logout(
    request: Auth_pb.LogoutRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  logout(
    request: Auth_pb.LogoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  logout(
    request: Auth_pb.LogoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/Logout',
        request,
        metadata || {},
        this.methodDescriptorLogout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/Logout',
    request,
    metadata || {},
    this.methodDescriptorLogout);
  }

  methodDescriptorRegister = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/Register',
    grpcWeb.MethodType.UNARY,
    Auth_pb.RegisterRequest,
    Auth_pb.RegisterReply,
    (request: Auth_pb.RegisterRequest) => {
      return request.serializeBinary();
    },
    Auth_pb.RegisterReply.deserializeBinary
  );

  register(
    request: Auth_pb.RegisterRequest,
    metadata: grpcWeb.Metadata | null): Promise<Auth_pb.RegisterReply>;

  register(
    request: Auth_pb.RegisterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Auth_pb.RegisterReply) => void): grpcWeb.ClientReadableStream<Auth_pb.RegisterReply>;

  register(
    request: Auth_pb.RegisterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Auth_pb.RegisterReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/Register',
        request,
        metadata || {},
        this.methodDescriptorRegister,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/Register',
    request,
    metadata || {},
    this.methodDescriptorRegister);
  }

  methodDescriptorConfirmRegisterEmail = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/ConfirmRegisterEmail',
    grpcWeb.MethodType.UNARY,
    Auth_pb.ConfirmRegisterEmailRequest,
    Common_pb.CommonReply,
    (request: Auth_pb.ConfirmRegisterEmailRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  confirmRegisterEmail(
    request: Auth_pb.ConfirmRegisterEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  confirmRegisterEmail(
    request: Auth_pb.ConfirmRegisterEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  confirmRegisterEmail(
    request: Auth_pb.ConfirmRegisterEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/ConfirmRegisterEmail',
        request,
        metadata || {},
        this.methodDescriptorConfirmRegisterEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/ConfirmRegisterEmail',
    request,
    metadata || {},
    this.methodDescriptorConfirmRegisterEmail);
  }

  methodDescriptorResendRegisterEmail = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/ResendRegisterEmail',
    grpcWeb.MethodType.UNARY,
    Auth_pb.ResendRegisterEmailRequest,
    Auth_pb.ResendRegisterEmailReply,
    (request: Auth_pb.ResendRegisterEmailRequest) => {
      return request.serializeBinary();
    },
    Auth_pb.ResendRegisterEmailReply.deserializeBinary
  );

  resendRegisterEmail(
    request: Auth_pb.ResendRegisterEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<Auth_pb.ResendRegisterEmailReply>;

  resendRegisterEmail(
    request: Auth_pb.ResendRegisterEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Auth_pb.ResendRegisterEmailReply) => void): grpcWeb.ClientReadableStream<Auth_pb.ResendRegisterEmailReply>;

  resendRegisterEmail(
    request: Auth_pb.ResendRegisterEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Auth_pb.ResendRegisterEmailReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/ResendRegisterEmail',
        request,
        metadata || {},
        this.methodDescriptorResendRegisterEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/ResendRegisterEmail',
    request,
    metadata || {},
    this.methodDescriptorResendRegisterEmail);
  }

  methodDescriptorCheckRegisterConfirmState = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/CheckRegisterConfirmState',
    grpcWeb.MethodType.UNARY,
    Auth_pb.CheckRegisterConfirmStateRequest,
    Auth_pb.CheckRegisterConfirmStateReply,
    (request: Auth_pb.CheckRegisterConfirmStateRequest) => {
      return request.serializeBinary();
    },
    Auth_pb.CheckRegisterConfirmStateReply.deserializeBinary
  );

  checkRegisterConfirmState(
    request: Auth_pb.CheckRegisterConfirmStateRequest,
    metadata: grpcWeb.Metadata | null): Promise<Auth_pb.CheckRegisterConfirmStateReply>;

  checkRegisterConfirmState(
    request: Auth_pb.CheckRegisterConfirmStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Auth_pb.CheckRegisterConfirmStateReply) => void): grpcWeb.ClientReadableStream<Auth_pb.CheckRegisterConfirmStateReply>;

  checkRegisterConfirmState(
    request: Auth_pb.CheckRegisterConfirmStateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Auth_pb.CheckRegisterConfirmStateReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/CheckRegisterConfirmState',
        request,
        metadata || {},
        this.methodDescriptorCheckRegisterConfirmState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/CheckRegisterConfirmState',
    request,
    metadata || {},
    this.methodDescriptorCheckRegisterConfirmState);
  }

  methodDescriptorRedeemToken = new grpcWeb.MethodDescriptor(
    '/WebServices.AuthServices/RedeemToken',
    grpcWeb.MethodType.UNARY,
    Auth_pb.RedeemTokenRequest,
    Auth_pb.AuthReply,
    (request: Auth_pb.RedeemTokenRequest) => {
      return request.serializeBinary();
    },
    Auth_pb.AuthReply.deserializeBinary
  );

  redeemToken(
    request: Auth_pb.RedeemTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<Auth_pb.AuthReply>;

  redeemToken(
    request: Auth_pb.RedeemTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Auth_pb.AuthReply) => void): grpcWeb.ClientReadableStream<Auth_pb.AuthReply>;

  redeemToken(
    request: Auth_pb.RedeemTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Auth_pb.AuthReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AuthServices/RedeemToken',
        request,
        metadata || {},
        this.methodDescriptorRedeemToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AuthServices/RedeemToken',
    request,
    metadata || {},
    this.methodDescriptorRedeemToken);
  }

}

export class PermissionServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetPermissionGroupDefaultList = new grpcWeb.MethodDescriptor(
    '/WebServices.PermissionServices/GetPermissionGroupDefaultList',
    grpcWeb.MethodType.UNARY,
    Permission_pb.GetDefaultPermissionGroupListRequest,
    Permission_pb.GetDefaultPermissionGroupListReply,
    (request: Permission_pb.GetDefaultPermissionGroupListRequest) => {
      return request.serializeBinary();
    },
    Permission_pb.GetDefaultPermissionGroupListReply.deserializeBinary
  );

  getPermissionGroupDefaultList(
    request: Permission_pb.GetDefaultPermissionGroupListRequest,
    metadata: grpcWeb.Metadata | null): Promise<Permission_pb.GetDefaultPermissionGroupListReply>;

  getPermissionGroupDefaultList(
    request: Permission_pb.GetDefaultPermissionGroupListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Permission_pb.GetDefaultPermissionGroupListReply) => void): grpcWeb.ClientReadableStream<Permission_pb.GetDefaultPermissionGroupListReply>;

  getPermissionGroupDefaultList(
    request: Permission_pb.GetDefaultPermissionGroupListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Permission_pb.GetDefaultPermissionGroupListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.PermissionServices/GetPermissionGroupDefaultList',
        request,
        metadata || {},
        this.methodDescriptorGetPermissionGroupDefaultList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.PermissionServices/GetPermissionGroupDefaultList',
    request,
    metadata || {},
    this.methodDescriptorGetPermissionGroupDefaultList);
  }

}

export class ArContentServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetCategoryList = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetCategoryList',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetCategoryListRequest,
    ArContent_pb.GetCategoryListReply,
    (request: ArContent_pb.GetCategoryListRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetCategoryListReply.deserializeBinary
  );

  getCategoryList(
    request: ArContent_pb.GetCategoryListRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetCategoryListReply>;

  getCategoryList(
    request: ArContent_pb.GetCategoryListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetCategoryListReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetCategoryListReply>;

  getCategoryList(
    request: ArContent_pb.GetCategoryListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetCategoryListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetCategoryList',
        request,
        metadata || {},
        this.methodDescriptorGetCategoryList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetCategoryList',
    request,
    metadata || {},
    this.methodDescriptorGetCategoryList);
  }

  methodDescriptorGetTypeList = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetTypeList',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetTypeListRequest,
    ArContent_pb.GetTypeListReply,
    (request: ArContent_pb.GetTypeListRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetTypeListReply.deserializeBinary
  );

  getTypeList(
    request: ArContent_pb.GetTypeListRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetTypeListReply>;

  getTypeList(
    request: ArContent_pb.GetTypeListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetTypeListReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetTypeListReply>;

  getTypeList(
    request: ArContent_pb.GetTypeListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetTypeListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetTypeList',
        request,
        metadata || {},
        this.methodDescriptorGetTypeList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetTypeList',
    request,
    metadata || {},
    this.methodDescriptorGetTypeList);
  }

  methodDescriptorGetTemplateList = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetTemplateList',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetTemplateListRequest,
    ArContent_pb.GetTemplateListReply,
    (request: ArContent_pb.GetTemplateListRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetTemplateListReply.deserializeBinary
  );

  getTemplateList(
    request: ArContent_pb.GetTemplateListRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetTemplateListReply>;

  getTemplateList(
    request: ArContent_pb.GetTemplateListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetTemplateListReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetTemplateListReply>;

  getTemplateList(
    request: ArContent_pb.GetTemplateListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetTemplateListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetTemplateList',
        request,
        metadata || {},
        this.methodDescriptorGetTemplateList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetTemplateList',
    request,
    metadata || {},
    this.methodDescriptorGetTemplateList);
  }

  methodDescriptorCreateArContent = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/CreateArContent',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.CreateArContentRequest,
    ArContent_pb.CreateArContentReply,
    (request: ArContent_pb.CreateArContentRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.CreateArContentReply.deserializeBinary
  );

  createArContent(
    request: ArContent_pb.CreateArContentRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.CreateArContentReply>;

  createArContent(
    request: ArContent_pb.CreateArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.CreateArContentReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.CreateArContentReply>;

  createArContent(
    request: ArContent_pb.CreateArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.CreateArContentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/CreateArContent',
        request,
        metadata || {},
        this.methodDescriptorCreateArContent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/CreateArContent',
    request,
    metadata || {},
    this.methodDescriptorCreateArContent);
  }

  methodDescriptorUpdateArContentTemplate = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/UpdateArContentTemplate',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.UpdateArContentTemplateRequest,
    ArContent_pb.UpdateArContentTemplateReply,
    (request: ArContent_pb.UpdateArContentTemplateRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.UpdateArContentTemplateReply.deserializeBinary
  );

  updateArContentTemplate(
    request: ArContent_pb.UpdateArContentTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.UpdateArContentTemplateReply>;

  updateArContentTemplate(
    request: ArContent_pb.UpdateArContentTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.UpdateArContentTemplateReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.UpdateArContentTemplateReply>;

  updateArContentTemplate(
    request: ArContent_pb.UpdateArContentTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.UpdateArContentTemplateReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/UpdateArContentTemplate',
        request,
        metadata || {},
        this.methodDescriptorUpdateArContentTemplate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/UpdateArContentTemplate',
    request,
    metadata || {},
    this.methodDescriptorUpdateArContentTemplate);
  }

  methodDescriptorUpdateArContentViewer = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/UpdateArContentViewer',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.UpdateArContentViewerRequest,
    ArContent_pb.UpdateArContentViewerReplay,
    (request: ArContent_pb.UpdateArContentViewerRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.UpdateArContentViewerReplay.deserializeBinary
  );

  updateArContentViewer(
    request: ArContent_pb.UpdateArContentViewerRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.UpdateArContentViewerReplay>;

  updateArContentViewer(
    request: ArContent_pb.UpdateArContentViewerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.UpdateArContentViewerReplay) => void): grpcWeb.ClientReadableStream<ArContent_pb.UpdateArContentViewerReplay>;

  updateArContentViewer(
    request: ArContent_pb.UpdateArContentViewerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.UpdateArContentViewerReplay) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/UpdateArContentViewer',
        request,
        metadata || {},
        this.methodDescriptorUpdateArContentViewer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/UpdateArContentViewer',
    request,
    metadata || {},
    this.methodDescriptorUpdateArContentViewer);
  }

  methodDescriptorGetArContentList = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetArContentList',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetArContentListRequest,
    ArContent_pb.GetArContentListReply,
    (request: ArContent_pb.GetArContentListRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetArContentListReply.deserializeBinary
  );

  getArContentList(
    request: ArContent_pb.GetArContentListRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetArContentListReply>;

  getArContentList(
    request: ArContent_pb.GetArContentListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetArContentListReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetArContentListReply>;

  getArContentList(
    request: ArContent_pb.GetArContentListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetArContentListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetArContentList',
        request,
        metadata || {},
        this.methodDescriptorGetArContentList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetArContentList',
    request,
    metadata || {},
    this.methodDescriptorGetArContentList);
  }

  methodDescriptorGetArContent = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetArContent',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetArContentRequest,
    ArContent_pb.GetArContentReply,
    (request: ArContent_pb.GetArContentRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetArContentReply.deserializeBinary
  );

  getArContent(
    request: ArContent_pb.GetArContentRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetArContentReply>;

  getArContent(
    request: ArContent_pb.GetArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetArContentReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetArContentReply>;

  getArContent(
    request: ArContent_pb.GetArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetArContentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetArContent',
        request,
        metadata || {},
        this.methodDescriptorGetArContent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetArContent',
    request,
    metadata || {},
    this.methodDescriptorGetArContent);
  }

  methodDescriptorUpdateArContent = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/UpdateArContent',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.UpdateArContentRequest,
    ArContent_pb.UpdateArContentReply,
    (request: ArContent_pb.UpdateArContentRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.UpdateArContentReply.deserializeBinary
  );

  updateArContent(
    request: ArContent_pb.UpdateArContentRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.UpdateArContentReply>;

  updateArContent(
    request: ArContent_pb.UpdateArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.UpdateArContentReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.UpdateArContentReply>;

  updateArContent(
    request: ArContent_pb.UpdateArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.UpdateArContentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/UpdateArContent',
        request,
        metadata || {},
        this.methodDescriptorUpdateArContent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/UpdateArContent',
    request,
    metadata || {},
    this.methodDescriptorUpdateArContent);
  }

  methodDescriptorUpdateArContentIsOn = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/UpdateArContentIsOn',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.UpdateArContentIsOnRequest,
    Common_pb.CommonReply,
    (request: ArContent_pb.UpdateArContentIsOnRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  updateArContentIsOn(
    request: ArContent_pb.UpdateArContentIsOnRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  updateArContentIsOn(
    request: ArContent_pb.UpdateArContentIsOnRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  updateArContentIsOn(
    request: ArContent_pb.UpdateArContentIsOnRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/UpdateArContentIsOn',
        request,
        metadata || {},
        this.methodDescriptorUpdateArContentIsOn,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/UpdateArContentIsOn',
    request,
    metadata || {},
    this.methodDescriptorUpdateArContentIsOn);
  }

  methodDescriptorDeleteArContent = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/DeleteArContent',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.DeleteArContentRequest,
    Common_pb.CommonReply,
    (request: ArContent_pb.DeleteArContentRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  deleteArContent(
    request: ArContent_pb.DeleteArContentRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  deleteArContent(
    request: ArContent_pb.DeleteArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  deleteArContent(
    request: ArContent_pb.DeleteArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/DeleteArContent',
        request,
        metadata || {},
        this.methodDescriptorDeleteArContent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/DeleteArContent',
    request,
    metadata || {},
    this.methodDescriptorDeleteArContent);
  }

  methodDescriptorDuplicateArContent = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/DuplicateArContent',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.DuplicateArContentRequest,
    ArContent_pb.DuplicateArContentReply,
    (request: ArContent_pb.DuplicateArContentRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.DuplicateArContentReply.deserializeBinary
  );

  duplicateArContent(
    request: ArContent_pb.DuplicateArContentRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.DuplicateArContentReply>;

  duplicateArContent(
    request: ArContent_pb.DuplicateArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.DuplicateArContentReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.DuplicateArContentReply>;

  duplicateArContent(
    request: ArContent_pb.DuplicateArContentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.DuplicateArContentReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/DuplicateArContent',
        request,
        metadata || {},
        this.methodDescriptorDuplicateArContent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/DuplicateArContent',
    request,
    metadata || {},
    this.methodDescriptorDuplicateArContent);
  }

  methodDescriptorGetArLink = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetArLink',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetArLinkRequest,
    ArContent_pb.GetArLinkReply,
    (request: ArContent_pb.GetArLinkRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetArLinkReply.deserializeBinary
  );

  getArLink(
    request: ArContent_pb.GetArLinkRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetArLinkReply>;

  getArLink(
    request: ArContent_pb.GetArLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetArLinkReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetArLinkReply>;

  getArLink(
    request: ArContent_pb.GetArLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetArLinkReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetArLink',
        request,
        metadata || {},
        this.methodDescriptorGetArLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetArLink',
    request,
    metadata || {},
    this.methodDescriptorGetArLink);
  }

  methodDescriptorGetViewerData = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/GetViewerData',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.GetViewerDataRequest,
    ArContent_pb.GetViewerDataReply,
    (request: ArContent_pb.GetViewerDataRequest) => {
      return request.serializeBinary();
    },
    ArContent_pb.GetViewerDataReply.deserializeBinary
  );

  getViewerData(
    request: ArContent_pb.GetViewerDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<ArContent_pb.GetViewerDataReply>;

  getViewerData(
    request: ArContent_pb.GetViewerDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetViewerDataReply) => void): grpcWeb.ClientReadableStream<ArContent_pb.GetViewerDataReply>;

  getViewerData(
    request: ArContent_pb.GetViewerDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ArContent_pb.GetViewerDataReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/GetViewerData',
        request,
        metadata || {},
        this.methodDescriptorGetViewerData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/GetViewerData',
    request,
    metadata || {},
    this.methodDescriptorGetViewerData);
  }

  methodDescriptorUpdateArContentThreeDModel = new grpcWeb.MethodDescriptor(
    '/WebServices.ArContentServices/UpdateArContentThreeDModel',
    grpcWeb.MethodType.UNARY,
    ArContent_pb.UpdateArContentThreeDModelRequest,
    Common_pb.CommonReply,
    (request: ArContent_pb.UpdateArContentThreeDModelRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  updateArContentThreeDModel(
    request: ArContent_pb.UpdateArContentThreeDModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  updateArContentThreeDModel(
    request: ArContent_pb.UpdateArContentThreeDModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  updateArContentThreeDModel(
    request: ArContent_pb.UpdateArContentThreeDModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.ArContentServices/UpdateArContentThreeDModel',
        request,
        metadata || {},
        this.methodDescriptorUpdateArContentThreeDModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.ArContentServices/UpdateArContentThreeDModel',
    request,
    metadata || {},
    this.methodDescriptorUpdateArContentThreeDModel);
  }

}

export class StatisticServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddCount = new grpcWeb.MethodDescriptor(
    '/WebServices.StatisticServices/AddCount',
    grpcWeb.MethodType.UNARY,
    Statistic_pb.AddCountRequest,
    Common_pb.CommonReply,
    (request: Statistic_pb.AddCountRequest) => {
      return request.serializeBinary();
    },
    Common_pb.CommonReply.deserializeBinary
  );

  addCount(
    request: Statistic_pb.AddCountRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.CommonReply>;

  addCount(
    request: Statistic_pb.AddCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void): grpcWeb.ClientReadableStream<Common_pb.CommonReply>;

  addCount(
    request: Statistic_pb.AddCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.CommonReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.StatisticServices/AddCount',
        request,
        metadata || {},
        this.methodDescriptorAddCount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.StatisticServices/AddCount',
    request,
    metadata || {},
    this.methodDescriptorAddCount);
  }

  methodDescriptorGetArContentDayInfoSum = new grpcWeb.MethodDescriptor(
    '/WebServices.StatisticServices/GetArContentDayInfoSum',
    grpcWeb.MethodType.UNARY,
    Statistic_pb.GetArContentDayInfoSumRequest,
    Statistic_pb.GetArContentDayInfoSumReply,
    (request: Statistic_pb.GetArContentDayInfoSumRequest) => {
      return request.serializeBinary();
    },
    Statistic_pb.GetArContentDayInfoSumReply.deserializeBinary
  );

  getArContentDayInfoSum(
    request: Statistic_pb.GetArContentDayInfoSumRequest,
    metadata: grpcWeb.Metadata | null): Promise<Statistic_pb.GetArContentDayInfoSumReply>;

  getArContentDayInfoSum(
    request: Statistic_pb.GetArContentDayInfoSumRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Statistic_pb.GetArContentDayInfoSumReply) => void): grpcWeb.ClientReadableStream<Statistic_pb.GetArContentDayInfoSumReply>;

  getArContentDayInfoSum(
    request: Statistic_pb.GetArContentDayInfoSumRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Statistic_pb.GetArContentDayInfoSumReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.StatisticServices/GetArContentDayInfoSum',
        request,
        metadata || {},
        this.methodDescriptorGetArContentDayInfoSum,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.StatisticServices/GetArContentDayInfoSum',
    request,
    metadata || {},
    this.methodDescriptorGetArContentDayInfoSum);
  }

  methodDescriptorGetArContentDropDownList = new grpcWeb.MethodDescriptor(
    '/WebServices.StatisticServices/GetArContentDropDownList',
    grpcWeb.MethodType.UNARY,
    Statistic_pb.GetArContentDropDownListRequest,
    Statistic_pb.GetArContentDropDownListReply,
    (request: Statistic_pb.GetArContentDropDownListRequest) => {
      return request.serializeBinary();
    },
    Statistic_pb.GetArContentDropDownListReply.deserializeBinary
  );

  getArContentDropDownList(
    request: Statistic_pb.GetArContentDropDownListRequest,
    metadata: grpcWeb.Metadata | null): Promise<Statistic_pb.GetArContentDropDownListReply>;

  getArContentDropDownList(
    request: Statistic_pb.GetArContentDropDownListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Statistic_pb.GetArContentDropDownListReply) => void): grpcWeb.ClientReadableStream<Statistic_pb.GetArContentDropDownListReply>;

  getArContentDropDownList(
    request: Statistic_pb.GetArContentDropDownListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Statistic_pb.GetArContentDropDownListReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.StatisticServices/GetArContentDropDownList',
        request,
        metadata || {},
        this.methodDescriptorGetArContentDropDownList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.StatisticServices/GetArContentDropDownList',
    request,
    metadata || {},
    this.methodDescriptorGetArContentDropDownList);
  }

  methodDescriptorGetArContentDayInfo = new grpcWeb.MethodDescriptor(
    '/WebServices.StatisticServices/GetArContentDayInfo',
    grpcWeb.MethodType.UNARY,
    Statistic_pb.GetArContentDayInfoRequest,
    Statistic_pb.GetArContentDayInfoReply,
    (request: Statistic_pb.GetArContentDayInfoRequest) => {
      return request.serializeBinary();
    },
    Statistic_pb.GetArContentDayInfoReply.deserializeBinary
  );

  getArContentDayInfo(
    request: Statistic_pb.GetArContentDayInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<Statistic_pb.GetArContentDayInfoReply>;

  getArContentDayInfo(
    request: Statistic_pb.GetArContentDayInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Statistic_pb.GetArContentDayInfoReply) => void): grpcWeb.ClientReadableStream<Statistic_pb.GetArContentDayInfoReply>;

  getArContentDayInfo(
    request: Statistic_pb.GetArContentDayInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Statistic_pb.GetArContentDayInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.StatisticServices/GetArContentDayInfo',
        request,
        metadata || {},
        this.methodDescriptorGetArContentDayInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.StatisticServices/GetArContentDayInfo',
    request,
    metadata || {},
    this.methodDescriptorGetArContentDayInfo);
  }

}

export class AccountSettingsServicesClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetBasicInfo = new grpcWeb.MethodDescriptor(
    '/WebServices.AccountSettingsServices/GetBasicInfo',
    grpcWeb.MethodType.UNARY,
    AccountSettings_pb.GetBasicInfoRequest,
    AccountSettings_pb.GetBasicInfoReply,
    (request: AccountSettings_pb.GetBasicInfoRequest) => {
      return request.serializeBinary();
    },
    AccountSettings_pb.GetBasicInfoReply.deserializeBinary
  );

  getBasicInfo(
    request: AccountSettings_pb.GetBasicInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<AccountSettings_pb.GetBasicInfoReply>;

  getBasicInfo(
    request: AccountSettings_pb.GetBasicInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.GetBasicInfoReply) => void): grpcWeb.ClientReadableStream<AccountSettings_pb.GetBasicInfoReply>;

  getBasicInfo(
    request: AccountSettings_pb.GetBasicInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.GetBasicInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AccountSettingsServices/GetBasicInfo',
        request,
        metadata || {},
        this.methodDescriptorGetBasicInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AccountSettingsServices/GetBasicInfo',
    request,
    metadata || {},
    this.methodDescriptorGetBasicInfo);
  }

  methodDescriptorUpdateBasicInfo = new grpcWeb.MethodDescriptor(
    '/WebServices.AccountSettingsServices/UpdateBasicInfo',
    grpcWeb.MethodType.UNARY,
    AccountSettings_pb.UpdateBasicInfoRequest,
    AccountSettings_pb.UpdateBasicInfoReply,
    (request: AccountSettings_pb.UpdateBasicInfoRequest) => {
      return request.serializeBinary();
    },
    AccountSettings_pb.UpdateBasicInfoReply.deserializeBinary
  );

  updateBasicInfo(
    request: AccountSettings_pb.UpdateBasicInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<AccountSettings_pb.UpdateBasicInfoReply>;

  updateBasicInfo(
    request: AccountSettings_pb.UpdateBasicInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.UpdateBasicInfoReply) => void): grpcWeb.ClientReadableStream<AccountSettings_pb.UpdateBasicInfoReply>;

  updateBasicInfo(
    request: AccountSettings_pb.UpdateBasicInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.UpdateBasicInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AccountSettingsServices/UpdateBasicInfo',
        request,
        metadata || {},
        this.methodDescriptorUpdateBasicInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AccountSettingsServices/UpdateBasicInfo',
    request,
    metadata || {},
    this.methodDescriptorUpdateBasicInfo);
  }

  methodDescriptorGetLoginInfo = new grpcWeb.MethodDescriptor(
    '/WebServices.AccountSettingsServices/GetLoginInfo',
    grpcWeb.MethodType.UNARY,
    AccountSettings_pb.GetLoginInfoRequest,
    AccountSettings_pb.GetLoginInfoReply,
    (request: AccountSettings_pb.GetLoginInfoRequest) => {
      return request.serializeBinary();
    },
    AccountSettings_pb.GetLoginInfoReply.deserializeBinary
  );

  getLoginInfo(
    request: AccountSettings_pb.GetLoginInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<AccountSettings_pb.GetLoginInfoReply>;

  getLoginInfo(
    request: AccountSettings_pb.GetLoginInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.GetLoginInfoReply) => void): grpcWeb.ClientReadableStream<AccountSettings_pb.GetLoginInfoReply>;

  getLoginInfo(
    request: AccountSettings_pb.GetLoginInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.GetLoginInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AccountSettingsServices/GetLoginInfo',
        request,
        metadata || {},
        this.methodDescriptorGetLoginInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AccountSettingsServices/GetLoginInfo',
    request,
    metadata || {},
    this.methodDescriptorGetLoginInfo);
  }

  methodDescriptorUpdateLoginInfo = new grpcWeb.MethodDescriptor(
    '/WebServices.AccountSettingsServices/UpdateLoginInfo',
    grpcWeb.MethodType.UNARY,
    AccountSettings_pb.UpdateLoginInfoRequest,
    AccountSettings_pb.UpdateLoginInfoReply,
    (request: AccountSettings_pb.UpdateLoginInfoRequest) => {
      return request.serializeBinary();
    },
    AccountSettings_pb.UpdateLoginInfoReply.deserializeBinary
  );

  updateLoginInfo(
    request: AccountSettings_pb.UpdateLoginInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<AccountSettings_pb.UpdateLoginInfoReply>;

  updateLoginInfo(
    request: AccountSettings_pb.UpdateLoginInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.UpdateLoginInfoReply) => void): grpcWeb.ClientReadableStream<AccountSettings_pb.UpdateLoginInfoReply>;

  updateLoginInfo(
    request: AccountSettings_pb.UpdateLoginInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: AccountSettings_pb.UpdateLoginInfoReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/WebServices.AccountSettingsServices/UpdateLoginInfo',
        request,
        metadata || {},
        this.methodDescriptorUpdateLoginInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/WebServices.AccountSettingsServices/UpdateLoginInfo',
    request,
    metadata || {},
    this.methodDescriptorUpdateLoginInfo);
  }

}

