import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb, { GetArContentListReply } from "@/proto/ArContent_pb";
import { ArContentInfo } from "@/proto/ArContent_pb"; 
import { PageInfoRequest } from "@/proto/Common_pb";
interface IProjectsModel {
  isloading: boolean,
  dataSource: ArContentInfo.AsObject[],
  token: "",
  searchData: ArContentInfo.AsObject[],
  currentPageNum: number,
  pageItemCount: number,
  pageInfo: PageInfoRequest | undefined,
  pageData: GetArContentListReply.AsObject,
  searchText: string,
}

export const ProjectListStatus = createModel<RootModel>()({
  state: {
    isloading: true,
    dataSource: [],
    token: "",
    searchData: [],
    currentPageNum: 1,
    pageItemCount: 10,
    pageInfo: undefined,
    pageData: new GetArContentListReply().toObject(),
    searchText: "",
  } as IProjectsModel ,
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
    
  },
  effects: (dispatch) => ({
    clearup(){
      dispatch.ProjectListStatus.setState({data:'',type:SET_TOKEN})
      dispatch.ProjectListStatus.setState({data:[],type:SET_DATA_SOURCE})
      dispatch.ProjectListStatus.setState({data:true,type:SET_ISLOADING})
      dispatch.ProjectListStatus.setState({data:[],type:SET_SEARCH_DATA})
    }
  })
   
});

export const SET_ISLOADING = "isloading";
export const SET_DATA_SOURCE = "dataSource";
export const SET_TOKEN = "token";
export const SET_SEARCH_DATA = "searchData";
export const SET_CURRENT_PAGE_NUMBER = "currentPageNum";
export const SET_PAGEITEM_COUNT = "pageItemCount";
export const SET_PAGEINFO = 'pageInfo';
export const SET_PAGEDATA = 'pageData';
export const SET_SEARCH_TEXT = 'searchText';
