import { Models } from "@rematch/core";
import { pageStatus } from "@/model/pageStatus";
import { arViewerStatus } from "@/model/arViewerStatus";
import { LoginStatus } from "@/model/login";
import { editEarringStatus } from "@/model/component/editEarring";
import { editLenseStatus } from "@/model/component/editLens";
import { SignUpStatus } from "@/model/signup";
import { ResendEmailStatus } from "@/model/resendEmail";
import { SendEmailStatus } from "./sendEmail";
import { ResendForgotEmailStatus } from "./resendforgotEmail";
import { BackgroundDataStatus } from "./backgroundData";
import { CreateProjectStatus } from "./arProject/CreateProject";
import { ProjectListStatus } from "./arProject/projects";
import { ProjectIndexStatus } from "./arProject/[pid]/projectIndex";
import { ProjectEditViewerStatus } from "./arProject/[pid]/editProjectViewer";
import { ProjectEditContentStatus } from "./arProject/[pid]/editProjectContent";
import { EditGlassesStatus } from "./component/editGlasses";
import { editHairStatus } from "./component/editHair";
import { BasicInfoStatus } from "./account/basicInfo_Status";
import { ConfirmRegisterStatus } from "./confirmRegister";
import { SideBarStatus } from "./component/sideBar";

//統整所有的model and all models' interface
//下列舉例請看login.ts

export interface RootModel extends Models<RootModel> {
  pageStatus: typeof pageStatus;
  arViewerStatus: typeof arViewerStatus;
  LoginStatus: typeof LoginStatus;
  editEarringStatus: typeof editEarringStatus;
  editLenseStatus: typeof editLenseStatus;
  SignUpStatus: typeof SignUpStatus;
  ResendEmailStatus: typeof ResendEmailStatus;
  SendEmailStatus: typeof SendEmailStatus;
  ResendForgotEmailStatus: typeof ResendForgotEmailStatus;
  BackgroundDataStatus: typeof BackgroundDataStatus;
  CreateProjectStatus: typeof CreateProjectStatus;
  ProjectListStatus: typeof ProjectListStatus;
  ProjectIndexStatus: typeof ProjectIndexStatus;
  ProjectEditViewerStatus: typeof ProjectEditViewerStatus;
  EditGlassesStatus: typeof EditGlassesStatus;
  editHairStatus: typeof editHairStatus;
  BasicInfoStatus: typeof BasicInfoStatus;
  ConfirmRegisterStatus: typeof ConfirmRegisterStatus;
  ProjectEditContentStatus: typeof ProjectEditContentStatus;
  SideBarStatus: typeof SideBarStatus
}

export const models: RootModel = {
  pageStatus,
  ProjectEditContentStatus,
  BasicInfoStatus,
  ConfirmRegisterStatus,
  SendEmailStatus,
  editHairStatus,
  ResendForgotEmailStatus,
  EditGlassesStatus,
  SideBarStatus,
  ProjectEditViewerStatus,
  ProjectIndexStatus,
  ResendEmailStatus,
  arViewerStatus,
  LoginStatus,
  BackgroundDataStatus,
  editEarringStatus,
  editLenseStatus,
  ProjectListStatus,
  SignUpStatus,
  CreateProjectStatus,
};
