import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import { getFreeProgramApi } from "@/helpers/programApi";
import { GetFreeProgramReply } from "@/proto/Program_pb";

export const SignUpStatus = createModel<RootModel>()({
  state: {
    programId: "",
    signUpEmail: "",
    signUpCountry: "TW",
    signUpMobile: "",
    signUpPassword: "",
    signUpConfirm: "",
  },
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
  },
  effects: (dispatch) => ({
    async load() {
        const getFreeProgram = await getFreeProgramApi()
        if (getFreeProgram instanceof GetFreeProgramReply) {
          dispatch.SignUpStatus.setState({
            type: SET_PROGRAMID,data:getFreeProgram.toObject().programId
          })
        } 
    },
    clearup() {
      dispatch.SignUpStatus.setState({ data: "", type: SET_PROGRAMID });
      dispatch.SignUpStatus.setState({ data: "", type: SET_EMAIL });
      dispatch.SignUpStatus.setState({ data: "TW", type: SET_COUNTRY });
      dispatch.SignUpStatus.setState({ data: "", type: SET_MOBILE });
      dispatch.SignUpStatus.setState({ data: "", type: SET_PASSWORD });
      dispatch.SignUpStatus.setState({ data: "", type: SET_CONFIRM_PASSWORD });
    },
  }),
});

export const SET_PROGRAMID = "programId";
export const SET_EMAIL = "signupEmail";
export const SET_COUNTRY = "signUpCountry";
export const SET_MOBILE = "signUpMobile";
export const SET_PASSWORD = "signUpPassword";
export const SET_CONFIRM_PASSWORD = "signUpConfirm";
