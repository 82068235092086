// source: Common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.Common.ArEditWindowModule', null, global);
goog.exportSymbol('proto.Common.ArInteractModule', null, global);
goog.exportSymbol('proto.Common.BusinessType', null, global);
goog.exportSymbol('proto.Common.CommonReply', null, global);
goog.exportSymbol('proto.Common.CommonRequest', null, global);
goog.exportSymbol('proto.Common.EffectTool', null, global);
goog.exportSymbol('proto.Common.ErrorCodes', null, global);
goog.exportSymbol('proto.Common.ErrorReply', null, global);
goog.exportSymbol('proto.Common.ErrorReplyGin', null, global);
goog.exportSymbol('proto.Common.MailState', null, global);
goog.exportSymbol('proto.Common.MailType', null, global);
goog.exportSymbol('proto.Common.PageInfoReply', null, global);
goog.exportSymbol('proto.Common.PageInfoRequest', null, global);
goog.exportSymbol('proto.Common.ProgramState', null, global);
goog.exportSymbol('proto.Common.Transform', null, global);
goog.exportSymbol('proto.Common.UploadFileType', null, global);
goog.exportSymbol('proto.Common.Vector', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.CommonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.CommonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.CommonRequest.displayName = 'proto.Common.CommonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.CommonReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.CommonReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.CommonReply.displayName = 'proto.Common.CommonReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.Transform = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.Transform, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.Transform.displayName = 'proto.Common.Transform';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.Vector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.Vector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.Vector.displayName = 'proto.Common.Vector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.PageInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.PageInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.PageInfoRequest.displayName = 'proto.Common.PageInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.PageInfoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.PageInfoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.PageInfoReply.displayName = 'proto.Common.PageInfoReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.ErrorReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.ErrorReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.ErrorReply.displayName = 'proto.Common.ErrorReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Common.ErrorReplyGin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Common.ErrorReplyGin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Common.ErrorReplyGin.displayName = 'proto.Common.ErrorReplyGin';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.CommonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.CommonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.CommonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.CommonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.CommonRequest}
 */
proto.Common.CommonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.CommonRequest;
  return proto.Common.CommonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.CommonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.CommonRequest}
 */
proto.Common.CommonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.CommonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.CommonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.CommonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.CommonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.CommonReply.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.CommonReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.CommonReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.CommonReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.CommonReply}
 */
proto.Common.CommonReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.CommonReply;
  return proto.Common.CommonReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.CommonReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.CommonReply}
 */
proto.Common.CommonReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.CommonReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.CommonReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.CommonReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.CommonReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.Transform.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.Transform.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.Transform} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.Transform.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && proto.Common.Vector.toObject(includeInstance, f),
    rotation: (f = msg.getRotation()) && proto.Common.Vector.toObject(includeInstance, f),
    scale: (f = msg.getScale()) && proto.Common.Vector.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.Transform}
 */
proto.Common.Transform.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.Transform;
  return proto.Common.Transform.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.Transform} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.Transform}
 */
proto.Common.Transform.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Common.Vector;
      reader.readMessage(value,proto.Common.Vector.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = new proto.Common.Vector;
      reader.readMessage(value,proto.Common.Vector.deserializeBinaryFromReader);
      msg.setRotation(value);
      break;
    case 3:
      var value = new proto.Common.Vector;
      reader.readMessage(value,proto.Common.Vector.deserializeBinaryFromReader);
      msg.setScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.Transform.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.Transform.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.Transform} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.Transform.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Common.Vector.serializeBinaryToWriter
    );
  }
  f = message.getRotation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Common.Vector.serializeBinaryToWriter
    );
  }
  f = message.getScale();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Common.Vector.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vector position = 1;
 * @return {?proto.Common.Vector}
 */
proto.Common.Transform.prototype.getPosition = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, proto.Common.Vector, 1));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.Common.Transform} returns this
*/
proto.Common.Transform.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Common.Transform} returns this
 */
proto.Common.Transform.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Common.Transform.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector rotation = 2;
 * @return {?proto.Common.Vector}
 */
proto.Common.Transform.prototype.getRotation = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, proto.Common.Vector, 2));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.Common.Transform} returns this
*/
proto.Common.Transform.prototype.setRotation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Common.Transform} returns this
 */
proto.Common.Transform.prototype.clearRotation = function() {
  return this.setRotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Common.Transform.prototype.hasRotation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector scale = 3;
 * @return {?proto.Common.Vector}
 */
proto.Common.Transform.prototype.getScale = function() {
  return /** @type{?proto.Common.Vector} */ (
    jspb.Message.getWrapperField(this, proto.Common.Vector, 3));
};


/**
 * @param {?proto.Common.Vector|undefined} value
 * @return {!proto.Common.Transform} returns this
*/
proto.Common.Transform.prototype.setScale = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Common.Transform} returns this
 */
proto.Common.Transform.prototype.clearScale = function() {
  return this.setScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Common.Transform.prototype.hasScale = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.Vector.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.Vector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.Vector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.Vector.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, ""),
    y: jspb.Message.getFieldWithDefault(msg, 2, ""),
    z: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.Vector}
 */
proto.Common.Vector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.Vector;
  return proto.Common.Vector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.Vector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.Vector}
 */
proto.Common.Vector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.Vector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.Vector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.Vector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.Vector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getY();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getZ();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string x = 1;
 * @return {string}
 */
proto.Common.Vector.prototype.getX = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.Vector} returns this
 */
proto.Common.Vector.prototype.setX = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string y = 2;
 * @return {string}
 */
proto.Common.Vector.prototype.getY = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.Vector} returns this
 */
proto.Common.Vector.prototype.setY = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string z = 3;
 * @return {string}
 */
proto.Common.Vector.prototype.getZ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.Vector} returns this
 */
proto.Common.Vector.prototype.setZ = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.PageInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.PageInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.PageInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.PageInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageItemCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentPageNum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.PageInfoRequest}
 */
proto.Common.PageInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.PageInfoRequest;
  return proto.Common.PageInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.PageInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.PageInfoRequest}
 */
proto.Common.PageInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageItemCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPageNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.PageInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.PageInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.PageInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.PageInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageItemCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentPageNum();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page_item_count = 1;
 * @return {number}
 */
proto.Common.PageInfoRequest.prototype.getPageItemCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Common.PageInfoRequest} returns this
 */
proto.Common.PageInfoRequest.prototype.setPageItemCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_page_num = 2;
 * @return {number}
 */
proto.Common.PageInfoRequest.prototype.getCurrentPageNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Common.PageInfoRequest} returns this
 */
proto.Common.PageInfoRequest.prototype.setCurrentPageNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.PageInfoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.PageInfoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.PageInfoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.PageInfoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.PageInfoReply}
 */
proto.Common.PageInfoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.PageInfoReply;
  return proto.Common.PageInfoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.PageInfoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.PageInfoReply}
 */
proto.Common.PageInfoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.PageInfoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.PageInfoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.PageInfoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.PageInfoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.Common.PageInfoReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Common.PageInfoReply} returns this
 */
proto.Common.PageInfoReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.ErrorReply.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.ErrorReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.ErrorReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.ErrorReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.ErrorReply}
 */
proto.Common.ErrorReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.ErrorReply;
  return proto.Common.ErrorReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.ErrorReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.ErrorReply}
 */
proto.Common.ErrorReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Common.ErrorCodes} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.ErrorReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.ErrorReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.ErrorReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.ErrorReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ErrorCodes code = 1;
 * @return {!proto.Common.ErrorCodes}
 */
proto.Common.ErrorReply.prototype.getCode = function() {
  return /** @type {!proto.Common.ErrorCodes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Common.ErrorCodes} value
 * @return {!proto.Common.ErrorReply} returns this
 */
proto.Common.ErrorReply.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Common.ErrorReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.ErrorReply} returns this
 */
proto.Common.ErrorReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Common.ErrorReplyGin.prototype.toObject = function(opt_includeInstance) {
  return proto.Common.ErrorReplyGin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Common.ErrorReplyGin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.ErrorReplyGin.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filePath: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Common.ErrorReplyGin}
 */
proto.Common.ErrorReplyGin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Common.ErrorReplyGin;
  return proto.Common.ErrorReplyGin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Common.ErrorReplyGin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Common.ErrorReplyGin}
 */
proto.Common.ErrorReplyGin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Common.ErrorCodes} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Common.ErrorReplyGin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Common.ErrorReplyGin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Common.ErrorReplyGin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Common.ErrorReplyGin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ErrorCodes code = 1;
 * @return {!proto.Common.ErrorCodes}
 */
proto.Common.ErrorReplyGin.prototype.getCode = function() {
  return /** @type {!proto.Common.ErrorCodes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Common.ErrorCodes} value
 * @return {!proto.Common.ErrorReplyGin} returns this
 */
proto.Common.ErrorReplyGin.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.Common.ErrorReplyGin.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.ErrorReplyGin} returns this
 */
proto.Common.ErrorReplyGin.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.Common.ErrorReplyGin.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.ErrorReplyGin} returns this
 */
proto.Common.ErrorReplyGin.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_path = 4;
 * @return {string}
 */
proto.Common.ErrorReplyGin.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Common.ErrorReplyGin} returns this
 */
proto.Common.ErrorReplyGin.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.Common.BusinessType = {
  BUSINESS_NONE: 0,
  COMPANY: 1,
  PERSIONAL: 2
};

/**
 * @enum {number}
 */
proto.Common.EffectTool = {
  EFFACT_TOOL_NONE: 0,
  BEAUTIFUL_SKIN: 1
};

/**
 * @enum {number}
 */
proto.Common.ArInteractModule = {
  AR_INTERACT_MODULE_NONE: 0
};

/**
 * @enum {number}
 */
proto.Common.ArEditWindowModule = {
  AR_EDIT_WINDOW_MODULE_NONE: 0,
  UI_EDIT: 1
};

/**
 * @enum {number}
 */
proto.Common.ProgramState = {
  PROGRAM_STATE_NONE: 0,
  OFF_SALE: 1,
  ON_SALE: 2
};

/**
 * @enum {number}
 */
proto.Common.ErrorCodes = {
  ERRORCODES_NONE: 0,
  SUCCESS: 10000,
  INVAILD_TOKEN: 10001,
  PERMISSION_DENIED: 10002,
  PROGRAM_NOT_SUPPORT: 10003,
  CONTACT_LIMIT_REACHED: 10004,
  SEATS_LIMIT_REACHED: 10005,
  INVAILD_PARAM: 20001,
  UNKNOWN_ERROR: 20002,
  REPEATED_ERROR: 20003,
  DATA_NOT_FOUND: 20004,
  LOGIN_CHECK_ID_FAIL: 30001,
  UPLOAD_FILE_SIZE_INVALID: 40001,
  UPLOAD_FILE_TYPE_NOT_SUPPORT: 40002,
  MAIL_STATE_EXPIRE_ERROR: 50001,
  USER_EMAIL_INVAILD: 50002,
  MAIL_HEAVY_FREQUENTLY: 50003,
  REACH_DAILY_MAIL_LIMIT: 50004,
  EMAIL_IS_VALIDATED: 50005
};

/**
 * @enum {number}
 */
proto.Common.MailType = {
  MAIL_TYPE_NONE: 0,
  MAIL_TYPE_ADMIN: 1,
  MAIL_TYPE_CHILD: 2
};

/**
 * @enum {number}
 */
proto.Common.MailState = {
  MAIL_STATE_NONE: 0,
  MAIL_STATE_INVITED: 1,
  MAIL_STATE_EXPIRE: 2,
  MAIL_STATE_CANCEL: 3,
  MAIL_STATE_SUCCESS: 4
};

/**
 * @enum {number}
 */
proto.Common.UploadFileType = {
  UPLOAD_FILE_TYPE_NONE: 0,
  IMAGE: 1,
  THREE_D_FILE: 2
};

goog.object.extend(exports, proto.Common);
