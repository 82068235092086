import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import "antd/dist/antd.min.css";
import { CameraOutlined, UserOutlined, QuestionCircleOutlined, WarningOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { GlassTemplate } from "@/helper/ar_lib/component/ar_view/GlassTemplate";
import { EarringsTemplate } from "@/helper/ar_lib/component/ar_view/EarringsTemplate";
import { LensTemplate } from "@/helper/ar_lib/component/ar_view/LensTemplate";
import { HairTemplate } from "@/helper/ar_lib/component/ar_view/HairTemplate";

type selfProps = {
  template: GlassTemplate | EarringsTemplate | LensTemplate | undefined;
  hairTemplate: HairTemplate | undefined;
  arSwitch: boolean;
  arLoading: Function;
};

const BaseArViewerController: React.FC<selfProps> = function (props) {
  let template: GlassTemplate | EarringsTemplate | LensTemplate | undefined
  let hairTemplate: HairTemplate | undefined
  if (props.template !== undefined) {
    template = props.template;
  } else {
    hairTemplate = props.hairTemplate
  }
  const [arSwitch, setArSwitch] = useState<boolean>(false);
  const [isHair, setIsHair] = useState(false);

  useEffect(() => {
    if (props.hairTemplate !== undefined) {
      setIsHair(true)
    } else {
      return
    }
  }, [props.hairTemplate])

  useEffect(() => {
    setArSwitch(false);
  }, [props.arSwitch]);

  async function onClickSwitch(e: any) {
    if (e.currentTarget.value === "false") {
      // e.currentTarget.value = true;
      setArSwitch(true);
      if (props.template !== undefined) {
        props.arLoading(true)
        const ArValue = template!.OpenArMode(false);
        template?.SetEnablePan(false)
        template?.SetEnableRotate(false)
        template?.SetEnableZoom(false)
        ArValue.then((value) => {
          if (value === true) { props.arLoading(false) } else if (value === false) {
            props.arLoading(false)
            setArSwitch(false);
            template?.CloseArMode();
            Modal.warning({
              title: <h3>Attention Please:</h3>,
              icon: <WarningOutlined />,
              content: <div style={{textAlign:"center"}}><h6>Allow &quot;metarcommerce.com&quot; to use your camera</h6><h6>to experience the AR try-on effect.</h6></div>,
              okText: "OK",
              maskClosable: true,
              width: 500,
            });
          }
        }).catch(err => console.log(err.message));
      } else {
        props.arLoading(true)
        const ArValue = hairTemplate!.OpenArMode(false);
        ArValue?.then((value) => {
          if (value === true) { props.arLoading(false) } else if (value === false) {
            props.arLoading(false)
            setArSwitch(false);
            hairTemplate?.CloseArMode();
            Modal.warning({
              title: <h3>Attention Please:</h3>,
              icon: <WarningOutlined />,
              content: <div style={{textAlign:"center"}}><h6>Allow &quot;metarcommerce.com&quot; to use your camera</h6><h6>to experience the AR try-on effect.</h6></div>,
              okText: "OK",
              maskClosable: true,
              width: 500,
            });
          }
        }).catch(err => console.log(err.message));
      }
    } else if (e.currentTarget.value === "true") {
      // e.currentTarget.value = false;
      setArSwitch(false);
      template?.SetEnablePan(true)
      template?.SetEnableRotate(true)
      template?.SetEnableZoom(true)
      if (props.template !== undefined) {
        props.arLoading(false)
        template?.CloseArMode();
      } else {
        props.arLoading(false)
        hairTemplate?.CloseArMode();
      }
    }
  }

  function help() {
    Modal.info({
      title: <h3>How to:</h3>,
      content: (
        <>
          <h6> Left click to control rotation.</h6>
          <h6> Right click to control displacement.</h6>
          <h6> Wheel click to control zoom.</h6>
        </>
      ),
      okText: "OK",
      maskClosable: true,
      width: 500,
    });
  }

  function resetPosition(e: any) {
    if (props.template) {
      template!.ResetPosition();
    } else {
      return
    }
  }

  return (
    <>
      <div className="btn-ar-control">
        <Button
          icon={arSwitch ? <UserOutlined /> : <CameraOutlined />}
          type="primary"
          onClick={onClickSwitch}
          value={arSwitch.toString()}
        />
        {isHair ? (
          null
        ) : (
          <>
            <Button disabled style={{ visibility: "hidden" }} />
            {/* <Button
              className="btn-360view"
              disabled={arSwitch}
              icon={
                <img
                  src="/images/menuIcon/E1_03_icon_360view.svg"
                  alt=""
                  style={
                    !arSwitch
                      ? {}
                      : {
                          filter:
                            "invert(76%) sepia(9%) saturate(23%) hue-rotate(314deg) brightness(100%) contrast(81%)",
                        }
                  }
                />
              }
            /> */}
            <Button
              className="btn-reset"
              disabled={arSwitch}
              icon={
                <img
                  src="/images/menuIcon/E1_03_icon_reset.svg"
                  alt=""
                  style={
                    !arSwitch
                      ? {}
                      : {
                        filter:
                          "invert(76%) sepia(9%) saturate(23%) hue-rotate(314deg) brightness(100%) contrast(81%)",
                      }
                  }
                />
              }
              onClick={resetPosition}
            />
            <Button
              className="help-icon"
              icon={<QuestionCircleOutlined />}
              onClick={help}
            />
          </>
        )}
      </div>
    </>
  );
};

export default BaseArViewerController;
