import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./local/en.json";
import zh_tw from "./local/zh_tw.json";

const resources = {
  en: { ...en },
  zh_tw: { ...zh_tw },
};

let lng = "en";

i18next.use(initReactI18next).init({
  resources,
  lng: lng,
  interpolation: {
    escapeValue: false
  }
});

export default i18next;
