import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb, { ArContentTemplateEnum } from "@/proto/ArContent_pb";
import * as ArContentTemplate_pb from "@/proto/ArContentTemplate_pb";


interface IProjectEditContentModel {
  isloading: boolean;
  contentInfo:ArContent_pb.ArContentInfo.AsObject | undefined
}
export const ProjectEditContentStatus = createModel<RootModel>()({
  state: {
    isloading: true,
    contentInfo:undefined
  } as IProjectEditContentModel,
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
  },
  effects: (dispatch) => ({
    async load(callback: (value: string) => void) {},
    clearup () {
      dispatch.ProjectEditContentStatus.setState({data:true , type: SET_ISLOADING})
      dispatch.ProjectEditContentStatus.setState({data:undefined,type:SET_CONTENT_INFO})
    }
  }),
});

export const SET_ISLOADING = "isloading";
export const SET_CONTENT_INFO = "contentInfo";
