import * as ArContent_pb from "@/proto/ArContent_pb";

const template_image_info = [
  {
    name: ArContent_pb.ArContentTemplateEnum.TEMPLATE_EARRING,
    key: ArContent_pb.ArContentTemplateEnum.TEMPLATE_EARRING,
    src: "/images/project/E1_02_image_earing.jpg",
    title: "VIRTUAL TRY-ON FOR JEWELRY",
    text: "Open up AR try-on service to your customers-create digital competitive advantage for your jewelry brand and enhance your e-commerce.",
  },
  {
    name: ArContent_pb.ArContentTemplateEnum.TEMPLATE_HAIR,
    key: ArContent_pb.ArContentTemplateEnum.TEMPLATE_HAIR,
    src: "/images/project/E1_02_image_haircolor.jpg",
    title: "VIRTUAL TRY-ON FOR HAIR COLORING",
    text: "Change your hair color in realtime added augmented reality(AR) technology to brand for at-home hair coloring , website to let customers virtually sample new hair colors before placing an order. it works for any colour , long or short hair.",
  },
  {
    name: ArContent_pb.ArContentTemplateEnum.TEMPLATE_GLASSES,
    key: ArContent_pb.ArContentTemplateEnum.TEMPLATE_GLASSES,
    src: "/images/project/E1_02_image_eyewer.jpg",
    title: "VIRTUAL TRY-ON FOR EYEWEAR",
    text: "Do you need a new eyewear and want to see how they look before making purchase? With METAR commerce Virtual Try On tool, tou can try on different eyewear online. You'll be able to see whether the frames suit you. it's so realistic and great fun too!",
  },
  {
    name: ArContent_pb.ArContentTemplateEnum.TEMPLATE_CONTECT_LENSES,
    key: ArContent_pb.ArContentTemplateEnum.TEMPLATE_CONTECT_LENSES,
    src: "/images/project/E1_02_image_lenscolor.jpg",
    title: "VIRTUAL TRY-ON FOR EYE COLOR",
    text: "Do you need a new eyewear and want to see how they look before making purchase? With METAR commerce Virtual Try On tool, tou can try on different eyewear online. You'll be able to see whether the frames suit you. it's so realistic and great fun too!",
  },
];

const categoryList = [
  { id: ArContent_pb.ArContentCategoryEnum.CATRGORY_HEAD, name: "HEAD"},
  { id: ArContent_pb.ArContentCategoryEnum.CATRGORY_FACE, name: "FACE"}
]

const typeList = [
  { id: ArContent_pb.ArContentTypeEnum.TYPE_EAR,name: "EAR"},
  { id: ArContent_pb.ArContentTypeEnum.TYPE_HAIR,name: "HAIR"},
  { id: ArContent_pb.ArContentTypeEnum.TYPE_GLASSES,name: "GLASSES"},
  { id: ArContent_pb.ArContentTypeEnum.TYPE_LENS,name: "LENS"},
]

const templateList = [
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_EARRING,name: "EARRING" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_GLASSES,name: "GLASSES" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_CONTECT_LENSES,name: "LENSES" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_HAIR,name: "HAIR" },
]

const allProduct = [
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_EARRING,name: "EARRING" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_GLASSES,name: "GLASSES" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_CONTECT_LENSES,name: "LENS" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_HAIR,name: "HAIR" },
]

//公測開放的template
const product = [
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_GLASSES,name: "GLASSES" },
  { id: ArContent_pb.ArContentTemplateEnum.TEMPLATE_EARRING,name: "EARRING" }
]

export { template_image_info , categoryList , typeList , templateList , allProduct , product };