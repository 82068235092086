import * as ArContentTemplate_pb from "@/proto/ArContentTemplate_pb";

export enum GlassesTemplatePosition {
  ALL = "all",
  FRONT = "front",
  LEFT = "left",
  RIGHT = "right",
}

export enum EarringTemplatePosition {
  OVERALL = "overall",
  TOP = "top",
  MID = "mid",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
}

export enum LensTemplatePosition {
  POS = "position",
  ROT = "rotation",
  SCALE = "scale",
  RADIUS = "radius",
  LEFT = "left",
  RIGHT = "right",
}

