import { RpcError } from "grpc-web";
import { ErrorCodes } from "../proto/Common_pb";
import { useNavigate } from "react-router-dom";
import routes from "../routers";
import localforage from "localforage";

const router_path = routes.router_path

export function loginErrorMsg(result: RpcError) {
  if (result) {
    const resultCode = result.message
    const errorCode = JSON.parse(resultCode);
    switch (errorCode.code) {
      case ErrorCodes.INVAILD_TOKEN:
        return "This account has not been registered."
      case ErrorCodes.LOGIN_CHECK_ID_FAIL:
        return "Account or password incorrect.";
      case ErrorCodes.INVAILD_PARAM:
        return "Account or password format is incorrect.";
      // case ErrorCodes.REPEATED_ERROR:
      //   return "This account is already registered.";
      default:
        return "Error";
    }
  }
}

export function sendEmailErrorMsg(result: RpcError) {
  if (result) {
    const resultCode = result.message
    const errorCode = JSON.parse(resultCode)
    switch (errorCode.code) {
      case ErrorCodes.USER_EMAIL_INVAILD:
        return "Account not exist.";
      case ErrorCodes.MAIL_HEAVY_FREQUENTLY:
        return "You can send again after an hour.";
      case ErrorCodes.REACH_DAILY_MAIL_LIMIT:
        return "Send Email limit reached.";
      case ErrorCodes.EMAIL_IS_VALIDATED:
        return "Your account has been verified.";
      default:
        return "Error";
    }
  }
}

export function registerErrorMsg(result: RpcError) {
  if (result) {
    const resultCode = result.message
    const errorCode = JSON.parse(resultCode);
    switch (errorCode.code) {
      case ErrorCodes.INVAILD_PARAM:
        return "Password length must be 6~100.";
      case ErrorCodes.REPEATED_ERROR:
        return "Account already registered.";
      default:
        return "Error";
    }
  }
}

export function resultErrorMsg(result: RpcError) {
  const nav = useNavigate()
  if (result) {
    const resultCode = result.message
    const errorCode = JSON.parse(resultCode)
    switch (errorCode.code) {
      case ErrorCodes.INVAILD_TOKEN:
        localforage.clear();
        nav(router_path.login);
        break;
      default:
        return "Error";
    }
  }
}

export function inputErrorMsg(result: RpcError) {
  if (result) {
    const resultCode = result.message
    let errorCode = JSON.parse(resultCode);
    switch (errorCode.code) {
      // case ErrorCodes.INVAILD_TOKEN:
      //   localforage.clear();
      //   router.push(router_path.login);
      //   break;
      case ErrorCodes.INVAILD_PARAM:
        return "Length must not exceed 100."
      default:
        return "Error";
    }
  }
}

export function editPasswordErrorMsg(result: RpcError) {
  if (result) {
    let errorCode = JSON.parse(result.message);
    switch (errorCode.code) {
      // case ErrorCodes.INVAILD_TOKEN:
      //   localforage.clear();
      //   router.push(router_path.login);
      //   break;
      case ErrorCodes.INVAILD_PARAM:
        return "Password length must be 6~100."
      case ErrorCodes.UNKNOWN_ERROR:
        return "The current password is incorrect."
      default:
        return "Error";
    }
  }
}
