import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import routers from "./routers";
import i18n from "./i18n";
import reportWebVitals from './reportWebVitals';
//* 從store引入initial standeard code
import {store} from "./store";
import './scss/_variables.scss';
import "./scss/globals.scss";
import "./scss/sing-up.scss";
import "./scss/send-email.scss";
import "./scss/confirm-register.scss";
import "antd/dist/antd.min.css";


// react 17的寫法會報錯

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <I18nextProvider i18n={i18n}>
//         <RouterProvider router={routers.routers} />
//       </I18nextProvider>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// 改成 react 18 的 createRoot

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode> 
    {/* 在最外層放進Store */}
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={routers.routers} />
      </I18nextProvider>
    </Provider>
  </React.StrictMode> 
);
reportWebVitals();