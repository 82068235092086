import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";


export const SendEmailStatus = createModel<RootModel>()({
    state: {
        userId:"",
        isLoading: true,
    },
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        }
    },
    effects: (dispatch) =>({
        clearup () {
            dispatch.SendEmailStatus.setState({data:'',type:SET_USER_ID})
            dispatch.SendEmailStatus.setState({data:true, type: SET_ISLOADING})
        }
    })
});

export const SET_USER_ID = "userI";
export const SET_ISLOADING = "isLoading";