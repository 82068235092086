import React, { useState, useEffect, useRef, useReducer } from "react";
import "antd/dist/antd.min.css";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  InputNumber,
  Segmented,
  Switch,
  InputNumberProps,
} from "antd";
import {
  WarningOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import localforage from "localforage";
import { AuthReply } from "@/proto/Auth_pb";
import * as ArContent_pb from "@/proto/ArContent_pb";
import * as arContentApi from "@/helpers/arContentApi";
import * as Common_pb from "@/proto/Common_pb";
import * as ArContentTemplate_pb from "@/proto/ArContentTemplate_pb";
import routes from "@/routers";
import { LensTemplate } from "@/helper/ar_lib/component/ar_view/LensTemplate";
import { ILensAction, ITransform } from "@/components/templateInterface";
import { LensTemplatePosition } from "./Helpers/SetTemplate";
import BaseArViewerController from "./Helpers/BaseArViewerController";
import EditUploadImage from "./Helpers/EditUploadImageTemplate";
import Loading from "./Loading";
import moment from "moment";
import { resultErrorMsg } from "@/helpers/errorCodeMsg";
import { RpcError } from "grpc-web";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "@/store";
import {
  SET_ARVIEW,
  SET_AR_LOADING,
  SET_COLOR_CODE,
  SET_COMPONENT_SIZE,
  SET_CONTENTINFO,
  SET_IMAGE_ID,
  SET_IMAGE_PATH,
  SET_IMAGE_UPLOAD_ID,
  SET_ISLOADING,
  SET_IS_Submit,
  SET_LOAD_ARVIEW,
  SET_TOKEN,
} from "@/model/component/editLens";
const { error } = Modal;

export interface ITemplateLensTransform {
  left: ITransform;
  right: ITransform;
  radius: number;
}

function transformReducer(state: ITemplateLensTransform, action: ILensAction) {
  const { type, payload } = action;
  switch (type) {
    case LensTemplatePosition.LEFT:
    case LensTemplatePosition.RIGHT:
      return { ...state,[type]:payload as ITransform, value: (state[type] = payload as ITransform) };
    case LensTemplatePosition.RADIUS:
      return {
        ...state,
        [LensTemplatePosition.RADIUS]: payload as number,
        value: (state[type] = payload as number),
      };
    default:
      return state;
  }
}

export const defaultTransform: ITemplateLensTransform = {
  left: {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 1, y: 1, z: 1 },
  },
  right: {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 1, y: 1, z: 1 },
  },
  radius: 1,
};

let template: LensTemplate | undefined = undefined;
const templateLens = new ArContentTemplate_pb.TemplateContactLenses();
const setLeftTransform = new Common_pb.Transform();
const setLeftPositon = new Common_pb.Vector();
const setLeftRotation = new Common_pb.Vector();
const setLeftScale = new Common_pb.Vector();
const setRightTransform = new Common_pb.Transform();
const setRightPositon = new Common_pb.Vector();
const setRightRotation = new Common_pb.Vector();
const setRightScale = new Common_pb.Vector();

const EditLens: React.FC = function () {
  const nav = useNavigate();
  const params = useParams();
  const router_path = routes.router_path;
  const contentId = params.pid;
  const [form] = Form.useForm();
  const modelViewDom = useRef<HTMLDivElement>(null);
  const editLenseStatus = useSelector(
    (state: RootState) => state.editLenseStatus
  );
  const {
    arLoading,
    arView,
    loadArView,
    isSubmit,
    token,
    isLoading,
    imageId,
    imagePath,
    imageUploadId,
    arSwitch,
    colorCode,
    contentInfo,
    componentSize,
    state
  } = editLenseStatus;
  const dispatchStore = useDispatch<Dispatch>();

  useEffect(() => {
    const load = async () => {
      if (contentId === undefined) {
        return;
      } else {
        const auth = await localforage.getItem<AuthReply.AsObject>(
          "admin_auth"
        );
        if (!auth) {
          await localforage.clear();

          nav(router_path.login);
        } else {
          const expiresTime = moment(auth.expireDate);
          if (moment().isAfter(expiresTime)) {
            await localforage.clear();
            nav(router_path.login);
          } else {
            const resultData = await arContentApi.getArContentApi(
              auth!.token,
              contentId!
            );
            try {
              if (resultData instanceof ArContent_pb.GetArContentReply) {
                const content = resultData.toObject().data;
                // console.log(content)
                dispatchStore.editLenseStatus.setState({
                  type: SET_CONTENTINFO,
                  data: content!,
                });
                dispatchStore.editLenseStatus.setState({
                  type: SET_TOKEN,
                  data: auth!.token,
                });

                const templateData =
                  ArContentTemplate_pb.TemplateContactLenses.deserializeBinary(
                    content!.templateSetting as Uint8Array
                  );
                if (templateData) {
                  dispatchStore.editLenseStatus.setState({
                    type: SET_IMAGE_ID,
                    data: templateData.toObject().imageName,
                  });
                  dispatchStore.editLenseStatus.setState({
                    type: SET_IMAGE_PATH,
                    data: templateData.toObject().imagePath,
                  });
                  dispatchStore.editLenseStatus.setState({
                    type: SET_COLOR_CODE,
                    data: templateData.toObject().colorCode,
                  });
                  const leftTemplateData_pos = templateData
                    .getLeft()!
                    .getPosition() as Common_pb.Vector;
                  const leftTemplateData_rot = templateData
                    .getLeft()!
                    .getRotation() as Common_pb.Vector;
                  const leftTemplateData_sca = templateData
                    .getLeft()!
                    .getScale() as Common_pb.Vector;
                  const leftTemplateData_all = {
                    position: {
                      x: +leftTemplateData_pos.getX(),
                      y: +leftTemplateData_pos.getY(),
                      z: +leftTemplateData_pos.getZ(),
                    },
                    rotation: {
                      x: +leftTemplateData_rot.getX(),
                      y: +leftTemplateData_rot.getY(),
                      z: +leftTemplateData_rot.getZ(),
                    },
                    scale: {
                      x: +leftTemplateData_sca.getX(),
                      y: +leftTemplateData_sca.getY(),
                      z: +leftTemplateData_sca.getZ(),
                    },
                  };

                  dispatchStore.editLenseStatus.transformReducer({
                    type: LensTemplatePosition.LEFT,
                    payload: leftTemplateData_all,
                  });
                  // console.log(state)
                  const rightTemplateData_pos = templateData
                    .getRight()!
                    .getPosition() as Common_pb.Vector;
                  const rightTemplateData_rot = templateData
                    .getRight()!
                    .getRotation() as Common_pb.Vector;
                  const rightTemplateData_sca = templateData
                    .getRight()!
                    .getScale() as Common_pb.Vector;
                  const rightTemplateData_all = {
                    position: {
                      x: +rightTemplateData_pos.getX(),
                      y: +rightTemplateData_pos.getY(),
                      z: +rightTemplateData_pos.getZ(),
                    },
                    rotation: {
                      x: +rightTemplateData_rot.getX(),
                      y: +rightTemplateData_rot.getY(),
                      z: +rightTemplateData_rot.getZ(),
                    },
                    scale: {
                      x: +rightTemplateData_sca.getX(),
                      y: +rightTemplateData_sca.getY(),
                      z: +rightTemplateData_sca.getZ(),
                    },
                  };
                  dispatchStore.editLenseStatus.transformReducer({
                    type: LensTemplatePosition.RIGHT,
                    payload: rightTemplateData_all,
                  });
                
                  dispatchStore.editLenseStatus.setState({
                    type: SET_LOAD_ARVIEW,
                    data: true,
                  });
                  let radiusValue = Number(templateData.getRadius());
                  dispatchStore.editLenseStatus.transformReducer({
                    type: "radius",
                    payload: radiusValue,
                  });
                  dispatchStore.editLenseStatus.setState({data: false,type:SET_AR_LOADING})
                }
              } else {
                throw new Error();
              }
            } catch (e) {
              resultErrorMsg(resultData as RpcError);
            }
          }
        }
      }
    };
    load();
    return function cleanUp() {
      if (template) {
        template.CloseArMode();
        template = undefined;
        dispatchStore.editLenseStatus.clearup()
      }
    };
  }, [params]);

  useEffect(() => {
    if (modelViewDom.current && loadArView) {
      const dom = modelViewDom.current;
      template = new LensTemplate(dom, {
        width: dom.clientWidth,
        height: dom.clientHeight,
      });
      template.LoadTemplate(
        false,
        {
          radius: state.radius,
          right: {
            position: state.right.position,
            rotation: state.right.rotation,
            scale: state.right.scale,
          },
          left: {
            position: state.left.position,
            rotation: state.left.rotation,
            scale: state.left.scale,
          },
        },
        async (status) => {
          if (status === "done") {
            if (imagePath) {
              await template!.ChangeTexture(
                imagePath,
                LensTemplatePosition.LEFT
              );
              await template!.ChangeTexture(
                imagePath,
                LensTemplatePosition.RIGHT
              );
            }
            await  dispatchStore.editLenseStatus.setState({
              type: SET_LOAD_ARVIEW,
              data: false,
            });
          } else {
            dispatchStore.editLenseStatus.setState({
              type: SET_ARVIEW,
              data: false,
            });
          }
          dispatchStore.editLenseStatus.setState({
            type: SET_ISLOADING,
            data: false,
          });
        }
      );
    }
  }, [loadArView]);


  useEffect(() => {
    template?.ChangeTexture(imagePath, LensTemplatePosition.LEFT);
    template?.ChangeTexture(imagePath, LensTemplatePosition.RIGHT);
  }, [imageUploadId]);

  useEffect(() => {
    if (template) {
      template.SetTransform(
        "right",
        state.right.position,
        state.right.rotation,
        state.right.scale
      );
      template.SetTransform(
        "left",
        state.left.position,
        state.left.rotation,
        state.left.scale
      );
      template.SetRadius(state.radius);
    }
  }, [state]);

  function onChangeValue(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value, dataset } = evt.currentTarget;
    switch (name) {
      case LensTemplatePosition.LEFT:
      case LensTemplatePosition.RIGHT:
        let changeValue = state[name];
        if (dataset.pos) {
          if (
            dataset.pos === "x" ||
            dataset.pos === "y" ||
            dataset.pos === "z"
          ) {
            changeValue.position[dataset.pos] = +value;
          }
        } else if (dataset.rot) {
          if (
            dataset.rot === "x" ||
            dataset.rot === "y" ||
            dataset.rot === "z"
          ) {
            changeValue.rotation[dataset.rot] = +value;
          }
        } else if (dataset.scale) {
          if (
            dataset.scale === "x" ||
            dataset.scale === "y" ||
            dataset.scale === "z"
          ) {
            changeValue.scale[dataset.scale] = +value;
          }
        }
        dispatchStore.editLenseStatus.transformReducer({ type: name, payload: changeValue });
        break;
      case "radius":
        dispatchStore.editLenseStatus.transformReducer({ type: name, payload: +Number(value) });
        break;
      default:
        console.error("error name");
        break;
    }
  }
  interface dataset {
    rot?: "x" | "y" | "z";
    scale?: "x" | "y" | "z";
    pos?: "x" | "y" | "z";
  }
  function numberOnChange(
    name: string,
    value: number | null,
    dataset?: dataset
  ) {
    let num = value ? value : 0;
    switch (name) {
      case LensTemplatePosition.LEFT:
      case LensTemplatePosition.RIGHT:
        let changeValue = state[name];
        if (dataset && dataset.pos) {
          if (
            dataset.pos === "x" ||
            dataset.pos === "y" ||
            dataset.pos === "z"
          ) {
            changeValue.position[dataset.pos] = +num;
          }
        } else if (dataset && dataset.rot) {
          if (
            dataset.rot === "x" ||
            dataset.rot === "y" ||
            dataset.rot === "z"
          ) {
            changeValue.rotation[dataset.rot] = +num;
          }
        } else if (dataset && dataset.scale) {
          if (
            dataset.scale === "x" ||
            dataset.scale === "y" ||
            dataset.scale === "z"
          ) {
            changeValue.scale[dataset.scale] = +num;
          }
        }
        dispatchStore.editLenseStatus.transformReducer({ type: name, payload: changeValue });
        break;
      case "radius":
        dispatchStore.editLenseStatus.transformReducer({ type: name, payload: +Number(value) });
        break;
      default:
        console.error("error name");
        break;
    }
  }

  function handleCancel() {
    // template!.CloseArMode();
    // setArSwitch(!arSwitch);
    dispatchStore.editLenseStatus.setState({data: false , type: SET_AR_LOADING});
    Modal.confirm({
      title: <h3>Back to project page?</h3>,
      icon: <WarningOutlined />,
      content: <h6>Changes you made may not be saved.</h6>,
      okText: "YES",
      cancelText: "Cancel",
      maskClosable: true,
      width: 500,
      async onOk() {
        nav(router_path.arProject.edit.replace(":pid", contentId!));
      },
    });
  }

  function genInputTransform(name: string, currentState: ITransform) {
    return (
      <>
        <div>
          <span className="action-button-title">Position</span>
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.position.x}
            name={name}
            data-pos={"x"}
            onChange={(value) => {
              numberOnChange(name, value, { pos: "x" });
            }}
            step="0.01"
          />
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.position.y}
            name={name}
            data-pos={"y"}
            onChange={(value) => {
              numberOnChange(name, value, { pos: "y" });
            }}
            step="0.01"
          />
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.position.z}
            name={name}
            data-pos={"z"}
            onChange={(value) => {
              numberOnChange(name, value, { pos: "z" });
            }}
            step="0.01"
          />
        </div>
        <div>
          <span className="action-button-title">Rotation</span>
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.rotation.x}
            name={name}
            data-rot={"x"}
            onChange={(value) => {
              numberOnChange(name, value, { rot: "x" });
            }}
            step="0.01"
          />
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.rotation.y}
            name={name}
            data-rot={"y"}
            onChange={(value) => {
              numberOnChange(name, value, { rot: "y" });
            }}
            step="0.01"
          />
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.rotation.z}
            name={name}
            data-rot={"z"}
            onChange={(value) => {
              numberOnChange(name, value, { rot: "z" });
            }}
            step="0.01"
          />
        </div>
        <div>
          <span className="action-button-title">scale</span>
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.scale.x}
            name={name}
            data-scale={"x"}
            onChange={(value) => {
              numberOnChange(name, value, { scale: "x" });
            }}
            step="0.1"
          />
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.scale.y}
            name={name}
            data-scale={"y"}
            onChange={(value) => {
              numberOnChange(name, value, { scale: "y" });
            }}
            step="0.1"
          />
          <InputNumber
            className="action-value"
            type={"number"}
            value={currentState.scale.z}
            name={name}
            data-scale={"z"}
            onChange={(value) => {
              numberOnChange(name, value, { scale: "z" });
            }}
            step="0.1"
          />
        </div>
      </>
    );
  }

  function handleSelect(checked: boolean) {
    function setComponentSize(
      value: LensTemplatePosition.LEFT | LensTemplatePosition.RIGHT
    ) {
      dispatchStore.editLenseStatus.setState({
        type: SET_COMPONENT_SIZE,
        data: value,
      });
    }
    if (checked === true) {
      setComponentSize(LensTemplatePosition.RIGHT);
    } else if (checked === false) {
      setComponentSize(LensTemplatePosition.LEFT);
    }
  }

  function positionComponent(value: LensTemplatePosition) {
    if (contentInfo) {
      switch (value) {
        case LensTemplatePosition.LEFT:
          return genInputTransform(componentSize, state.left);
        case LensTemplatePosition.RIGHT:
          return genInputTransform(componentSize, state.right);
      }
    }
  }

  async function onFinish() {
    // template!.CloseArMode();
    // setArSwitch(!arSwitch);
    dispatchStore.editLenseStatus.setState({data: false , type: SET_AR_LOADING});

    setLeftPositon
      .setX(state.left.position.x.toString())
      .setY(state.left.position.y.toString())
      .setZ(state.left.position.z.toString());
    setLeftTransform.setPosition(setLeftPositon);
    setLeftRotation
      .setX(state.left.rotation.x.toString())
      .setY(state.left.rotation.y.toString())
      .setZ(state.left.rotation.z.toString());
    setLeftTransform.setRotation(setLeftRotation);
    setLeftScale
      .setX(state.left.scale.x.toString())
      .setY(state.left.scale.y.toString())
      .setZ(state.left.scale.z.toString());
    setLeftTransform.setScale(setLeftScale);
    setRightPositon
      .setX(state.right.position.x.toString())
      .setY(state.right.position.y.toString())
      .setZ(state.right.position.z.toString());
    setRightTransform.setPosition(setRightPositon);
    setRightRotation
      .setX(state.right.rotation.x.toString())
      .setY(state.right.rotation.y.toString())
      .setZ(state.right.rotation.z.toString());
    setRightTransform.setRotation(setRightRotation);
    setRightScale
      .setX(state.right.scale.x.toString())
      .setY(state.right.scale.y.toString())
      .setZ(state.right.scale.z.toString());
    setRightTransform.setScale(setRightScale);
    templateLens
      .setImageName(imageId)
      .setImagePath(imagePath)
      .setImageUploadName(imageUploadId)
      .setColorCode(colorCode)
      .setLeft(setLeftTransform)
      .setRight(setRightTransform)
      .setRadius(state.radius.toString());

    if (imagePath) {
      const result = await arContentApi.updateArContentTemplateApi(
        token,
        contentId!,
        templateLens.serializeBinary()
      );
      try {
        if (result instanceof ArContent_pb.UpdateArContentTemplateReply) {
          const resultData = result.toObject();
          dispatchStore.editLenseStatus.setState({
            type: SET_ISLOADING,
            data: true,
          });
          dispatchStore.editLenseStatus.setState({
            type: SET_IS_Submit,
            data: true,
          });
          const path = router_path.arProject.editViewer.replace(
            ":pid",
            contentInfo!.arContentId
          );
          if (resultData) {
            nav(path);
          } else {
            throw new Error();
          }
        } else {
          dispatchStore.editLenseStatus.setState({
            type: SET_IS_Submit,
            data: false,
          });
          throw new Error();
        }
      } catch (e) {
        resultErrorMsg(result as RpcError);
      }
    } else {
      error({
        title: <h3>Error</h3>,
        icon: <CloseCircleOutlined />,
        content: <h6>Please upload three images.</h6>,
        okText: "OK",
        maskClosable: true,
        width: 500,
      });
      return;
    }
  }

  const setImageUploadId = (value: string) => {
    dispatchStore.editLenseStatus.setState({
      type: SET_IMAGE_UPLOAD_ID,
      data: value,
    });
  };

  const setImagePath = (value: string) => {
    dispatchStore.editLenseStatus.setState({
      type: SET_IMAGE_PATH,
      data: value,
    });
  };

  return (
    <>
      <div className="edit-ar-layout">
        {/* Left side */}
        <Form form={form} onFinish={onFinish} className="edit-ar-form">
          <div className="edit-ar-left">
            {/* 選擇ABC檔案圖片 */}
            <div className="edit-layout">
              <p className="edit-title">Upload Images</p>
              <p className="edit-hint">
                Choose your .png or .jpg file (File size 1024 x 1024 pixels and
                must not exceed 1MB)
              </p>
              <div className="upload-image-group">
                <div className="upload-image-layout">
                  <p className="upload-image-title">Image</p>
                  <EditUploadImage
                    fileList={imagePath}
                    token={token}
                    setImageFileId={setImageUploadId}
                    setImageFilePath={setImagePath}
                    showDelete={false}
                    showDeleteColor={""}
                  />
                </div>
              </div>
            </div>

            <Divider
              className="divider"
              style={{ display: imagePath || imageId ? "" : "none" }}
            />

            {/* 調整ABC */}
            <div
              className="edit-modifyModel-layout"
              style={{ display: imagePath || imageId ? "" : "none" }}
            >
              <p className="edit-title">Modify Model</p>
              <p className="edit-hint">
                Select your image and modify the value to change model position.
              </p>

              {/* 調整ABC position */}
              <Form.Item className="action-button-from">
                <Form.Item className="action-title-group">
                  <span className="select-direction-title">Left</span>
                  <Switch onChange={handleSelect} />
                  <span className="select-direction-title">Right</span>
                </Form.Item>
                <Form.Item className="action-title-group">
                  <span className="action-title"> </span>
                  <div className="action-title">Left / Right</div>
                  <div className="action-title">Up / Down</div>
                  <div className="action-title">Front / Back</div>
                </Form.Item>
                <Form.Item
                  // initialValue={contentInfo}
                  className="action-title-group"
                >
                  <Form.Item key={componentSize}>
                    {positionComponent(componentSize)}
                  </Form.Item>
                  <Form.Item className="action-button-from">
                    <div>
                      <span style={{ fontWeight: "500" }}>G. DIA</span>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          margin: "0 15px 0 5px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        (graphic diameter){" "}
                      </span>
                      <InputNumber
                        className="action-value"
                        type={"number"}
                        value={state.radius}
                        name={"radius"}
                        onChange={(value) => numberOnChange("radius", value)}
                        step={0.1}
                      />
                    </div>
                  </Form.Item>
                </Form.Item>
              </Form.Item>
            </div>
          </div>

          {/* Right side */}
          <div className="edit-ar-right">
            {/* preview */}
            <div className="preview-group">
              <BaseArViewerController
                template={template}
                hairTemplate={undefined}
                arSwitch={arSwitch}
                arLoading={(value: boolean) => {
                  dispatchStore.editLenseStatus.setState({
                    type: SET_AR_LOADING,
                    data: value,
                  });
                }}
              />
              <div className="ar-preview">
                {arLoading ? (
                  <div className="preview-loading">
                    <LoadingOutlined
                      style={{ fontSize: "2rem", color: "white" }}
                    />
                  </div>
                ) : arView ? null : (
                  <div className="preview-loading">
                    <p style={{ fontSize: "1rem", color: "white" }}>
                      Open AR mode error
                    </p>
                  </div>
                )}
                <div className="preview" ref={modelViewDom}></div>
              </div>
            </div>
            {/* 微調的 icon */}
            {/* buttons */}
            <div className="button-group">
              <Button
                className="btn-cancel"
                onClick={handleCancel}
                shape="round"
                size="large"
              >
                Back
              </Button>
              <Button
                className="btn-next"
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                loading={isSubmit}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
      {isLoading ? <Loading /> : null}
    </>
  );
};

export default EditLens;
