import {init, RematchDispatch, RematchRootState} from "@rematch/core";
// 把RootModel 從 '/model/index.ts' 裡面把所有的model引入進來
// '@/model'是前工程師習慣的引入方式pathname rule
import { models, RootModel } from "@/model";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "redux",
    storage,
    whitelist: ['pageStatus'],
};

export const store = init<RootModel>({
    models,
    plugins: [persistPlugin(persistConfig)]
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

// 以上都是一開始的Standard code