import React, { lazy, Suspense, useEffect, useState } from "react";
import localforage from "localforage";
import { AuthReply } from "@/proto/Auth_pb";
import moment from "moment";
import routers from "@/routers";
import { useNavigate } from "react-router-dom";

interface ILazyLoadRoutes {
  componentName: string;
}

const LazyLoadRoutes: React.FC<ILazyLoadRoutes> = ({ componentName }) => {
  const LazyElement = lazy(() => import(`../view/${componentName}.tsx`));
  const [state, setState] = useState<Boolean>(false);
  const nav = useNavigate();
  const unprotect_router = routers.unprotect_router;
  const load = async () => {
    const auth = await localforage.getItem<AuthReply.AsObject>("admin_auth");
    if (auth) {
      const expiresTime = moment(auth?.expireDate);

      if (!moment().isBefore(expiresTime)) {
        await nav(routers.router_path.login);
      }
    } else {
      nav(routers.router_path.login);
    }
  };

  useEffect(() => {
    if (
      unprotect_router.indexOf(componentName) === -1 &&
      !componentName.includes("arViewer/Index")
    ) {
      load();
    }
    setState(true);
  }, []);

  return (
    <Suspense fallback="Loading...">{state ? <LazyElement /> : null}</Suspense>
  );
};

export default LazyLoadRoutes;
