import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";

export const pageStatus = createModel<RootModel>()({
    state: {
        isLoading: false
    },
    reducers: {
        setIsLoading(state, payload: boolean) {
            return { ...state, isLoading: payload};
        }
    },
    effects: (dispatch) => ({})
});