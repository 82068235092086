import * as Program_pb from "../proto/Program_pb";
import { ProgramServicesClient } from "../proto/WebServicesServiceClientPb";
import { RpcError } from "grpc-web";
const programServicesClient = new ProgramServicesClient(`${process.env.REACT_APP_PUBLIC_API_HOST}`);

export async function createProgramApi(token:string , model:Program_pb.ProgramModel | undefined):Promise<Program_pb.CreateProgramReply | RpcError>{
    const req = new Program_pb.CreateProgramRequest()
    req.setModel(model)
    try {
        return await programServicesClient.createProgram(req ,{"authorization":token})
    }catch (err) {
        return err as RpcError;
    }
}

export async function getProgramListApi(token:string):Promise<Program_pb.GetProgramListReply | RpcError>{
    const req = new Program_pb.CreateProgramRequest()
    try {
        return await programServicesClient.getProgramList(req ,{"authorization":token})
    }catch (err) {
        return err as RpcError;
    }
}

export async function getFreeProgramApi(): Promise<Program_pb.GetFreeProgramReply | RpcError>{
  const req = new Program_pb.GetFreeProgramRequest()
  try {
    return await programServicesClient.getFreeProgram(req, null)
  }catch (err) {
    return err as RpcError;
  }
}