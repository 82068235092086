import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import {
  IHairAction,
  ILensAction,
  ITransform,
} from "@/components/templateInterface";
import { ITemplateHairSetting, defaultHairColor } from "@/components/EditHair";

export interface IEditHairStatus {
  isSubmit: boolean;
  loadArView: boolean;
  token: string;
  isLoading: boolean;
  arSwitch: boolean;
  colorCode: string;
  arLoading: boolean;
  arView: boolean;
  hairPickerColor: string;
  hairPickerColorHEX: string;
  hairPickerColorB: number;
  hairPickerColorG: number;
  hairPickerColorR: number;
  state: ITemplateHairSetting;
}

export const editHairStatus = createModel<RootModel>()({
  state: {
    isLoading: true,
    isSubmit: false,
    loadArView: false,
    token: "",
    hairPickerColor: "#000000",
    hairPickerColorHEX: "#000000",
    hairPickerColorB: 0,
    hairPickerColorG: 0,
    hairPickerColorR: 0,
    arSwitch: false,
    arLoading: true,
    arView: true,
    state: defaultHairColor,
  } as IEditHairStatus,
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      // console.log(payload)
      return { ...state, [type]: data };
    },
    hairSettingReducer(state, action: IHairAction) {
      const { type, payload } = action;
      switch (type) {
        case "color":
        case "feather":
        case "softness":
          return {
            ...state,
            state: {
              ...state.state,
              [type]: payload,
            },
          };
        default:
          return state;
      }
    },
  },
  effects: (dispatch) => ({
    clearup() {
      dispatch.editHairStatus.setState({ data: true, type: SET_ISLOADING });
      dispatch.editHairStatus.setState({ data: true, type: SET_AR_LOADING });
      dispatch.editHairStatus.setState({ data: false, type: SET_LOAD_ARVIEW });
      dispatch.editHairStatus.setState({ data: false, type: SET_IS_SUBMIT });
    },
  }),
});

export const SET_TOKEN = "token";
export const SET_IS_SUBMIT = "isSubmit";
export const SET_CONTENTINFO = "contentInfo";
export const SET_ARVIEW = "arView";
export const SET_AR_LOADING = "arLoading";
export const SET_LOAD_ARVIEW = "loadArView";
export const SET_ISLOADING = "isLoading";
export const SET_STATE = "state";
export const SET_HAIR_PICKER_COLOR = "hariPickerColor";
export const SET_HAIR_PICKER_COLOR_HEX = "hairPickerColorHEX";
export const SET_HAIR_PICKER_COLOR_B = "hairPickerColorB";
export const SET_HAIR_PICKER_COLOR_G = "hairPickerColorG";
export const SET_HAIR_PICKER_COLOR_R = "hairPickerColorR";
