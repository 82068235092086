import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import * as ArContentInfo from "@/components/templateInfo/template_image_info";
import localforage from "localforage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import routers from "@/routers";
import { AuthReply } from "@/proto/Auth_pb";
import * as arContentApi from "@/helpers/arContentApi";
import { resultErrorMsg } from "@/helpers/errorCodeMsg";
import { RpcError } from "grpc-web";
interface ICreateProjectModel {
  isloading: boolean;
  isSubmit: boolean;
  token: string;
  categoryList: any[];
  categoryValue: ArContent_pb.ArContentCategoryEnum | undefined;
  typeList: any[] | undefined;
  typeValue: ArContent_pb.ArContentTypeEnum | undefined;
  templateList: any[] | undefined;
  templateId: number | undefined;
  templateValue: ArContent_pb.ArContentTemplateEnum | undefined;
  imageFileId: string | undefined;
  tags: string[];
  tagsValue: string;
  tagTipShow: boolean;
  showProduct: { id: ArContent_pb.ArContentTemplateEnum; name: string }[];
}

export const CreateProjectStatus = createModel<RootModel>()({
  state: {
    isloading: false,
    isSubmit: false,
    token: "",
    categoryList: [],
    categoryValue: undefined,
    typeList: undefined,
    typeValue: undefined,
    templateList: undefined,
    templateId: undefined,
    templateValue: undefined,
    imageFileId: undefined,
    tags: [],
    tagsValue: "",
    showProduct: ArContentInfo.product,
    tagTipShow:false,
  } as ICreateProjectModel,
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
  },
  effects: (dispatch) => ({
    clearup () {
      dispatch.CreateProjectStatus.setState({data:undefined,type:SET_TEMPLATE_ID});
      dispatch.CreateProjectStatus.setState({data:false,type:SET_TAG_TIP_SHOW})
      dispatch.CreateProjectStatus.setState({data:false,type:SET_ISLOADING})
      dispatch.CreateProjectStatus.setState({data:false,type:SET_IS_SUBMIT})
      dispatch.CreateProjectStatus.setState({data:[],type:SET_TAGS})
      dispatch.CreateProjectStatus.setState({data:'',type:SET_TAGS_VALUE})
    },
    async getArContentCategory(value: ArContent_pb.ArContentCategoryEnum[]) {
      let categoryData: any = [];
      for (let i = 0; i < ArContentInfo.categoryList.length; i++) {
        if (value.includes(ArContentInfo.categoryList[i].id)) {
          categoryData.push(ArContentInfo.categoryList[i]);
        }
      }
      dispatch.CreateProjectStatus.setState({
        data: categoryData,
        type: SET_CATEGORY_LIST,
      });
    },
    async load(callback:(stage:number)=>void) {
      
    
      const auth = await localforage.getItem<AuthReply.AsObject>("admin_auth");
     
      if (auth) {
        console.log('auth: ' + auth)
        const expiresTime = moment(auth.expireDate);
        if (moment().isBefore(expiresTime)) {
          dispatch.CreateProjectStatus.setState({
            type: SET_TOKEN,
            data: auth.token,
          });
          const result = await arContentApi.getCategoryList(auth.token);
          try {
            if (result instanceof ArContent_pb.GetCategoryListReply) {
              const userEmail = await localforage.getItem<string>("auth_email");

              //判斷是否用公司email登入，驗收需要開啟所有template，所以用公司email登入都可以看到所有template

              if (userEmail) {
                if (userEmail.includes("@spe3d.co")) {
                  dispatch.CreateProjectStatus.setState({
                    data: ArContentInfo.allProduct,
                    type: SET_SHOW_PRODUCT,
                  });
                }
              }
              // console.log(userEmail);
              const categoryData = result.toObject().categoryEnumList;
              if (categoryData !== undefined) {
                this.getArContentCategory(categoryData);
              }
              dispatch.CreateProjectStatus.setState({
                data: false,
                type: SET_ISLOADING,
              });
            } else {
              throw new Error();
            }
          } catch (e) {
            resultErrorMsg(result as RpcError);
          }
        } else {
          await localforage.clear();
         
          callback(LOAD_CALLBACK_STAGE.tologin)
        }
      } else {
       
        callback(LOAD_CALLBACK_STAGE.tologin)
      }
      
    },
  }),
});

export const SET_ISLOADING = "isloading";
export const SET_IS_SUBMIT = "isSubmit";
export const SET_TOKEN = "token";
export const SET_TAG_TIP_SHOW = 'tagTipShow';
export const SET_CATEGORY_LIST = "categoryList";
export const SET_CATEGORY_VALUE = "categoryValue";
export const SET_TYPE_LIST = "typeList";
export const SET_TYPE_VALUE = "typeValue";
export const SET_TEMPLATE_LIST = "templateList";
export const SET_TEMPLATE_VALUE = "templateValue";
export const SET_TEMPLATE_ID = "templateId";
export const SET_IMAGE_FILE_ID = "imageFileId";
export const SET_TAGS = "tags";
export const SET_TAGS_VALUE = "tagsValue";
export const SET_SHOW_PRODUCT = "showProduct";
export const LOAD_CALLBACK_STAGE = {
  tologin:1
}