import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import { ArContentTemplateEnum } from "@/proto/ArContent_pb";
export const arViewerStatus = createModel<RootModel>()({
    state: {
        isViewerOpen: false,
        isArModeOpen: false,
        isCameraOpen: true,
        imagePreview:'',
        contentData: new ArContent_pb.GetViewerDataReply(),
        isRecord : false,
        controlPanel : false,
        recordingTime:0,
        videoData:undefined,
        detectFace:true,
    },
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            // console.log(payload)
            return { ...state, [type]: data};
        }
    },
    effects: (dispatch) =>({
        clearup () {
            dispatch.arViewerStatus.setState({data:false , type: SET_VIEWER_OPEN})
            dispatch.arViewerStatus.setState({data:false , type: SET_AR_MODE_OPEN})
            dispatch.arViewerStatus.setState({data:true , type:SET_CAMERA_OPEN})
            dispatch.arViewerStatus.setState({data:false,type:SET_RECORD})
            dispatch.arViewerStatus.setState({data:true , type:SET_DETECT_FACE})
            dispatch.arViewerStatus.setState({data:false,type:SET_CONTROL_PANEL_OPEN})
        }
    })
});

export const SET_VIEWER_OPEN = "isViewerOpen";
export const SET_CONTENT_DATA = "contentData";
export const SET_AR_MODE_OPEN = "isArModeOpen";
export const SET_CAMERA_OPEN = "isCameraOpen";
export const SET_PREVIEW_DATA = "imagePreview";
export const SET_RECORD = "isRecord";
export const SET_RECORD_TIME = "recordingTime";
export const SET_VIDEO_DATA = "videoData";
export const SET_CONTROL_PANEL_OPEN = "controlPanel";
export const SET_DETECT_FACE = 'detectFace';

