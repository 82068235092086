import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import { IAction, ITransform } from "@/components/templateInterface";
import { EarringTemplatePosition } from "@/components/Helpers/SetTemplate";

interface ITemplateEarringTransform {
    overall: ITransform;
    top: ITransform;
    mid: ITransform;
    down: ITransform;
  }
const defaultTransform: ITemplateEarringTransform = {
    overall: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    top: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    mid: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    down: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
  };
interface IEditEarringStatus {
  leftState: ITemplateEarringTransform,
  rightState: ITemplateEarringTransform,
  token:string,
  isLoading:boolean,
  isSubmit: boolean,
  loadArView:boolean,
  imageAFileId:string,
  imageBFileId:string,
  imageCFileId:string,
  imageAFilePath:string,
  imageBFilePath:string,
  imageCFilePath:string,
  imageAUploadId:string,
  imageBUploadId:string,
  imageCUploadId:string,
  componentSize:EarringTemplatePosition.OVERALL | EarringTemplatePosition.TOP | EarringTemplatePosition.MID | EarringTemplatePosition.DOWN,
  contentInfo:undefined | ArContent_pb.ArContentInfo.AsObject,
  arSwitch:boolean,
  arLoading:boolean,
  arView:boolean,
  direction:EarringTemplatePosition.LEFT | EarringTemplatePosition.RIGHT,
}
export const editEarringStatus = createModel<RootModel>()({
    state: {
        leftState: defaultTransform,
        rightState: defaultTransform,
        token:'',
        isLoading:true,
        isSubmit: false,
        loadArView:false,
        imageAFileId:'',
        imageBFileId:'',
        imageCFileId:'',
        imageAFilePath:'',
        imageBFilePath:'',
        imageCFilePath:'',
        imageAUploadId:'',
        imageBUploadId:'',
        imageCUploadId:'',
        componentSize:EarringTemplatePosition.OVERALL,
        contentInfo:undefined,
        arSwitch:false,
        arLoading:true,
        arView:true,
        direction:EarringTemplatePosition.LEFT,


    } as IEditEarringStatus,
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        },
        leftTransformReducer(state,action: IAction){
            const {type,payload} = action;
            switch (type) {
                case EarringTemplatePosition.OVERALL:
                case EarringTemplatePosition.TOP:
                case EarringTemplatePosition.MID:
                case EarringTemplatePosition.DOWN:
                   return {
                    ...state,
                    SET_LEFT_STATE:{
                        ...state.leftState,
                        [type]:payload
                    }
                   }
                    break
                default:
                  return state;
              }
        },
        rightTransformReducer(state,action: IAction){
            const {type,payload} = action;
            switch (type) {
                case EarringTemplatePosition.OVERALL:
                case EarringTemplatePosition.TOP:
                case EarringTemplatePosition.MID:
                case EarringTemplatePosition.DOWN:
                    return {
                        ...state,
                        SET_RIGHT_STATE:{
                            ...state.leftState,
                            [type]:payload
                        }
                       }
                    break
                default:
                  return state;
              }
        }
    },
    effects: (dispatch) =>({
      clearup () {
        dispatch.editEarringStatus.setState({data:EarringTemplatePosition.OVERALL,type:SET_COMPONENT_SIZE})
        dispatch.editEarringStatus.setState({data: true, type: SET_IS_LOADING})
        dispatch.editEarringStatus.setState({data: false, type: SET_LOAD_AR_VIEW})
        dispatch.editEarringStatus.setState({data:true,type:SET_AR_LOADING})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_A_FILEPATH})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_B_FILEPATH})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_C_FILEPATH})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_A_FILE_ID})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_B_FILE_ID})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_C_FILE_ID})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_A_UPLOAD_ID})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_B_UPLOAD_ID})
        dispatch.editEarringStatus.setState({data:'',type:SET_IMAGE_C_UPLOAD_ID})

      }
    })
});

export const SET_LEFT_STATE = "leftState";
export const SET_RIGHT_STATE = "rightState";
export const SET_TOKEN = 'token';
export const SET_IS_LOADING = 'isLoading'
export const SET_IS_SUBMIT = 'isSubmit'
export const SET_LOAD_AR_VIEW = 'loadArView';
export const SET_IMAGE_A_FILEPATH = 'imageAFilePath';
export const SET_IMAGE_B_FILEPATH = 'imageBFilePath';
export const SET_IMAGE_C_FILEPATH = 'imageCFilePath';
export const SET_IMAGE_A_FILE_ID = 'imageAFileId';
export const SET_IMAGE_B_FILE_ID = 'imageBFileId';
export const SET_IMAGE_C_FILE_ID = 'imageCFileId';
export const SET_IMAGE_A_UPLOAD_ID = 'imageAUploadId' ;
export const SET_IMAGE_B_UPLOAD_ID = 'imageBUploadId' ;
export const SET_IMAGE_C_UPLOAD_ID = 'imageCUploadId'
export const SET_COMPONENT_SIZE = 'componentSize';
export const SET_DIRECTION = 'direction';
export const SET_CONTENT_INFO = 'contentInfo';
export const SET_AR_SWITCH = 'arSwitch';
export const SET_AR_VIEW = 'arView';
export const SET_AR_LOADING = 'arLoading';


