function degrees_to_radians(degrees: number)
{
    const pi = Math.PI;

    return degrees * (pi/180);
}

function radians_to_degrees(radians: number)
{
    const pi = Math.PI;

    return radians * (180/pi);
}

function dist(ptA:{x:number, y:number}, ptB: {x:number, y:number}): number {
    let distX = ptA.x - ptB.x;
    let distY = ptA.y - ptB.y;

    return Math.sqrt(distX*distX + distY*distY);
}

function radian(cx: number, cy: number, ex: number, ey: number): number {
    let dy = ey - cy;
    let dx = ex - cx;

    return Math.atan2(dy, dx);  //range(-PI, PI)
}

export {
    degrees_to_radians,
    radians_to_degrees,
    dist,
    radian
};