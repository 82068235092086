import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb, { ArContentTemplateEnum } from "@/proto/ArContent_pb";
import * as ArContentTemplate_pb from "@/proto/ArContentTemplate_pb";

interface Vector3 {
  position: { x: number; y: number; z: number };
  rotation: { x: number; y: number; z: number };
  scale: { x: number; y: number; z: number };
}
interface IProjectEditViewerModel {
  isloading: boolean;
  arloading: boolean;
  isSubmit: boolean;
  arViewIsLoading: boolean;
  cameraOpen: boolean;
  allowCaptureValue: boolean;
  token: string;
  rightButtonCheck: boolean;
  leftButtonCheck: boolean;
  logoFileId: string;
  uploadLogoId: string;
  logoFilePath: string;
  templateEnum: ArContent_pb.ArContentTemplateEnum | undefined;
  templateData:
    | ArContentTemplate_pb.TemplateGlasses
    | ArContentTemplate_pb.TemplateEarring
    | ArContentTemplate_pb.TemplateContactLenses
    | undefined;
  hairTemplateData: ArContentTemplate_pb.TemplateHair | undefined;
  allTransform: Vector3;
  earLeftTransform: Vector3;
  earRightTransform: Vector3;
  firstTransform: Vector3;
  secondTransform: Vector3;
  thirdTransform: Vector3;
  leftTopTransform: Vector3;
  leftMidTransform: Vector3;
  leftDownTransform: Vector3;
  rightTopTransform: Vector3;
  rightMidTransform: Vector3;
  rightDownTransform: Vector3;
  lenLeftTransform: Vector3;
  lenRightTransform: Vector3;
  imageAFilePath: string;
  imageBFilePath: string;
  imageCFilePath: string;
  radius: string;
  hairColor: string;
  windowPickerColor: string;
  btnPickerColor: string;
  btnRightColor: string;
  btnRightTextValue: string;
  btnRightTextColor: string;
  showRightBtnDetails: false;
  btnLeftTextValue: string;
  btnLeftTextColor: string;
  btnLeftUrlValue: string;
  btnRightUrlValue: string;
  btnLeftColor: string;
  showLeftBtnDetails: false;
}
const initialState = {
  position: { x: 0, y: 0, z: 0 },
  rotation: { x: 0, y: 0, z: 0 },
  scale: { x: 1, y: 1, z: 1 },
};
const INITIALSTATE: IProjectEditViewerModel = {
  isloading: false,
  arloading: false,
  isSubmit: false,
  arViewIsLoading: false,
  cameraOpen: true,
  allowCaptureValue: true,
  token: "",
  rightButtonCheck: true,
  leftButtonCheck: true,
  logoFileId: "",
  uploadLogoId: "",
  logoFilePath: "",
  templateEnum: undefined,
  templateData: undefined,
  hairTemplateData: undefined,
  allTransform: initialState,
  earLeftTransform: initialState,
  earRightTransform: initialState,
  firstTransform: initialState,
  secondTransform: initialState,
  thirdTransform: initialState,
  leftTopTransform: initialState,
  leftMidTransform: initialState,
  leftDownTransform: initialState,
  rightTopTransform: initialState,
  rightMidTransform: initialState,
  rightDownTransform: initialState,
  lenLeftTransform: initialState,
  lenRightTransform: initialState,
  imageAFilePath: "",
  imageBFilePath: "",
  imageCFilePath: "",
  radius: "",
  hairColor: "",
  windowPickerColor: "#a0defd",
  btnPickerColor: "ef0606",
  btnRightColor: "#ffffff",
  btnRightTextValue: "",
  btnRightTextColor: "#000000",
  btnRightUrlValue: "",
  showRightBtnDetails: false,
  btnLeftTextValue: "",
  btnLeftColor: "#000000",
  btnLeftTextColor: "#000000",
  btnLeftUrlValue: "",
  showLeftBtnDetails: false,
};
export const ProjectEditViewerStatus = createModel<RootModel>()({
  state: INITIALSTATE as IProjectEditViewerModel,
  reducers: {
    setState(state, payload: { type: string; data: any }) {
      const { type, data } = payload;
      return { ...state, [type]: data };
    },
  },
  effects: (dispatch) => ({
    async load(callback: (value: string) => void) {},
    clearup() {
      dispatch.ProjectEditViewerStatus.setState({
        type: SET_IS_SUBMIT,
        data: false,
      });
      dispatch.ProjectEditViewerStatus.setState({
        type: SET_CAMERA_OPEN,
        data: true,
      });
      dispatch.ProjectEditViewerStatus.setState({
        type: SET_ISLOADING,
        data: false,
      });
      dispatch.ProjectEditViewerStatus.setState({type: SET_SHOW_RIGHT_BTN_DETAILS,data:false})
      dispatch.ProjectEditViewerStatus.setState({type: SET_SHOW_LEFT_BTN_DETAILS,data:false})
      dispatch.ProjectEditViewerStatus.setState({type:SET_LOGO_FILE_PATH,data:''})
      dispatch.ProjectEditViewerStatus.setState({type:SET_UPLOAD_LOGO_ID,data:''})
      dispatch.ProjectEditViewerStatus.setState({type:SET_LOGO_FILEID,data:''})
      dispatch.ProjectEditViewerStatus.setState({type:SET_ARLOADING,data: false})
      dispatch.ProjectEditViewerStatus.setState({type:SET_AR_VIEW_ISLOADING, data: false})
      dispatch.ProjectEditViewerStatus.setState({type:SET_TEMPLATE_DATA,data: undefined})
      dispatch.ProjectEditViewerStatus.setState({type:SET_TEMPLATE_EUM,data:undefined})
      dispatch.ProjectEditViewerStatus.setState({type:SET_HAIR_TEMPLATE_DATA,data:undefined})
      dispatch.ProjectEditViewerStatus.setState({type:SET_IMAGE_A_FILEPATH,data:''})
      dispatch.ProjectEditViewerStatus.setState({type:SET_IMAGE_B_FILEPATH,data:''})
      dispatch.ProjectEditViewerStatus.setState({type:SET_IMAGE_C_FILEPATH,data:''})
    },
  }),
});

export const SET_ISLOADING = "isloading";
export const SET_IS_SUBMIT = "isSubmit";
export const SET_AR_VIEW_ISLOADING = "arViewIsLoading";
export const SET_TOKEN = "token";
export const SET_ALLOW_CAPTURING = "allowCaptureValue";
export const SET_CAMERA_OPEN = "cameraOpen";
export const SET_ARLOADING = "arloading";
export const SET_BTN_RIGHT_CHECK = "rightButtonCheck";
export const SET_BTN_LEFT_CHECK = "leftButtonCheck";
export const SET_LOGO_FILEID = "logoFileID";
export const SET_TEMPLATE_EUM = "templateEnum";
export const SET_UPLOAD_LOGO_ID = "uploadLogoId";
export const SET_LOGO_FILE_PATH = "logoFilePath";
export const SET_TEMPLATE_DATA = "templateData";
export const SET_HAIR_TEMPLATE_DATA = "hairTemplateData";
export const SET_ALL_TRANSFORM = "allTransform";
export const SET_EAR_LEFT_TRANSFORM = "earLeftTransform";
export const SET_EAR_RIGHT_TRANSFORM = "earRightTransform";
export const SET_FIRST_TRANSFORM = "firstTransform";
export const SET_SECOND_TRANSFORM = "secondTransform";
export const SET_THIRD_TRANSFORM = "thirdTransform";
export const SET_LEFT_TOP_TRANSFORM = "leftTopTransform";
export const SET_RIGHT_TOP_TRANSFORM = "rightTopTransform";
export const SET_LEFT_MID_TRANSFORM = "leftMidTransform";
export const SET_LEFT_DOWN_TRANSFORM = "lfetDownTransform";
export const SET_RIGHT_MID_TRANSFORM = "rightMidTransform";
export const SET_RIGHT_DOWN_TRANSFORM = "rightDownTransform";
export const SET_LENS_LFET_TRANSFROM = "lenLeftTransform";
export const SET_LENS_RIGHT_TRANSFORM = "lenRightTranform";
export const SET_IMAGE_A_FILEPATH = "imageAFilePath";
export const SET_IMAGE_B_FILEPATH = "imageBFilePath";
export const SET_IMAGE_C_FILEPATH = "imageCFilePath";
export const SET_RADIUS = "radius";
export const SET_HAIRCOLOR = "hairColor";
export const SET_WINDOWPICKER_COLOR = "windowPickerColor";
export const SET_BTN_PICKER_COLOR = "btnPickerColor";
export const SET_BTN_RIGHT_COLOR = "btnRightColor";
export const SET_BTN_LEFT_COLOR = "btnLeftColor";
export const SET_BTN_RIGHT_TEXT_COLOR = "btnRightTextColor";
export const SET_BTN_RIGHT_TEXT_VALUE = "btnRightTextValue";
export const SET_BTN_LEFT_TEXT_COLOR = "btnLeftTextColor";
export const SET_BTN_LEFT_TEXT_VALUE = "btnLeftTextValue";
export const SET_BTN_RIGHT_URL_VALUE = "btnRightUrlValue";
export const SET_BTN_LEFT_URL_VALUE = "btnLeftUrlValue";
export const SET_SHOW_LEFT_BTN_DETAILS = "showLeftBtnDetails";
export const SET_SHOW_RIGHT_BTN_DETAILS = "showRightBtnDetails";
