import { createModel } from "@rematch/core";
import { RootModel } from "@/model/index";
import ArContent_pb from "@/proto/ArContent_pb";
import routes from "@/routers";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";


export const ResendEmailStatus = createModel<RootModel>()({
    state: {
        userEmail:"",
        isLoading: true,
        routerPath:'',
    },
    reducers: {
        setState(state, payload: {type: string, data: any}) {
            const { type, data } = payload;
            return { ...state, [type]: data};
        }
    },
    effects: (dispatch) =>({
        
        async loadLocalStorage (){
            const router_path = routes.router_path ;
            const nav = useNavigate()
            const pathInfo = await localforage.getItem("router_path");
            const emailInfo = await localforage.getItem("auth_email");
            if (pathInfo) {
                dispatch.ResendEmailStatus.setState({data:pathInfo as string,type:SET_ROUTER_PATH})
            }
            if (emailInfo) {
                dispatch.ResendEmailStatus.setState({data:emailInfo as string , type:SET_USER_EMAIL})
                dispatch.ResendEmailStatus.setState({data:false ,  type:SET_ISLOADING})
            } else {
               nav(router_path.login)
            }
        },
        clearup () {
            dispatch.ResendEmailStatus.setState({data:'',type:SET_USER_EMAIL})
            dispatch.ResendEmailStatus.setState({data:true,type:SET_ISLOADING})
            dispatch.ResendEmailStatus.setState({data:'',type:SET_ROUTER_PATH})
        }
    })
});

export const SET_USER_EMAIL = "userEmail";
export const SET_ISLOADING = "isLoading";
export const SET_ROUTER_PATH = "routerPath";